@media only screen and (max-width: 1800px) {
  .recent-glace {
    padding: 8px 10px;
  }

  .annual-planmodal {
    width: 55%;
    margin: 0 auto;
  }
  .chart-wrap canvas {
    width: 260px !important;
    height: 260px !important;
  }
  .equal-height-cust {
    padding-top: 0vh;
  }

  .equal-height-cust-3 {
    padding-top: 0.8vh;
  }
  .suplements-main-outer {
    margin-bottom: 2rem;
  }
  .weekly-table {
    justify-content: space-between;
  }
  .suplements-main-outer-empty {
    border: 1px solid grey;
    width: 100%;
    height: 100%;
    min-height: 465px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .dtpicker-custom .react-calendar-datetime-picker .header .header-date {
    display: flex;
    color: #fff;
    font-size: 16px;
    justify-content: center;
    flex-wrap: wrap;
  }

  .dtpicker-custom .react-calendar-datetime-picker .header .header--btn {
    cursor: pointer;
    display: block;
    width: 18px;
    height: 27px;
    color: #fff;
  }

  .dtpicker-custom .react-calendar-datetime-picker .daysList {
    list-style: none;
    overflow: hidden;
    padding: 0px 0;
    margin: 0;
    text-align: center;
  }

  .titleAddBlogs input {
    font-size: 15px;
    padding: 5px 15px;
    font-family: "Poppins";
    background-color: #e1e1e1;
    border: 1px solid #d2d2d2;
  }

  .blogs-tab-specific span {
    color: #4f6367;
    font-size: 15px;
    font-family: "Poppins";
  }

  .previewCheckBox label {
    font-size: 16px !important;
  }

  .featured-img h6 {
    padding: 14px;
    font-size: 14px;
    font-family: "Poppins";
    font-weight: 300;
    color: #ffffff;
  }

  .blog-categories .form-label {
    font-size: 14px;
    font-weight: 300;
    font-family: "Poppins";
    margin-left: 0;
    margin-bottom: 0 !important;
    z-index: 1;
  }

  .blog-categories .form-blog {
    display: flex;
    flex-wrap: wrap;
    background: #ffffff;
    width: 90%;
    /* gap: 9px; */
    column-gap: 9px;
    margin: 0 auto;
    border-radius: 5px;
  }

  .blogger-name input {
    font-size: 14px;
    font: 300;
    width: 90%;
    padding: 6px;
    background-color: #ffffff;
    border: none;
    border-radius: 5px;
  }

  .blogger-description textarea {
    font-size: 14px;
    font: 300;
    width: 100%;
    padding: 6px;
    background-color: #ffffff;
    border: none;
    border-radius: 5px;
    height: 100px;
  }

  .feature-img-main p {
    width: -moz-fit-content;
    width: fit-content;
    font-size: 14px;
    font-weight: 300;
    font-family: "Poppins";
    margin: 0 !important;
  }

  .weekly-table .form-row-second {
    background: #ffffff;
    border: 1px solid #d5d5d5;
    border-radius: 50%;
    height: 30px !important;
    margin: 4px;
    overflow: hidden;
    position: relative;
    width: 40px;
  }

  .weekly-table .form-input:checked ~ .form-label-second {
    color: #ffffff;
    background-color: #258e00;
    height: 31px !important;
  }

  .weekly-table .form-row-second {
    background: #ffffff;
    border: 1px solid #d5d5d5;
    border-radius: 50%;
    height: 40px;
    margin: 0px;
    overflow: hidden;
    position: relative;
    width: 30px !important;
  }

  .update-btn-new {
    width: 130px !important;
    height: 35px !important;
    font-size: 18px;
    font-weight: 600;
    background-color: #7a9ea0 !important;
    margin: initial !important;
    margin-bottom: 12px !important;
  }

  .pr-info-client h1 {
    color: #ffffff !important;
    background-color: #749598;
    border-radius: 40px;
    margin: 0 auto;
    font-size: 25px !important;
    padding: 10px 0;
    font-weight: 600;
  }

  .fade.logoutmodal.delet-modale.error-dynamic-modal.modal.show .modal-content {
    background: white;
    border: 2px solid #4f6367;
  }

  .pr-info-client ul li {
    font-size: 15px !important;
    font-weight: 600;
    color: #4f6367;
  }

  .outer-profile-prac {
    margin-top: 6rem !important;
  }

  .hexagon-box p,
  .hexagon-box span {
    font-size: 20px;
  }

  .custom-section-gap {
    margin-top: 6.2rem !important;
  }

  .css-c6g3g4-MenuPortal {
    top: auto !important;
    /* Disable any top positioning */
    bottom: calc(100% + 8px);
    /* Force the dropdown to open downwards */
  }
}

@media only screen and (max-width: 1600px) {
  .hexagon-box.Fourth,
  .hexagon-box.first,
  .hexagon-box.second {
    height: 110px;
  }
  .health-profile-markers span {
    font-size: 18px !important;
  }

  .monthly-data {
    font-size: 11px !important;
  }
  .equal-height-cust-4 {
    padding-top: 0.5vh;
  }
  section.admin-side-side.report-custom.custom-section-gap.cust-gap-2 {
    margin-top: 6.5rem !important;
  }
  .personalize-specific {
    font-size: 13px;
  }
  .personalizer-inner-head {
    font-size: 17px;
  }
  /* .check-up-detail-inner {
    overflow-y: auto;
    max-height: 544px;
  } */
  .checkup-height {
    padding-top: 0.3vh;
  }
  .report-custom-2 {
    width: 77%;
  }
  .recenttestcustomer.sp-added {
    margin-top: 40px;
  }

  .annual-planfirst {
    gap: 74px;
  }
  .annual-planmodal {
    width: 70% !important;
    margin: 0 auto;
  }

  .weekly-table-2 .form-row {
    height: 35px !important;

    width: 35px !important;
  }

  .recom-bio .weekly-table-2 .form-input:checked ~ .form-label-second::before {
    top: 45% !important;
    width: 7px !important;
    height: 16px !important;

    left: 65% !important;
  }
  .recom-bio .weekly-table-2 .form-input:checked ~ .form-label-second {
    height: 34px !important;
  }
  .weekly-table-2 .form-input:checked ~ .form-label-second::before {
    top: 50% !important;
    left: 69% !important;
  }

  .weekly-table .form-row-second {
    height: 24px !important;

    width: 24px !important;
  }
  .weekly-table .form-label-second {
    line-height: 22px !important;
  }
  .more-recommendations {
    font-weight: bold;
    font-size: 10px;
  }
  .recommend-circle .red-circle-recommend span {
    font-size: 13px !important;
  }
  .vital-chart-main div#chartContainer {
    height: 200px !important;
  }
  .vitals-report-chart canvas.canvasjs-chart-canvas {
    height: 200px !important;
    width: 530px !important;
  }
  .chart-wrap canvas {
    width: 230px !important;
    height: 230px !important;
  }
  .weekly-table .form-input:checked ~ .form-label-second {
    color: #ffffff;
    background-color: #258e00;
    height: 23px !important;
  }
  .weekly-table .form-input:checked ~ .form-label-second::before {
    width: 7px !important;
    height: 13px !important;

    top: 46% !important;
    left: 65% !important;
  }
  .weekly-table .form-label-second {
    line-height: 20px !important;
  }
  .recom-bio-slider img {
    height: 200px;
  }
  .suplements-main-outer {
    height: 340px;
  }
  .suplements-main-outer img {
    height: 160px;
  }
  .suplements-main-outer {
    margin-bottom: 1rem;
  }
  .weekly-table .form-label {
    line-height: 4.2;
    font-size: 8px;
  }
  .recom-bio > ul {
    height: 55px;
  }
  .suplements-main-outer-empty {
    border: 1px solid grey;
    width: 100%;
    height: 100%;
    min-height: 340px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .done-btn-calender {
    position: absolute;
    bottom: 1.2rem;
    z-index: 99999;
    right: 0.5rem;
    font-size: 13px;
    font-family: "Poppins";
    background-color: #4f6367;
    padding: 5px 7px;
    border-radius: 5px;
    color: #ffff;
  }

  .set-reminder-btn {
    font-size: 13px;
    font-family: "Poppins";
    color: #414141;
  }

  .dtpicker-custom .react-calendar-datetime-picker .header .header-date {
    display: flex;
    color: #fff;
    font-size: 15px;
    justify-content: center;
    flex-wrap: wrap;
  }

  .dtpicker-custom .react-calendar-datetime-picker .daysList .is-week-days {
    color: #aaa;
    font-size: 13px;
    font-weight: 500;
    height: 34px;
    line-height: 34px;
    border-radius: 0;
  }

  .dtpicker-custom .react-calendar-datetime-picker .daysList .daysList-day {
    width: 13.285714%;
    height: calc(285px / 7);
    line-height: calc(310px / 7);
    float: left;
    text-align: center;
    box-sizing: border-box;
    cursor: pointer;
    border-radius: 50%;
    font-size: 13px;
    margin: 1px 0;
  }

  .dtpicker-custom .react-calendar-datetime-picker .daysList .daysList-day {
    width: 13.285714%;
    height: calc(285px / 7);
    line-height: calc(310px / 7);
    float: left;
    text-align: center;
    box-sizing: border-box;
    cursor: pointer;
    border-radius: 50%;
    font-size: 13px;
    margin: 1px 1px;
  }

  .titleAddBlogs input {
    font-size: 14px;
    padding: 5px 15px;
    font-family: "Poppins";
    background-color: #e1e1e1;
    border: 1px solid #d2d2d2;
  }

  .previewCheckBox label {
    font-size: 15px !important;
  }

  .previewCheckBox label {
    font-size: 13px !important;
  }

  .visibility-opts {
    font-size: 14px;
    font-weight: 500;
    font-family: "Poppins";
    display: flex;
    justify-content: space-around;
  }

  .featured-img h6 {
    padding: 11px;
    font-size: 13px;
    font-family: "Poppins";
    font-weight: 300;
    color: #ffffff;
  }

  .blog-categories .form-label {
    font-size: 13px;
    font-weight: 300;
    font-family: "Poppins";
    margin-left: 0;
    margin-bottom: 0 !important;
    z-index: 1;
  }

  .blog-categories .form-row {
    background: #ffffff;
    border-radius: 5px;
    padding: 7px 9px;
    margin: 9px 8px;
    overflow: hidden;
    position: relative;
  }

  .feature-img-main p {
    width: -moz-fit-content;
    width: fit-content;
    font-size: 13px;
    font-weight: 300;
    font-family: "Poppins";
    margin: 0 !important;
  }

  .blogger-name input {
    font-size: 13px;
    font: 300;
    width: 90%;
    padding: 6px;
    background-color: #ffffff;
    border: none;
    border-radius: 5px;
  }

  .blogger-description textarea {
    font-size: 13px;
    font: 300;
    width: 100%;
    padding: 6px;
    background-color: #ffffff;
    border: none;
    border-radius: 5px;
    height: 100px;
  }

  .save-draft-btn {
    height: 42px;
    border: 1px solid #d2d2d2;
    background-color: #e1e1e1;
    color: #717475;
    font-size: 13px;
    font-family: "Poppins";
    font-weight: 300;
    /* height: 101px; */
    /* height: 43px; */
    height: 37px !important;
    width: 100px;
    border-radius: 5px !important;
  }

  .publish-btn {
    height: 37px !important;
    width: 100px;
    border-radius: 5px !important;
  }

  .blog-categories .form-blog {
    display: flex;
    flex-wrap: wrap;
    background: #ffffff;
    width: 90%;
    /* gap: 9px; */
    column-gap: 0px;
    margin: 0 auto;
    border-radius: 5px;
  }

  .blogs-tab-specific span {
    color: #4f6367;
    font-size: 14px;
    font-family: "Poppins";
  }

  .practionar-request .blog-order-box {
    padding: 20px 0;
  }

  .blog-second-order-box {
    height: 59.5px !important;
  }

  .underline img {
    width: 40px;
    height: 40px;
  }

  .latest-order-box-parent ul.sec-order-list li a {
    font-size: 12px;
  }

  ul.order-list li a {
    font-size: 12px;
  }

  .checkin-btn-new {
    height: 28px !important;
  }

  input:checked ~ .form-label-second {
    color: #ffffff;
    background-color: #258e00;
    height: 28px !important;
  }

  .weekly-table .form-input:checked ~ .form-label::before {
    content: "";
    display: block;
    position: absolute;
    width: 9px;
    height: 18px;
    border-bottom: 2px solid #ffffff;
    border-right: 2px solid #ffffff;
    transform: rotate(45deg);
    top: 40%;
    left: 58%;
    transform-origin: center;
    margin-top: -7px;
    margin-left: -8px;
  }

  .update-btn-new {
    width: 115px !important;
    height: 32px !important;
    font-size: 16px;
    font-weight: 600;
    background-color: #7a9ea0 !important;
    margin: initial !important;
    margin-bottom: 12px !important;
  }

  .weekly-table .form-row {
    background: #ffffff;
    border: 1px solid #d5d5d5;
    border-radius: 50%;
    height: 40px;
    margin: 0px;
    overflow: hidden;
    position: relative;
    width: 40px;
  }

  .pr-info-client h1 {
    color: #ffffff !important;
    background-color: #749598;
    border-radius: 40px;
    margin: 0 auto;
    font-size: 20px !important;
    padding: 10px 0;
    font-weight: 600;
  }

  .pr-info-client ul li {
    font-size: 14px !important;
    font-weight: 600;
    color: #4f6367;
  }

  .pr-info-client h1 {
    color: #ffffff !important;
    background-color: #749598;
    border-radius: 40px;
    margin: 0 auto;
    font-size: 17px !important;
    padding: 10px 0;
    font-weight: 600;
  }

  .pr-info-client ul li {
    font-size: 13px !important;
    font-weight: 600;
    color: #4f6367;
  }

  .outer-profile-prac {
    margin-top: 6.3rem !important;
  }

  .css-c6g3g4-MenuPortal {
    bottom: auto !important;
    /* Disable the bottom property */
    top: 100% !important;
    /* Force the dropdown to open downwards */
  }

  .cust-prac-nav li {
    border: 1px solid;
    color: #7a9ea0;
    padding: 4px 12px;
    border-radius: 6px;
    color: #7a9ea0;
    font-family: "Poppins", sans-serif;
    cursor: pointer;
    font-size: 14px;
  }

  .recom-bio {
    background: var(--Antique-White);
    padding: 11px 10px;
    border-radius: 0px 0px 15px 15px;
  }

  .recom-bio h3 {
    color: var(--Slate);
    font-size: 18px;
    font-weight: 600;
  }

  .recommend-bybiomarker {
    background-color: #fffdf9;
    padding: 15px;
    border: 1px solid #4f6367;
    border-radius: 18px;
    width: 16rem;
  }

  .recommend-outer-btn {
    width: 16rem;
  }

  .recommend-bybiomarker h2 {
    font-size: 18px;
    width: -moz-fit-content;
    width: fit-content;
    color: var(--Slate);
    font-weight: 600;
    margin: 0 auto;
  }

  .all-recommend-outer h3 {
    color: var(--Slate);
    font-weight: 600;
    font-size: 20px;
  }

  .all-recommend-inner-main {
    display: grid;
    grid-template-columns: 28.5% 28.5% 28.5%;
    gap: 25px;
  }

  .recom-bio ul li {
    display: flex;
    color: #414141;
    font-size: 10px;
    align-items: center;
  }

  .recom-bio h4 {
    font-size: 15px;
    color: #4f6367;
    font-weight: 600;
    margin: 10px 0;
  }

  .recommend-under-head {
    font-size: 15px;
    font-weight: 600;
    color: var(--Slate);
  }

  .recomendation-steps .recommend-last-btn {
    width: 93.5%;
  }

  .recommend-inner-main-custom {
    display: grid;
    grid-template-columns: 29.5% 29.5% 29.5% !important;
    gap: 25px;
    padding-left: 3rem;
  }

  .recommend-label-btns {
    display: flex;
    flex-wrap: wrap;
    row-gap: 13px;
    column-gap: 5px;
  }

  .recommend-btn .biomarkeraddbtn {
    width: 80px !important;
    font-weight: 500 !important;
    font-size: 13px;
  }

  .recommend-spefic h4 {
    font-size: 18px;
    font-weight: 600;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 auto;
    color: var(--Slate);
  }

  .recommend-steps {
    color: var(--Slate);
    font-size: 12px;
    font-weight: 500;
    display: grid;
    grid-template-columns: 70% 30%;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .recommend-steps-head {
    color: var(--Slate);
    font-size: 15px;
    font-weight: 600;
  }

  .recomend-label-specific {
    display: flex;
    align-items: center;
    gap: 4px;
    background-color: #7a9ea0;
    width: -moz-fit-content;
    width: fit-content;
    padding: 4px 8px;
    border-radius: 8px;
    font-size: 12px;
    color: #fff;
  }

  .recommend-bybiomarker-sub h4 {
    color: var(--Slate);
    font-size: 16px;
    font-weight: 600;
  }

  .recomend-test-bar {
    font-size: 14px;
    background-color: #b8d9d9;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 6px 18px;
    border-radius: 30px;
    margin-bottom: 0.5rem;
    box-shadow: 10px 10px 31px -22px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 10px 10px 31px -19px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 10px 10px 31px -22px rgba(0, 0, 0, 0.75);
  }

  .bybiomarker-head {
    font-size: 20px;
    color: var(--Slate);
    font-weight: 600;
    text-transform: capitalize;
  }

  .recommended-head {
    color: var(--Slate);
    font-size: 16px;
    font-weight: 600;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 auto;
    padding-bottom: 1rem;
  }

  .recommended-details {
    font-size: 13px;
    color: var(--Slate);
  }

  .recommended-inner-head {
    color: var(--Slate);
    font-size: 16px;
    font-weight: 600;
  }

  .main-recommended-task {
    display: grid;
    grid-template-columns: 47% 47%;
    gap: 51px;
    width: 78%;
    margin: 0 auto;
  }

  /* .recommend-btn .biomarkeraddbtn {
  border-radius: 8px !important;
  width: 80px !important;
  font-weight: 600 !important;
  font-size: 14px;
} */
  /* .recommend-bybiomarker-sub h4 {
  color: var(--Slate);
  font-size: 17px;
  font-weight: 600;
} */
  /* .recomend-test-bar {
  font-size: 14px;
  background-color: #B8D9D9;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 18px;
  border-radius: 30px;
  margin-bottom: 0.5rem;
  box-shadow: 10px 10px 31px -22px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 10px 10px 31px -19px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 31px -22px rgba(0, 0, 0, 0.75);
} */
  .recommend-circle {
    height: 15px;
    width: 15px;
    border-radius: 50%;
  }

  .recenttestcustomer {
    padding: 1rem 1rem;
  }

  .sidebaradmin.Customersidebar {
    height: 84vh;
  }

  .recenttestcustomer h3 {
    font-size: 21px;
  }

  .cust-modal-main-2.modal .modal-dialog.modal-dialog-centered {
    width: 100%;
    max-width: 70%;
  }

  .cust-modal-main-2.modal .fourth-img h5 {
    font-size: 13px;
    line-height: 19px;
  }

  .cust-modal-main-2.modal .left-inner-grid h6 {
    font-size: 12px;
  }

  .hexagon-box p,
  .hexagon-box span {
    font-size: 13px;
  }

  .result-graph-cust h3 {
    font-size: 20px;
  }

  .result-graph-cust p {
    font-size: 20px;
  }

  .cust-head-modal-2 p {
    font-size: 14px;
  }

  .cust-head-modal-2 h3 {
    font-size: 20px;
  }

  /* .recenttestcustomer.recent-two .hexagon-box {
    padding: 30px 20px;
  } */

  .customer-all-test-result ul li {
    font-size: 12px;
  }

  .alltest-box p {
    font-size: 19px;
  }

  .avatar-upload .avatar-preview {
    width: 110px;
    height: 110px;
    border: 3px solid #f8f8f8;
  }

  .avatar-upload {
    position: relative;
    max-width: 105px;
    margin: 0px auto;
  }

  .avatar-upload .avatar-edit input + label {
    width: 24px;
    height: 24px;
  }

  .avatar-upload .avatar-edit input + label:after {
    font-size: 12px;
  }

  .avatar-upload .avatar-edit {
    right: -4px;
  }
  .fourth-img h5 {
    font-size: 14px;
  }
  .recommend-labels {
    height: 110px;
  }
  .recommend-specific-2 {
    height: 368px;
  }
}

@media only screen and (max-width: 1440px) {
  .text-end.btn-text-end a {
    width: 76px !important;
    font-size: 10px;
  }
  .hexagon-box.Fourth {
    height: 107px;
  }
  .health-profile-markers span {
    font-size: 16px !important;
  }
  .recent-glace {
    padding: 6px 10px;
  }
  .equal-height-cust-4 {
    padding-top: 0vh;
  }
  section.admin-side-side.report-custom.custom-section-gap.cust-gap-2 {
    margin-top: 5.3rem !important;
  }
  .personalize-specific {
    font-size: 12px;
  }
  .personalize-detail-outer {
    width: 50%;
    padding: 1rem 1.5rem !important;
  }
  .checkup-specific h2 {
    font-size: 16px;
    color: #4f6367;
  }
  .checkup-specific p {
    font-size: 12px;
  }
  /* .check-up-detail-inner {
    overflow-y: auto;
    max-height: 442px;
  } */
  .report-custom-2 {
    width: 76%;
  }
  .see-all-cust {
    margin-top: 6px !important;
    margin-bottom: 0 !important;
  }
  .recenttestcustomer.sp-added {
    margin-top: 15px;
  }
  .cust-overview-head {
    font-size: 30px;
  }
  .annual-planmodal {
    width: 85% !important;
    margin: 0 auto;
  }
  .customer-form-detail-modl4 input {
    width: 70%;
  }
  .customer-form-detail-modl4-img1 {
    top: 11px;
    left: 17%;
  }
  .customer-form-detail-modl4-img2 {
    left: 56%;
  }
  .customer-strip-btn-modl4 img {
    width: 16%;
  }
  .customer-strip-btn-modl4 a {
    padding: 7px 117px;
  }
  .customer-form-detail-modl4-img3 {
    left: 66%;
  }
  .customer-subcribtion-content-modl2-body ul {
    padding: 9px 0;
  }
  .customer-subcribtion-content-modl2 ul {
    padding-top: 0;
    padding-bottom: 10px;
  }
  .recom-bio .weekly-table-2 .form-input:checked ~ .form-label-second::before {
    top: 45% !important;
    left: 65% !important;
  }

  .weekly-table-2 .form-row {
    height: 30px !important;
    width: 30px !important;
  }
  .weekly-table-2 .form-label-second {
    line-height: 2px !important;
    font-size: 7px !important;
  }
  button.set-reminder-btn {
    font-size: 10px;
  }
  .chart-wrap canvas {
    width: 200px !important;
    height: 200px !important;
  }
  .cust-prac-nav ul {
    display: flex;
    gap: 10px;
  }
  .equal-height-cust-3 {
    padding-top: 0vh;
  }
  .recom-bio-slider img {
    height: 150px;
  }
  .recom-bio .weekly-4 .form-input:checked ~ .form-label-second {
    height: 30px !important;
  }
  .suplements-main-outer img {
    height: 130px;
  }
  .suplements-main-outer {
    height: 280px;
  }
  .weekly-table .form-label {
    font-size: 8px !important;
  }
  .suplements-main-outer-empty {
    border: 1px solid grey;
    width: 100%;
    height: 100%;
    min-height: 280px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .done-btn-calender {
    position: absolute;
    bottom: 1.3rem;
    z-index: 99999;
    right: 0.5rem;
    font-size: 11px;
    font-family: "Poppins";
    background-color: #4f6367;
    padding: 5px 7px;
    border-radius: 8px;
    color: #ffff;
  }

  .set-reminder-btn {
    font-size: 10px;
    font-family: "Poppins";
    color: #414141;
  }

  .dtpicker-custom .react-calendar-datetime-picker .calender-modal {
    width: 260px;
  }

  /* .dtpicker-custom .react-calendar-datetime-picker .daysList .daysList-day {
    width: 11.285714%;
    height: calc(267px / 8);
    line-height: calc(270px / 8);
} */
  .dtpicker-custom .react-calendar-datetime-picker .daysList .daysList-day {
    width: 11.285714%;
    height: calc(234px / 8);
    line-height: calc(270px / 8);
    float: left;
    text-align: center;
    box-sizing: border-box;
    cursor: pointer;
    border-radius: 50%;
    /* color: #ffffff; */
    font-size: 12px;
    margin: 1px 3px;
  }

  .dtpicker-custom .react-calendar-datetime-picker .header .header-date {
    display: flex;
    color: #fff;
    font-size: 14px;
    justify-content: center;
    flex-wrap: wrap;
  }

  .blog-categories .form-label {
    font-size: 12px;
    font-weight: 300;
    font-family: "Poppins";
    margin-left: 0;
    margin-bottom: 0 !important;
    z-index: 1;
  }

  .blog-categories .form-row {
    background: #ffffff;
    border-radius: 5px;
    padding: 7px 9px;
    margin: 7px 7px;
    overflow: hidden;
    position: relative;
  }

  .blogger-name input {
    font-size: 12px;
    font: 300;
    width: 90%;
    padding: 6px;
    background-color: #ffffff;
    border: none;
    border-radius: 5px;
  }

  .blogger-description textarea {
    font-size: 12px;
    font: 300;
    width: 100%;
    padding: 6px;
    background-color: #ffffff;
    border: none;
    border-radius: 5px;
    height: 100px;
  }

  .save-draft-btn {
    height: 42px;
    border: 1px solid #d2d2d2;
    background-color: #e1e1e1;
    color: #717475;
    font-size: 12px;
    font-family: "Poppins";
    font-weight: 300;
    /* height: 101px; */
    /* height: 43px; */
    height: 34px !important;
    width: 94px;
    border-radius: 5px !important;
  }

  .publish-btn {
    font-size: 12px;
    height: 34px !important;

    width: 94px;
  }

  .previewCheckBox label {
    font-size: 12px !important;
  }

  .featured-img h6 {
    padding: 8px;
    font-size: 12px;
    font-family: "Poppins";
    font-weight: 300;
    color: #ffffff;
  }

  .feature-img-main p {
    width: -moz-fit-content;
    width: fit-content;
    font-size: 12px;
    font-weight: 300;
    font-family: "Poppins";
    margin: 0 !important;
  }

  .titleAddBlogs input {
    font-size: 13px;
    padding: 5px 15px;
    font-family: "Poppins";
    background-color: #e1e1e1;
    border: 1px solid #d2d2d2;
  }

  .blogs-tab-specific span {
    color: #4f6367;
    font-size: 13px;
    font-family: "Poppins";
  }

  ul.order-list li a {
    font-size: 11px;
  }

  .latest-order-box-parent ul.sec-order-list li a {
    font-size: 11px;
  }

  .underline img {
    width: 35px;
    height: 35px;
  }

  .practionar-request .blog-order-box {
    padding: 15px 0;
  }

  .blog-second-order-box {
    height: 47.17px !important;
  }

  .checkin-btn-new {
    height: 23px !important;
    font-size: 12px;
  }

  .weekly-table .form-row-second {
    background: #ffffff;
    border: 1px solid #d5d5d5;
    border-radius: 50%;
    height: 20px !important;
    margin: 0px;
    overflow: hidden;
    position: relative;
    width: 20px !important;
  }

  .weekly-table .form-input:checked ~ .form-label-second {
    color: #ffffff;
    background-color: #258e00;
    height: 19px !important;
  }

  .weekly-table .form-input:checked ~ .form-label-second::before {
    content: "";
    display: block;
    position: absolute;
    width: 5px !important;
    height: 12px !important;

    border-bottom: 2px solid #ffffff;
    border-right: 2px solid #ffffff;
    transform: rotate(45deg);
    top: 48% !important;
    left: 79% !important;

    transform-origin: center;
    margin-top: -7px;
    margin-left: -8px;
  }

  .dashboards.customer-dashboard .weekly-table .form-label-second {
    line-height: 3 !important;
    font-size: 7px !important;
  }

  .weekly-table .form-input:checked ~ .form-label::before {
    content: "";
    display: block;
    position: absolute;
    width: 8px;
    height: 16px;
    border-bottom: 2px solid #ffffff;
    border-right: 2px solid #ffffff;
    transform: rotate(45deg);
    top: 41%;
    left: 62%;
    transform-origin: center;
    margin-top: -7px;
    margin-left: -8px;
  }

  .weekly-table .form-row {
    background: #ffffff;
    border: 1px solid #d5d5d5;
    border-radius: 50%;
    height: 31px;
    margin: 0px;
    overflow: hidden;
    position: relative;
    width: 31px;
  }

  .weekly-table .form-input:checked ~ .form-label {
    color: #ffffff;
    background-color: #258e00;
    height: 31px;
  }

  .weekly-table .form-label {
    color: #414141;
    border-radius: 50%;
    background-color: #ffffff;
    font-size: 12px;
    margin: 0;
    text-align: center;
    line-height: 30px;
    width: 100%;
    z-index: 1;
    line-height: 4 !important ;
  }

  .checkin-btn-new {
    margin-left: initial !important;
    width: 91px !important;
    height: 26px;
  }

  .outer-profile-prac {
    margin-top: 0rem !important;
  }

  .dashboards.customer-dashboard section.admin-side-side {
    margin-top: 5rem;
  }

  .css-c6g3g4-MenuPortal {
    top: auto !important;
    /* Disable any top positioning */
    bottom: calc(100% + 8px);
    /* Force the dropdown to open downwards */
  }

  .custom-section-gap {
    margin-top: 5.2rem !important;
  }
  .recenttestcustomer.recent-two {
    margin-top: 5px;
  }
  .recenttestcustomer h3 {
    font-size: 21px;
    margin-bottom: 13px !important;
  }
  .scoringNresults {
    padding: 5px 0;
  }
  .cust-prac-nav li {
    border: 1px solid;
    color: #7a9ea0;
    padding: 4px 12px;
    border-radius: 6px;
    color: #7a9ea0;
    font-family: "Poppins", sans-serif;
    cursor: pointer;
    font-size: 12px;
  }

  .practitioner-dash-top-search-parent input {
    margin-bottom: 5px;
  }

  /* .recenttestcustomer.recent-two .hexagon-box {
    padding: 25px 20px;
  } */
  .hexagon-box {
    padding: 20px 20px;
  }
  .recom-bio {
    background: var(--Antique-White);
    padding: 6px 10px;
    border-radius: 0px 0px 15px 15px;
  }

  .recommend-bybiomarker h2 {
    font-size: 16px;
    width: -moz-fit-content;
    width: fit-content;
    color: var(--Slate);
    font-weight: 600;
    margin: 0 auto;
  }

  .recommend-bybiomarker {
    background-color: #fffdf9;
    padding: 11px 7px;
    border: 1px solid #4f6367;
    border-radius: 18px;
    width: 14rem;
  }

  .recommend-outer-btn {
    width: 14rem;
  }

  .all-recommend-outer h3 {
    color: var(--Slate);
    font-weight: 600;
    font-size: 18px;
  }

  .all-recommend-inner-main {
    display: grid;
    grid-template-columns: 32.5% 32.5% 32.5%;
    gap: 10px;
  }

  .recommend-under-head {
    font-size: 14px;
    font-weight: 600;
    color: var(--Slate);
  }

  .recom-bio h3 {
    color: var(--Slate);
    font-size: 15px;
    font-weight: 600;
  }

  /* TOO LESS FONT */
  .recom-bio ul li {
    display: flex;
    color: #414141;
    font-size: 9px;
    align-items: center;
  }
  .more-recommendations {
    font-weight: bold;
    font-size: 9px;
  }
  .recom-bio h4 {
    font-size: 12px;
    color: #4f6367;
    font-weight: 600;
    margin: 10px 0;
  }

  /* TOO LESS FONT */

  .recommend-inner-main-custom {
    display: grid;
    grid-template-columns: 32.2% 32.2% 32.2% !important;
    gap: 14px;
    padding-left: 1rem;
  }

  .recommend-label-btns {
    display: flex;
    flex-wrap: wrap;
    row-gap: 13px;
    column-gap: 5px;
  }

  .recomendation-steps .recommend-last-btn {
    width: 57.5rem;
  }

  .recommend-spefic h4 {
    font-size: 16px;
    font-weight: 600;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 auto;
    color: var(--Slate);
  }

  .recommend-steps {
    color: var(--Slate);
    font-size: 10px;
    font-weight: 500;
    display: grid;
    grid-template-columns: 70% 30%;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .recommend-steps-head {
    color: var(--Slate);
    font-size: 14px;
    font-weight: 600;
  }

  .recomend-test-bar {
    font-size: 13px;
    background-color: #b8d9d9;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 6px 18px;
    border-radius: 30px;
    margin-bottom: 0.5rem;
    box-shadow: 10px 10px 31px -22px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 10px 10px 31px -19px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 10px 10px 31px -22px rgba(0, 0, 0, 0.75);
  }

  .recommended-head {
    color: var(--Slate);
    font-size: 15px;
    font-weight: 600;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 auto;
    padding-bottom: 1rem;
  }

  .recommended-details {
    font-size: 11px !important;
    color: var(--Slate);
  }

  .main-recommended-task {
    display: grid;
    grid-template-columns: 47% 47%;
    gap: 51px;
    width: 85%;
    margin: 0 auto;
  }

  .recommended-inner-head {
    color: var(--Slate);
    font-size: 15px;
    font-weight: 600;
  }

  .recommend-bybiomarker-sub h4 {
    color: var(--Slate);
    font-size: 15px;
    font-weight: 600;
  }

  /* .recomend-test-bar {
  font-size: 13px;
  background-color: #B8D9D9;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 18px;
  border-radius: 30px;
  margin-bottom: 0.5rem;
  box-shadow: 10px 10px 31px -22px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 10px 10px 31px -19px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 31px -22px rgba(0, 0, 0, 0.75);
} */
  .recommend-circle {
    height: 13px;
    width: 13px;
    border-radius: 50%;
  }
  .recom-bio ul li span {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2;
    height: 28px;
  }
  .recommend-btn .biomarkeraddbtn {
    width: 71px !important;
    height: 27px !important;
    font-weight: 500 !important;
    font-size: 13px;
  }

  .bybiomarker-head {
    font-size: 18px;
    color: var(--Slate);
    font-weight: 600;
    text-transform: capitalize;
  }

  h1.mean-admin-title {
    font-size: 17px;
    color: var(--Slate);
    font-weight: 500;
    text-transform: uppercase;
  }

  .cholesterol-detail-inner {
    width: 80.8%;
    font-size: 12px !important;
  }

  .vital-para-head {
    background: var(--Slate);
    font-size: 12px;
    padding: 12px 20px;
    color: #fff;
    border-radius: 8px;
    font-family: "Poppins", sans-serif;
    box-shadow: 10px 10px 31px -22px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 10px 10px 31px -22px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 10px 10px 31px -22px rgba(0, 0, 0, 0.75);
  }

  .vital-para {
    font-size: 11px !important;
    border: 1px solid var(--Slate);
    padding: 4px 56px 6px 23px;
    border-radius: 8px;
    font-family: "Poppins", sans-serif;
    box-shadow: 10px 10px 31px -22px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 10px 10px 31px -22px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 10px 10px 31px -22px rgba(0, 0, 0, 0.75);
  }

  .report-bar-head span {
    color: #86ef1d;
    font-size: 20px;
  }

  .vitals-report-chart canvas.canvasjs-chart-canvas {
    height: 270px !important;
  }

  .vital-chart-main div#chartContainer {
    height: 309px !important;
  }

  .vital-result-inner h4 {
    font-size: 15px;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 auto;
  }

  .inner-detail span {
    font-size: 10px;
    color: #7a9ea0;
  }

  .vital-stat-head {
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 auto;
    font-size: 15px;
  }

  .inner-three-cond span {
    display: flex;
    justify-content: center;
    font-family: "Poppins", sans-serif;
    font-size: 13px;
    color: #4f6367;
  }
  .test-btn.text-start button {
    font-size: 13px;
  }
  .tabprofile .test-btn button {
    border-radius: 50px;
    font-weight: 600;
    font-size: 13px;
  }
  .billing-info-head {
    padding-bottom: 5px;
  }
  label {
    margin-bottom: 0.2rem !important;
  }
  .input-field-cutsomer-modal {
    margin-top: 4px;
  }
  .modal-cutomer-input {
    padding: 4px;
  }

  /*  */
  .left-of-subs-two-grid-text {
    font-size: 12px;
  }
  .right-of-subs-two-box-txt-one {
    font-size: 14px;
  }
  .right-of-subs-two-box-txt-two {
    font-size: 18px;
  }
  .right-of-subs-two-box-txt-three {
    font-size: 12px;
  }
  .right-of-subs-two-box-txt-four {
    font-size: 12px;
  }
  /*  */
}

@media only screen and (max-width: 1200px) {
  .btn-login {
    display: flex;
    gap: 6px;
  }
  .main-menu.desktop ul {
    gap: 0px;
  }
}

@media only screen and (max-width: 999px) {
  /* .sidebaradmin.Customersidebar{
    height: 89.25vh;
  } */

  .sidebaradmin.Customersidebar {
    height: calc(100% - 70px);
  }
}

@media only screen and (max-width: 767px) {
  .annual-planmodal {
    width: 100% !important;
  }
  .customer-subcribtion-inner-modl2 {
    padding: 10px 5px;
  }
  .modify-billing-info-modal-main {
    max-width: 100% !important;
  }
  .recenttestcustomer {
    margin-top: 15px;
  }
  .customer-subs-text {
    width: 100%;
  }
  .customer-subs-text {
    width: 100%;
    text-wrap: nowrap;
  }
  .recom-bio .weekly-table-3 .form-row-second {
    height: 29px !important;
    width: 30px !important;
  }
  .recom-bio .weekly-table-3 .form-input:checked ~ .form-label-second {
    height: 27px !important;
  }
  .recom-bio .weekly-table-3 .form-input:checked ~ .form-label::before {
    margin-top: -7px;
    margin-left: -7px;
  }
  .recommend-specific-2 {
    height: 350px;
  }
  .weekly-table .form-input:checked ~ .form-label-second::before {
    top: 48% !important;
    left: 64% !important;
  }
  .recom-bio .weekly-4 .form-input:checked ~ .form-label-second {
    height: 29px !important;
  }

  .weekly-table .form-row-second {
    height: 33px !important;

    width: 38px !important;
  }
  .weekly-table .form-input:checked ~ .form-label-second {
    height: 31px !important;
  }
  .biomarkerbar {
    width: 100%;
  }
  .weekly-table .form-label-second {
    line-height: 31px !important;
  }
  .meter-hov {
    position: absolute;
    top: 8px;
  }
  .row.HealthScoree .left-detail {
    padding: 20px 20px;
  }
  .recent-time-head {
    font-size: 18px;
  }

  .scoringNresults {
    width: 48%;
  }

  .mob-hexagons {
    flex-flow: wrap;
    justify-content: center;
  }

  .scoringNresults:hover .cus-hover-dailog-2 {
    left: -40px !important;
  }

  .health-profile-customer-tab-parent .accordion-button {
    font-size: 10px;
    display: flex;
    align-items: center;
    white-space: nowrap; /* Prevent text from wrapping */
    overflow: hidden; /* Hide overflowing text */
    text-overflow: ellipsis;
  }

  .health-profile-customer-tab-parent
    #CircularProgressbarWithChildren__children {
    display: flex !important;
    align-items: center !important;
  }

  .health-profile-customer-tab-parent {
    width: 90%;
    margin: 0 auto;
    padding: 2rem 0 1rem;
  }

  .mob-biomarkerbar p {
    color: white;
    font-size: 14px !important;
  }

  .borderbg.p-3.d-flex.justify-content-between.flex-column.mob-abnormal {
    justify-content: space-between !important;
    align-items: start !important;
    text-align: start !important;
    margin-top: 2rem;
  }

  .mob-needimprov {
    display: flex !important;
    justify-content: space-between !important;
  }

  .bybiomarker-head {
    font-size: 15px;
    color: var(--Slate);
    font-weight: 600;
    text-transform: capitalize;
  }

  .recommended-inner-head {
    color: var(--Slate);
    font-size: 14px;
    font-weight: 600;
  }
  .borderbg.p-3.d-flex.justify-content-between.flex-column.mob-heart-score {
    margin-top: 4rem;
  }
  .vitals-data {
    margin-top: 5rem !important;
    margin-bottom: 1rem;
  }

  .mob-vital-inner-2 ul {
    grid-template-columns: 31% 32% 31% !important;
  }

  .mob-vital-inner-3 ul {
    grid-template-columns: 31% 32% 31% !important;
  }

  .colordot {
    width: 13px !important;
    height: 13px !important;

    right: -16px !important;
  }

  .mob-vitals-data-2 {
    margin-top: 1rem !important;
  }

  .vital-stats-outer-heartscore {
    background-color: #fbf7ef !important;
    padding: 1rem 0.5rem;
  }

  .outer-three-cond-healthscore {
    font-size: 12px;
  }

  .circle-container img {
    width: 90%;
  }

  .report-bar-head {
    width: max-content;
    margin: 0 auto;
    padding-bottom: 4rem;
    padding-top: 1rem;
  }

  .circle-container .chart-wrap canvas {
    width: 250px !important;
    height: 250px !important;
  }

  .recommend-bybiomarker {
    width: 100%;
  }

  .all-recommend-inner-main {
    display: grid;
    grid-template-columns: initial;
    gap: 10px;
  }

  .recommend-outer-btn {
    width: 100%;
  }

  .recommend-last-btn {
    justify-content: center !important;
    padding-right: 0;
  }

  .weekly-table {
    gap: 10px !important;
  }

  .recommend-inner-main-custom {
    display: grid;
    grid-template-columns: 100% !important;
    gap: 14px;
    padding-left: 1rem;
  }

  .main-recommended-task {
    display: grid;
    grid-template-columns: 100%;
    gap: 10px;
    width: 100%;
  }

  .recommend-testbar-order {
    display: grid;
    grid-template-columns: 100%;
    justify-content: space-between;
  }

  .weekly-table .form-label-second {
    font-size: 8px !important;
  }

  .suplements-main-outer {
    position: relative;
    margin-bottom: 1rem;
  }

  .customer-all-test-result {
    width: 1000px;

    grid-template-columns: 15% 62% 23%;
  }

  .cust-search {
    display: flex;
    justify-content: center;
    gap: 1rem;
  }

  .cust-prac-nav li {
    padding: 8px 0px;

    font-size: 10px;
    width: 120px;
    display: flex;
    justify-content: center;
  }

  .cust-prac-nav ul {
    flex-flow: wrap;
    gap: 1rem;
  }

  .practitioner-dash-top-search-parent input {
    padding-top: 0.6rem !important;
    padding-bottom: 0.6rem !important;
  }

  .cust-prac-nav {
    overflow-x: scroll;
  }

  .cust-prac-nav ul {
    width: 1000px;
  }

  i.fa-solid.fa-chevron-left.back-arrow-icon {
    margin-left: -1rem !important;
  }

  .cust-pract-profile-detail {
    margin-left: 0.5rem !important;
  }

  .first-inner {
    background-color: #4f6367;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-weight: 500;
  }

  .rest-inner {
    font-size: 12px;
  }

  .hexagon-box p,
  .hexagon-box span {
    font-size: 12px;
  }

  .health-profile-customer-tab-parent .accordion-body {
    padding: 15px 0;
  }

  .practitioner-notifications {
    padding: 1rem !important;
  }

  .notification-img {
  }

  .notification-img {
    width: 70px;
  }
  .cust-prac-nav {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
  }
  .bar-per {
    right: -25px;
  }
  .cirularprobar {
    position: absolute;
    right: 5rem;
  }
  .outer-three-cond-healthscore {
    padding-left: 1rem;
  }
  .weekly-table .form-input:checked ~ .form-label {
    height: 26px;
  }
  .weekly-table-2 .form-row {
    height: 28px !important;
    width: 30px !important;
  }
  .weekly-table .form-label {
    line-height: 3.4 !important;
  }
  .weekly-table .form-input:checked ~ .form-label::before {
    margin-top: -9px;
  }
  .under-graph .slick-slider button {
    display: none !important;
  }
  .check-up-detail-inner {
    overflow-y: initial;
    max-height: initial;
  }
  .This-checkup-content {
    grid-template-columns: 100%;
    gap: 0rem;
  }
  .check-up-detail-outer-accordiun {
    padding: 1rem 1rem;
  }
}

@media only screen and (max-width: 400px) {
  .crd-btn-grid-prod {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
  .scoringNresults:hover .cus-hover-dailog-2 {
    left: -66px !important;
  }
}

@media only screen and (max-width: 365px) {
  .modal-footer a {
    font-size: 11px !important;
  }
  .cirularprobar {
    position: absolute;
    right: 50px;
  }
  .health-profile-customer-tab-parent .accordion-button::after {
    position: absolute;
    right: 3px;
  }
}
