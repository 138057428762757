/* QUESTION BOX STYLES */
.parent-box-health-profile-content {
  background-color: var(--Pale-Aqua);
  border-radius: 30px;
  margin-bottom: 1.5rem;
}

.health-profile-customer-tab-parent {
  width: 80%;
  margin: 0 auto;
  padding: 3rem 0;
}

.health-profile-customer-tab-parent .accordion-button:focus {
  box-shadow: none !important;
}

.health-profile-customer-tab-parent .accordion-button::after {
  background-image: url(../images/downarrow.png);
  filter: none;
}

.health-profile-customer-tab-parent .accordion-button:not(.collapsed)::after {
  background-image: url(../images/downarrow.png);
  transform: rotate(180deg) scale(0.7) translateY(10px) !important;
}

.helth-pro-user-img img {
  width: 120px;
  height: 120px;
  border-radius: 90px;
  margin-bottom: 10px;
}

.recom-bio-slider {
  width: 97%;
  margin: 0 auto;
}

.arrow-annual-subs {
  font-size: 2.5em;
  color: #4f6367;
}

.cust-overview-slider .slick-dots li button:before {
  font-family: "slick";
  font-size: 18px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: "•";
  text-align: center;
  opacity: 1.1;
  color: var(--Pale-Aqua);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.cust-overview-slider .slick-dots li.slick-active button:before {
  color: var(--Slate);
}

.meter-hov {
  position: absolute;
  top: -70px;
  width: 100%;
  background: white;
  border: 1px solid #4f6367;
  border-radius: 10px;
  padding: 9px 0;
  opacity: 0;
  left: 0;
  transition: all 0.5s ease-in-out;
  z-index: 999;
}

.meter-hov p {
  background: transparent;
  font-size: 13px;
  width: 100%;
  color: #4f6367;
  display: block;
  top: 0;
  height: auto;
}

.circleprobarbiomarkers:hover .meter-hov {
  opacity: 1;
}

.helth-pro-user-img {
  text-align: center;
}

button.accordion-button.collapsed {
  border-radius: 15px !important;
}

.health-profile-customer-tab-parent .accordion-button {
  border: 0px solid var(--Slate);
  border-radius: 15px 15px 0px 0px !important;
  background: #fff;
  font-size: 1;
  color: var(--Slate);
  font-weight: 600;
}

.health-profile-customer-tab-parent .accordion-item {
  background: none;
  box-shadow: none;
  border: none;
  margin-bottom: 1.5rem;
}

.fertin canvas.canvasjs-chart-canvas {
  margin: 0;
}

.fertin a.canvasjs-chart-credit {
  display: none;
}

.health-profile-customer-tab-parent .accordion-body {
  background-color: #fff;
  border-radius: 0 0 15px 15px;
}

.content-accordion-body-customer-health {
  width: 90%;
  margin: 0 auto;
}

.field-customer-health {
  border: 1px solid var(--Slate);
  min-height: 34px;
  border-radius: 0.4rem;
  color: #515151;
  padding: 0 1rem;
  font-size: 13px;
}

.label-customer-health {
  color: #515151;
  font-weight: 600;
  font-size: 13px;
  margin-bottom: 0.8rem;
}

.parent-inputlabel-body-customer-health {
  margin-bottom: 1rem;
}

.health-profile-radio {
  padding: 0.4rem 0;
  border: 1px solid var(--Slate);
  border-radius: 6px;
  min-height: 34px;
  cursor: pointer;
  font-size: 13px;
}

.health-profile-radio-min-width {
  min-width: 101px;
}

.btn-check:checked ~ .health-profile-radio {
  background-color: var(--Slate);
  color: #fff;
}

.cancel-save-btn {
  border: 1px solid var(--Slate);
  color: var(--Slate);
  font-size: 13px;
  min-width: 95px;
  padding: 0.3rem 0;
  font-weight: 500;
  border-radius: 30px;
  transition: all 0.3s ease-in-out;
}

.cancel-save-btn:hover {
  background-color: var(--Slate);
  color: #fff;
}

/* QUESTION BOX STYLES */

/* customer mean dashboard page start */
.recenttestcustomer {
  background: var(--dark-Pale-Aqua);
  border-radius: 10px;
  padding: 1rem 2rem;
}

.recent-glace {
  background: var(--white);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 13.7px 10px;
  margin-bottom: 7px;
  font-size: 13px;
}

.recent-glace p {
  margin: 0;
  color: var(--Slate);
}

.recent-glace span {
  color: white;
  border-radius: 50px;
  background: red;
  padding: 8px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  width: 24px;
}

.test-view-btn-customer {
  background: white;
  padding: 7px 20px;
  border-radius: 50px;
  color: var(--Slate);
  font-weight: 600;
  width: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  font-size: 13px;
  margin: 0 auto;
}

.recent-glace.glace-2 span {
  height: 5px !important;
  display: block;
  width: 130px;
  padding: 0;
}

.hexagon-box p,
.hexagon-box span {
  font-size: 13px;
  font-weight: 600;
  color: var(--Slate);
  margin: 0;
}

.scoringNresults {
  background: #fbf7ef;
  width: 50%;
  padding: 10px 0;
  border-radius: 15px;
  position: relative;
}

.hexagon-box {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  text-align: center;
  padding: 33px 20px;
  position: relative;
}

.recenttestcustomer.recent-two .hexagon-box {
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hexagon-box.first {
  background-image: url(../images/healthhexagon.png);
  height: 130px;
}

.hexagon-box.second {
  background-image: url(../images/hexagonsecond.png);
  height: 130px;
}

.recenttestcustomer h3 {
  font-size: 20px;
}

.hexagon-box.Fourth {
  background-image: url(../images/hexagonthird.png);
  height: 130px;
}

/* customer mean dashboard page end */

/* Customer all test page start */
.customer-test-title h1 {
  font-weight: 200;
  font-size: 25px;
}

.customer-test-title h1 span {
  font-weight: 500;
}

.radio-btn-tb input {
  accent-color: var(--Slate);
}

.customer-test-title h4 {
  font-weight: 200;
  font-size: 20px;
}

.radio-btn-tb label {
  font-size: 13px;
  font-weight: 400;
  color: #797f82;
  padding-bottom: 5px;
}

.customer-all-test-result {
  display: grid;
  grid-template-columns: 25% 45% 30%;
  align-items: center;
  padding: 10px 34px;
  border-radius: 10px;
}

.alltest-box-1 ul {
  display: grid;
  grid-template-columns: 20% 20% 60%;
}

.responsve-alltest-box ul {
  grid-template-columns: 20% 35% 45%;
  margin-top: 5px !important;
}

.customer-all-test-result ul li {
  font-size: 16px;
  color: var(--Slate);
  gap: 20px;
}

.alltest-box-1 li {
  display: flex;
  align-items: center;
}

.customer-all-test-result ul li p span {
  border-radius: 30px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  color: white;
  justify-content: center;
}

.customer-all-test-result ul li p {
  display: flex;
  gap: 4px;
  margin: 0;
  align-items: center;
}

.btn-all-test-view {
  background: var(--Slate);
  font-size: 13px;
  color: white;
  border-radius: 40px;
  width: 130px;
  display: flex;
  height: 32px;
  justify-content: center;
  align-items: center;
}

.test-btn a {
  background: var(--Slate);
  font-size: 13px;
  color: white;
  border-radius: 40px;
  width: 130px;
  display: flex;
  height: 32px;
  justify-content: center;
  align-items: center;
}

.Health-Profile-tab h1 {
  font-size: 25px;
  margin: 0;
}

.test-btn.delt-acc a {
  width: 220px;
}

.alltest-box p {
  font-size: 24px;
  color: var(--Slate);
  font-weight: 300;
}

.btn-test-view-more {
  background: var(--Slate);
  font-size: 13px;
  color: white;
  border-radius: 40px;
  font-weight: 600;
  width: 130px;
  display: flex;
  height: 32px;
  justify-content: center;
  align-items: center;
}

.customer-all-test-result .alltest-box {
  border-right: 1px solid white;
  margin-right: 15px;
}

/* Shahmir Css  */
.inner-accordion-body-syst .accordion-button {
  border: none !important;
  background: var(--Slate) !important;
  color: #fff !important;
  border-radius: 40px !important;
}

.inner-accordion-body-syst .accordion-button::after {
  filter: invert(1) brightness(5) !important;
}

/* .health-profile-customer-tab-parent .accordion-button span {
  position: absolute;
  right: 4rem;
  font-size: 1.5rem;
  display: flex;
  gap: 10px;
  align-items: center;
} */
.para-acc-new {
  font-size: 14px;
}

/* Shahmir Css  */
.sidebaradmin.Customersidebar {
  height: 86vh;
}

.input-area input {
  padding: 0.4rem 0;
  border: 1px solid var(--Slate);
  border-radius: 6px;
  min-height: 34px;
  cursor: pointer;
  font-size: 13px;
  width: 100%;
  padding: 0 15px;
}

.dashboards.customer-dashboard section.admin-side-side {
  margin-top: 6rem;
}

.progrss-br {
  /* font-size: 20px; */
  /* margin: 20px; */
  position: relative;
  padding: 0;
  width: 3em;
  height: 3em;
  background-color: #7a9ea0;
  border-radius: 50%;
  line-height: 5em;
  position: absolute;
  right: 3rem;
  top: 2px;
}

.progrss-br span {
  border-radius: 50%;
  width: 3em;
  height: 3em;
  position: absolute;
  clip: rect(0, 5em, 5em, 2.5em);
  background-color: var(--Slate);
}

.bar-per {
  color: var(--Slate);
  font-weight: 600;
  position: absolute;
  right: -47px;
  top: 10px;
}

.progrss-br:after {
  border: none;
  position: absolute;
  top: 0.35em;
  left: 0.35em;
  text-align: center;
  display: block;
  border-radius: 50%;
  width: 2.3em;
  height: 2.3em;
  background-color: var(--Pale-Aqua);
  content: " ";
}

.progrss-br.inner-acc span {
  background: white;
}

.progrss-br.inner-acc .bar-per {
  color: white;
}

.cirularprobar .CircularProgressbar .CircularProgressbar-path {
  stroke: #4f6367 !important;
}

.cirularprobar .CircularProgressbar .CircularProgressbar-trail {
  stroke: #7a9ea0 !important;
}

.cirularprobar {
  background-color: var(--Pale-Aqua);
  border-radius: 50%;
}

.cirularprobar {
  position: absolute;
  right: 7rem;
}

.cirularprobar.active svg.CircularProgressbar {
  opacity: 0;
}

.cirularprobar.active {
  background-color: initial;
}

.inner-accordion-body-syst .accordion-button .bar-per {
  color: white;
}

/* .inner-accordion-body-syst .accordion-button .cirularprobar.active img {
  filter: grayscale(38) brightness(3.5);
} */

.inner-accordion-body-syst
  .accordion-button
  .CircularProgressbar
  .CircularProgressbar-trail {
  stroke: white !important;
}

.inner-accordion-body-syst
  .accordion-button
  .cirularprobar
  .CircularProgressbar
  .CircularProgressbar-path {
  stroke: #7a9ea0 !important;
}

/* CUSTOMER---BIOMARKER */
.container-1600 {
  width: 1600px;
  margin: 0 auto;
}

/* .cust-head{
  padding-top: 5rem;
    padding-left: 2rem;
} */
.cust-search {
  display: flex;
  justify-content: end;
  gap: 1rem;
}

.cust-options {
  background-color: #d5d1d1;
  border: #d5d1d1;
  border-radius: 4px;
  padding: 3px 8px 3px 9px;
}

input.cust-searchin:visited {
  border: none;
}

input.cust-searchin:focus {
  border: none;
}

input.cust-searchin {
  background-color: #d5d1d1;
  border: #d5d1d1;
  border-radius: 4px;
  padding: 3px 8px 3px 9px;
  width: 90%;
}

.cust-main span {
  position: absolute;
  top: 20%;
  right: 27rem;
}

.cust-head-modal h3 {
  text-align: center;
  font-size: 40px;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  padding-top: 2rem;
}

.cust-head-modal span {
  color: #46b710;
}

.left-inner-details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.right-inner-details {
  display: flex;
  flex-direction: column;
  width: max-content;
  justify-content: space-between;
}

/* .left-detail{
  width: 80%;

}
.right-detail{
  width: 80%;

} */
.first-inner {
  background-color: #4f6367;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 500;
}

.rest-inner {
  background-color: #b8d9d9 !important;
  justify-content: space-between;
  display: flex;
  font-size: 16px;
  font-family: "Poppins", sans-serif;

  font-weight: 500;
  border-radius: 50px;
  padding: 10px;
  margin-top: 0.7rem;
}

.modal-detail {
  display: grid;
  grid-template-columns: 46% 46%;
  margin: 0 auto;
  width: 100%;
  gap: 20px;
  justify-content: center;
  padding-bottom: 2rem;
  height: 600px;
  overflow-y: scroll;
}

.modal-detail h2 {
  text-align: center;
  font-size: 25px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.modal-dialog {
  max-width: 55%;
}

.first-inner {
  background-color: #4f6367;
  justify-content: space-between;
  display: flex;
  border-radius: 50px;
  padding: 10px;
  margin-top: 0.7rem;
  color: #ffffff;
}

.cust-head-modal p {
  width: 70%;
  text-align: center;
  margin: 0 auto;
  font-size: 16px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}

.close-icon i {
  background-color: #f53546;
  border: 1px solid #ffffff;
}

.close-icon .closehandlebtndelet {
  color: #f5f5f5;
}

/* input.option{
  background-color: #2E96D7;
} */
/* CUSTOMER---BIOMARKER */
/* CUSTOMER---PRACTITIONERS */

.cust-pract .signdeclinebtn button {
  height: 45px !important;
}

.cust-pract .signdeclinebtn .cust-pract-account {
  width: 190px !important;
}

.cust-pract h1 {
  padding-top: 2rem;

  padding-bottom: 2rem;
}

.cust-search input {
  width: initial !important;
  background-color: #f2f2f2;
}

.pr-info-client h1 {
  color: #ffffff !important;
  background-color: #749598;
  border-radius: 40px;
  width: 90%;
  margin: 0 auto;
  font-size: 30px !important;
  padding: 10px 0;
  font-weight: 600;
}

.outer-profile-prac {
  margin-top: 6.8rem !important;
}

.newly-cust-sidebar {
}

/* .pr-info-client{
  position: relative;
  margin-left: 2rem;

} */
.cust-prac-nav li {
  border: 1px solid;
  color: #7a9ea0;
  padding: 4px 12px;
  border-radius: 6px;
  color: #7a9ea0;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
  font-size: 15px;
}
.report-custom-2 {
  width: 78%;
}
.cust-prac-nav ul {
  display: flex;
  gap: 2rem;
}

.custom-section-gap {
  margin-top: 0rem !important;
}
.custom-section-gap-2 {
  margin-top: 7rem !important;
}
.cust-prac-nav {
  padding-bottom: 1rem;
  padding-top: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.vitals-data i:hover {
  fill: orange;
  font-weight: 900;
}

.cust-prac-nav ul li.active {
  font-weight: bold;
  color: #ffffff;
  background: #7a9ea0;
}

.cust-pract .view-test:hover {
  background-color: #4f6367 !important;
}

.cust-pract section.admin-side-side.table-boxes .signdeclinebtn button span {
  width: initial;
  height: initial;
  background: initial;
  border-radius: 50%;
  color: #4f6367;
}

.backdrop-loader-parent {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.backdrop-loader-parent:before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00000055;
  z-index: -1;
}

.sechomefaq button.accordion-button {
  font-size: 1.3rem;
  font-weight: 500;
  font-family: "Poppins";
  color: var(--Slate);
  text-transform: uppercase;
}

.home-accord-sec {
  padding-bottom: 2rem;
}

.home-accord-sec .accordion-item {
  box-shadow: 10px 10px 31px -22px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 10px 10px 31px -22px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 31px -22px rgba(0, 0, 0, 0.75);
}

.cus-img-upload {
  width: 120px;
  height: 120px;
}

/* CUSTOMER---PRACTITIONERS */

.avatar-upload {
  position: relative;
  max-width: 145px;
  margin: 0px auto;
}

.avatar-upload .avatar-edit {
  position: absolute;
  right: 12px;
  z-index: 1;
  top: 10px;
}

.avatar-upload .avatar-edit input {
  display: none;
}

.avatar-upload .avatar-edit input + label {
  display: inline-block;
  width: 34px;
  height: 34px;
  margin-bottom: 0;
  border-radius: 100%;
  background: #ffffff;
  border: 1px solid transparent;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  font-weight: normal;
  transition: all 0.2s ease-in-out;
}

.avatar-upload .avatar-edit input + label:hover {
  background: #f1f1f1;
  border-color: #d6d6d6;
}

.avatar-upload .avatar-edit input + label:after {
  content: "\f040";
  font-family: "FontAwesome";
  color: #757575;
  position: absolute;
  top: 3px;
  left: 0;
  right: 0;
  text-align: center;
  margin: auto;
}

.avatar-upload .avatar-preview {
  width: 140px;
  height: 140px;
  position: relative;
  border-radius: 100%;
  border: 6px solid #f8f8f8;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
}

.avatar-upload .avatar-preview > div {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.cus-hover-dailog {
  font-size: 11px;
  color: #4f6367;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  display: none;
  position: absolute;
  top: 0;
}

.scoringNresults:hover .cus-hover-dailog {
  display: block;
  color: #4f6367;
  text-wrap: nowrap;
  left: 0;
  top: -27px;
  background-color: #ffffff;
  padding: 5px;
  border-radius: 6px;
  border: 1px solid;
}

div#imagePreview {
  background-image: url(https://svgur.com/i/4V0.svg);
  background-size: cover;
}

.bar-graph {
  width: 94px;
  background: white;
  border-radius: 10px;
  display: flex;
  justify-content: space-around;
  height: 43px;
  align-items: center;
  padding: 0 9px;
}

.bar-graph span {
  height: 31px;
  width: 1px;
  background: red;
  transform: rotate(15deg);
}

.bar-graph.desirableGreen span {
  background: #20c200 !important;
  background-color: #20c200 !important;
}

.bar-graph.abnormalRed span {
  background: red !important;
  background-color: red !important;
}

.bar-graph.atRiskYellow span {
  background: #feb117 !important;
  background-color: #feb117 !important;
}

.bar-handle {
  height: 43px;
  width: 2px;
  background: #2e2e2e;
  left: 7%;
  position: relative;
}

.vitals-data i {
  color: orange;
  position: absolute;
  right: -16px;
  bottom: -39%;
  font-size: 12px;
}

.bar-handle p {
  background: red;
  width: 13px;
  height: 13px;
  border-radius: 15px;
  left: -6px;
  position: absolute;
  top: 7%;
}

.recom-bio h3 {
  color: var(--Slate);
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.recom-bio ul {
  display: flex;
  gap: 0px;
  align-items: center;
}

.recom-bio ul li {
  display: flex;
  color: #414141;
  font-size: 12px;
  align-items: center;
  -webkit-line-clamp: 1;
  margin-right: 10px;
}

.recom-bio ul li span {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
  height: 33px;
}

.dot span {
  font-size: 28px;
}

/* .recom-bio ul li {
  color: #414141;
  font-size: 16px;
} */

.recom-bio h4 {
  font-size: 16px;
  color: #4f6367;
  font-weight: 600;
  margin: 10px 0;
}

.recom-bio {
  background: var(--Antique-White);
  padding: 20px 10px;
  border-radius: 0px 0px 15px 15px;
}

.cust-head-modal-2 {
  background-color: #faf9f7;
  border-radius: 8px;
}

.cust-head-modal-2 h3 {
  display: flex;
  justify-content: space-between;
  text-align: start;
  font-size: 25px;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  padding-top: 2rem;
  padding-left: 2rem;
  color: #4f6367;
}

.cust-head-modal-2 p {
  width: 50%;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  padding-left: 2rem !important;
  color: #797f82;
}

.cust-head-modal-2 span {
  color: green;
}

/* .cust-modal-main-2{
  background-color: #FBF7EF !important;
} */

canvas.canvasjs-chart-canvas {
  width: 100% !important;
  margin: initial !important;
  height: 300px !important;
}

.graph-and-detail {
  display: grid;
  grid-template-columns: 30% 70% !important;
  gap: 20px;
  width: 100%;
  margin: 0 auto;
  background: #faf9f7;
  padding: 20px 70px;
  border-radius: 30px;
}

.result-graph-cust h3 {
  font-family: "Poppins", sans-serif;
  font-size: 32px;
  font-weight: 700;
  color: #4f6367;
  text-align: center;
}

.result-graph-cust p {
  font-size: 25px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #4f6367;
  padding: 20px 70px !important;
}

.inner-prod-box .slick-prev:before,
.inner-prod-box .slick-next:before {
  color: #4f6367 !important;
  font-size: 28px !important;
}

.result-graph-mean {
  width: 95%;
  margin: 0 auto;
}

.under-graph .slick-prev {
  left: -40px;
}

.inner-detail {
  padding-bottom: 15px;

  display: flex;
  justify-content: space-between;
}

.left-detail-graph h4 {
  text-align: center;
  font-size: 15px;
  color: #7a9ea0;
}

.inner-detail span {
  font-size: 12px;
  color: #7a9ea0;
}
.inner-detail li {
  font-size: 15px;
}
.square-result {
  height: 10px;
  width: 10px;
  background-color: #0080ff;
}
.inner-detail-3 span {
  font-size: 15px;
}
.inner-detail-3 {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
.inner-detail span:first-child {
  color: black;
}
.new-result-line {
  display: flex;
  align-items: center;
  gap: 6px;
}
.under-graph .fourth-box {
  border: 1px solid var(--Slate);
  background-color: var(--Antique-White);
  border-radius: 15px;

  min-height: 100% !important;
  padding: 13px 0;
  width: 95% !important;
  margin-bottom: 2rem;
}

.under-graph .fourth-grid {
  grid-gap: 0;
  grid-template-columns: 33.33% 33.33% 33.33%;
  width: 100%;
  margin-left: 10px;
}

.cust-container {
  width: 1200px;
}

.cust-modal-main-2 .modal-content {
  background: var(--Antique-White);
  padding: 10px 20px;
}

.fourth-img h5 {
  font-size: 15px;
  line-height: 17px;
  display: flex;
  justify-content: center;
}

.left-inner-grid h6 {
  font-size: 14px;
}

.right-inner-grid button {
  font-size: 13px;
  padding: 1px 7px;
}

div#chartContainer {
  height: 250px !important;
}

.customer-bar {
  width: 34% !important;
}

.rangslidetest.customer-bar {
  margin-left: auto;
  margin-bottom: 20px;
}

.cust-modal-main-2.modal .closehandlebtndelet {
  top: -23px;
  right: -23px;
}

.stat-graph {
  background-color: #faf9f7;
  border-radius: 8px;
}

a.canvasjs-chart-credit {
  display: none;
}

.per-results {
  background: var(--Slate);
  border-radius: 8px;
  font-size: 13px;
  font-weight: 500;
  color: #ffff;
  font-family: "Poppins";
  width: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  font-weight: 600;
  margin: 0 auto;
  margin-bottom: 11px;
}

/* biomarker healthscore */
.row.HealthScoree .left-detail {
  padding: 10px 20px;
  background: #fbf7ef;
  border-radius: 12px;
}

.row.HealthScoree .first-inner {
  background: #b8d9d9 !important;
  border-radius: 0;
}

.row.HealthScoree .first-inner span {
  color: #4f6367;
}

.row.HealthScoree .rest-inner {
  border-radius: 0;
  margin: 0;
  padding-bottom: 15px;
}

.vite-score {
  border-radius: 10px;
  background: #b8d9d9;
  padding: 10px 0;
}

.row.HealthScoree .left-inner-details h2 {
  font-size: 20px;
  text-align: center;
  color: #4f6367;
}

.row.HealthScoree .left-inner-details p {
  text-align: center;
  font-size: 13px;
  color: #4f6367;
}

.row.HealthScoree .rest-inner span {
  color: #4f6367;
}

.vital-para-head {
  background: var(--Slate);
  font-size: 13px;
  padding: 15px 22px;
  color: #fff;
  border-radius: 8px;
  font-family: "Poppins", sans-serif;
  box-shadow: 10px 10px 31px -22px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 10px 10px 31px -22px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 31px -22px rgba(0, 0, 0, 0.75);
}

.vital-para {
  font-size: 13px;
  border: 1px solid var(--Slate);
  padding: 6px 56px 6px 23px;
  border-radius: 8px;
  font-family: "Poppins", sans-serif;
  box-shadow: 10px 10px 31px -22px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 10px 10px 31px -22px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 31px -22px rgba(0, 0, 0, 0.75);
}

.cholesterol-detail-inner {
  width: 80.8%;
  font-size: 13px;
}

div#chartContainer {
  padding-top: 0;
}

.vital-report-bar {
  background-color: #faf9f7;
  padding: 10px 10px 35px 10px;
  border-radius: 8px;
}

.report-bar-head {
  width: max-content;
  margin: 0 auto;
  padding-bottom: 1rem;
  padding-top: 0rem;
}

.cust-vital-bar {
  width: 62% !important;
  margin: 0 auto;
}

.vitals-report-chart div#canvasjs-react-chart-container-1708070696352 {
  height: 300px !important;
}

.vitals-report-chart canvas.canvasjs-chart-canvas {
  height: initial !important;
}

.vital-result-detail {
  background-color: #ffffff;
  padding: 20px;
  margin-top: 1rem;
  border-radius: 8px;
}

.vital-result-inner h4 {
  font-size: 20px;
  width: fit-content;
  margin: 0 auto;
  color: #4f6367;
  font-weight: 500;
}

.vital-result-inner .inner-detail {
  font-size: 12px;
  font-family: "Poppins", sans-serif;

  border-bottom: 1px solid #e9e9e9;
  width: 69%;
  margin: 0 auto;
  padding-bottom: initial;
  padding: 4px;
}

.vital-result-inner .inner-detail span:first-child {
  color: black;
}

.vital-stats-outer {
  background-color: #faf9f7;
  margin-top: 1rem;
  border-radius: 8px;
  padding-bottom: 8px;
}

.vital-stat-head {
  width: fit-content;
  margin: 0 auto;
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #4f6367;
}

.vital-stat-detail {
  width: fit-content;
  margin: 0 auto;
}

.vital-stat-detail span {
  font-size: 13px;
  font-family: "Poppins", sans-serif;
}

.outer-three-cond {
  padding-top: 1rem;

  width: 100%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 28% 28% 28%;
  justify-content: space-around;
  font-size: 16px;
  text-wrap: nowrap;
}

.inner-cond-circle {
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 50%;
}

.cond-circle-outer {
  display: flex;
  align-items: center;
  gap: 4px;
  justify-content: center;
}

.inner-three-cond span {
  display: flex;
  justify-content: center;
  font-family: "Poppins", sans-serif;
  font-size: 13px;
  color: #4f6367;
}

.report-bar-head span {
  color: #86ef1d;
}

.circle {
  background: red;
  width: 250px;
  height: 250px;
  border-radius: 50%;
  z-index: 0;
  position: relative;
  transform-origin: center;
  background: linear-gradient(
    90deg,
    rgb(238, 24, 24) 0%,
    rgb(238, 24, 24) 0%,
    rgb(255, 165, 0) 17.3913%,
    rgb(31, 194, 0) 22.3913%,
    rgb(31, 194, 0) 27.3913%,
    rgb(31, 194, 0) 68.2609%,
    rgb(31, 194, 0) 73.2609%,
    rgb(255, 165, 0) 78.2609%,
    rgb(238, 24, 24) 83.2609%,
    rgb(238, 24, 24) 98.2609%,
    rgb(238, 24, 24) 100%
  );

  top: 0px;
}

.circle:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  width: 230px;
  height: 230px;
  border-radius: 50%;
  background-color: #faf9f7;
  transform: translate(-50%, -50%);
}

.circle:after {
  content: "";
  position: absolute;
  left: 0px;
  bottom: 0;
  width: 100%;
  height: 17px;
  background: #faf9f7;
  border-radius: 50%;
}

.circle-container {
  width: fit-content;
  margin: 0 auto;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.recomend-test-bar {
  font-size: 15px;
  background-color: #b8d9d9;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 18px;
  border-radius: 30px;
  margin-bottom: 0.5rem;
  box-shadow: 10px 10px 31px -22px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 10px 10px 31px -19px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 31px -22px rgba(0, 0, 0, 0.75);
}

.recommend-circle {
  height: 20px;
  width: 20px;
  border-radius: 50%;
}

.red-circle-recommend {
  background-color: #ff0000;
}

.yellow-circle-recommend {
  background-color: #ffaa00;
}

.orange-circle-recommend {
  background-color: #ffaa00;
}

.green-circle-recommend {
  background-color: #46b710;
}

.recommend-bybiomarker {
  background-color: #fffdf9;
  padding: 15px;
  border: 1px solid #4f6367;
  border-radius: 18px;
  width: 24rem;
}

.recommend-under-head {
  font-size: 16px;
  font-weight: 600;
  color: var(--Slate);
}

.recommend-bybiomarker h2 {
  font-size: 20px;
  width: fit-content;
  color: var(--Slate);
  font-weight: 600;
  margin: 0 auto;
}

.recommend-test-outer {
  padding-top: 1rem;
}

.recommend-btn {
  display: flex;
  justify-content: center;
  font-weight: 600;
}

.recommend-outer-btn {
  width: 24rem;
}

.all-recommend-outer h3 {
  color: var(--Slate);
  font-weight: 600;
  font-size: 22px;
}

.recommend-spefic h4 {
  font-size: 20px;
  font-weight: 600;
  width: fit-content;
  margin: 0 auto;
  color: var(--Slate);
}

.recommend-steps {
  color: var(--Slate);
  font-size: 13px;
  font-weight: 500;
  display: grid;
  grid-template-columns: 70% 30%;

  padding-top: 2rem;
  padding-bottom: 2rem;
}

.recommend-steps-head {
  color: var(--Slate);

  font-size: 16px;
  font-weight: 600;
}

.all-recommend-inner-main {
  display: grid;
  grid-template-columns: 25.5% 25.5% 25.5%;
  gap: 25px;
}

.recommend-spefic {
  background-color: #fbf7ef;
  padding: 24px 15px;
  border: 1px solid #4f6367;
  border-radius: 18px;
}

.recommend-small-circle {
  height: 10px;
  width: 10px;
  border-radius: 50%;
}

.recomend-label-specific {
  display: flex;
  align-items: center;
  gap: 4px;
  background-color: #7a9ea0;
  width: fit-content;
  padding: 4px 8px;
  border-radius: 8px;
  font-size: 13px;
  color: #fff;
}

.recommend-labels {
  padding-top: 2rem;
  height: 129px;
  overflow: hidden;
}

.recommend-label-btns {
  display: flex;
  flex-wrap: wrap;
  row-gap: 6px;
  column-gap: 8px;
}

.recommend-btn .biomarkeraddbtn {
  border-radius: 8px !important;
  width: 88px !important;
  font-weight: 500 !important;
  border-radius: 50px !important;
}

.new-recommend-btns {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 10px;
}

.recommend-last-btn {
  justify-content: end !important;
  padding-right: 5rem;
}

.bybiomarker-head {
  font-size: 22px;
  color: var(--Slate);
  font-weight: 600 !important;
  text-transform: capitalize;
}

.recommend-biomarker-outer {
  background-color: #fbf7ef;
  padding: 20px 25px;
  border-radius: 10px;
  margin-top: 7rem !important;
}

.recommend-bybiomarker-sub h4 {
  color: var(--Slate);
  font-size: 18px;
  font-weight: 600;
}

.recommend-testbar-order {
  display: grid;
  grid-template-columns: 31% 31% 31%;
  justify-content: space-between;
}

.bybiomarker-main h2 {
  width: fit-content;
  margin: 0 auto;
}

.bybiomarker-main {
  display: flex;
  padding-bottom: 1rem;
}

.bybiomarker-main i {
  color: #4f6367;
}

.recommend-inner-main-custom {
  display: grid;
  grid-template-columns: 26.5% 26.5% 26.5% !important;
  gap: 25px;
  padding-left: 3rem;
}

.recommend-head-btn {
  display: flex;
  align-items: center;
  gap: 2.5rem;
}

.recommend-head-btn i {
  color: #4f6367;
}

.recomendation-steps .recommend-last-btn {
  width: 84.5%;
}

.recommendation-details-main {
  align-items: center;
}

.recommended-head {
  color: var(--Slate);
  font-size: 18px;
  font-weight: 600;
  width: fit-content;
  margin: 0 auto;
  padding-bottom: 1rem;
}

.recommended-details {
  font-size: 14px;
  color: var(--Slate);
}

.recommended-task-specific {
  padding: 20px;
  border-radius: 8px;
  background-color: #b8d9d9;
}

.main-recommended-task {
  display: grid;
  grid-template-columns: 47% 47%;
  gap: 51px;
  width: 75%;
  margin: 0 auto;
}

.recommended-inner-head {
  color: var(--Slate);
  font-size: 18px;
  font-weight: 600;
}

.btn-text-end {
  color: #fff;
  display: flex;
  justify-content: flex-end;
}

.print-btn svg {
  color: white;
  accent-color: white;
}

.cust-dashboard-scroll {
  overflow: initial !important;
}

.outer-three-cond-healthscore {
  padding-top: 1rem;
  padding-left: 2rem;
  padding-bottom: 1rem;
  width: 100%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: initial !important;
  justify-content: start;
  row-gap: 23px;
  font-size: 16px;
  text-wrap: nowrap;
}

.inner-three-cond-healthscore span {
  display: initial !important;
}

.cond-circle-outer-healthscore {
  justify-content: start !important;
}

.healthscore-container {
  display: grid;
  grid-template-columns: 50% 50%;
  align-items: center;
}

.cond-circle-outer-healthscore {
  display: flex;
  align-items: center;
  gap: 4px;
  justify-content: start !important;
}

.vital-stats-outer-heartscore {
  background-color: #fbf7ef !important;
  padding: 0;
}

.healthscore-score {
  color: #139113;
}

.percent-health-score {
  color: initial !important;
}

.back-btn-icon {
  accent-color: var(--Slate) !important;
  color: var(--Slate) !important;
}

.back-btn-icon {
  position: absolute;
  left: -18px;
  top: 5px;
}

.back-btn-icon {
  position: absolute;
  left: -18px;
  top: 3px;
}

/*  */

.recommend-label-btns > span {
  font-size: 11px;
  font-weight: 600;
  align-items: center;
  display: flex;
}

.apexcharts-legend.apexcharts-align-center.apx-legend-position-right {
  display: none;
}

.tooltipvital {
  position: absolute;
  background: white;
  border-radius: 8px;
  font-size: 8px;
  right: 0rem;
  top: -1.1rem;
  padding: 5px 15px;
  width: 140px;
  color: black;
  font-family: "Poppins";
  display: none;
  text-align: center;
  border: 1px solid black;
}

i:hover .tooltipvital {
  display: block;
}

.checkin-btn-new {
  margin-left: initial !important;
  width: 100px !important;
}

.update-btn-new {
  width: 140px !important;
  height: 35px !important;
  font-size: 20px;
  font-weight: 600;
  background-color: #7a9ea0 !important;
  margin: initial !important;
  margin-bottom: 12px !important;
}

.vital-data-cust {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* WEEKLY TIMETABLE */
.weekly-table label {
  font-size: 14px;
}

.flex-center {
  align-items: center;
  display: flex;
}

.weekly-table .form {
  background: #cccccc;
  height: 100vh;
  width: 100%;
}

.weekly-table .form-row {
  background: #ffffff;
  border: 1px solid #d5d5d5;
  border-radius: 50%;
  height: 40px;
  margin: 4px;
  overflow: hidden;
  position: relative;
  width: 40px;
  /* Adjust width as needed */
}

/* .weekly-table .form-input:checked~.form-label {
  font-size: 12px;
} */
.weekly-table .form-input:checked ~ .form-label {
  color: transparent !important;
  user-select: none !important;
  /* Prevent text selection */
}

.weekly-table .form-input {
  -webkit-appearance: none;
  appearance: none;
  position: absolute;
  opacity: 0;
}

.weekly-table .form-input:checked ~ .form-label {
  color: #ffffff;
  background-color: #258e00;
  /* Green background */
}

.weekly-table .form-input:checked ~ .form-label::before {
  content: "";
  display: block;
  position: absolute;
  width: 10px;
  height: 20px;
  border-bottom: 2px solid #ffffff;
  border-right: 2px solid #ffffff;
  transform: rotate(45deg);
  top: 33%;
  left: 58%;
  transform-origin: center;
  /* Set the rotation origin to the center */
  margin-top: -7px;
  /* Adjust position to center vertically */
  margin-left: -8px;
  /* Adjust position to center horizontally */
}

/* .weekly-table .form-input:checked ~ .form-label {
  color: #ffffff;
} */

.weekly-table .form-label {
  color: #414141;
  border-radius: 50%;
  background-color: #ffffff;
  font-size: 10px;
  margin: 0;
  text-align: center;
  line-height: 40px;
  width: 100%;
  z-index: 1;
}

form.form.flex-center.weekly-table {
  justify-content: space-between;
}

.weekly-table .form-input::before {
  content: "";
  cursor: pointer;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.weekly-table .form-input:checked::before {
  background: #21209c;
}

.weekly-table .form-input::after {
  content: "";
  cursor: pointer;
  height: 15px;
  border-radius: 50%;
  border: 4px solid #21209c;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 15px;
}

.weekly-table .form-input:checked::after {
  border: 4px solid #ffffff;
}

/* WEEKLY TIMETABLE */
/* WEEKLY TIMETABLE FOR RECOMMEND */
.weekly-table .form-row-second {
  background: #ffffff;
  border: 1px solid #d5d5d5;
  border-radius: 50%;
  height: 28px !important;

  margin: 4px;
  overflow: hidden;
  position: relative;
  width: 28px;
}

.recom-bio-slider img {
  height: 220px;
  object-fit: center;
}

.recommend-specific-2 {
  height: 388px;
}

.weekly-table .form-label-second {
  line-height: 27px !important;
}

.weekly-table {
  justify-content: space-between;
}

.recom-bio .weekly-4 .form-input:checked ~ .form-label-second {
  height: 38px !important;
}

.weekly-table .form-input:checked ~ .form-label-second::before {
  content: "";
  display: block;
  position: absolute;
  width: 8px !important;
  height: 14px !important;

  border-bottom: 2px solid #ffffff;
  border-right: 2px solid #ffffff;
  transform: rotate(45deg);
  top: 43% !important;

  left: 63% !important;

  transform-origin: center;
  margin-top: -7px;
  margin-left: -8px;
}

.weekly-table .form-input:checked ~ .form-label-second {
  color: #ffffff;
  background-color: #258e00;
  height: 28px !important;
}

/* WEEKLY TIMETABLE FOR RECOMMEND */

.suplements-main-outer {
  position: relative;
  margin-bottom: 3rem;
  height: 380px;
}

.recom-bio > ul {
  height: 55px;
}

.set-reminder-detail {
  display: flex;
  justify-content: space-between;
}

.cross-icon-outer {
  position: absolute;
  background-color: #ffffff;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 0;
  right: -7px;
  top: -7px;
}

.cross-icon-outer i {
  color: #f53546;
}

/* DATE AND CALENDER */
ul.daysList.undefined {
  display: block;
}

ul.daysList.undefined li {
  display: block;
}

.done-button {
  z-index: 9999;
  position: absolute;
  top: 450px;
  display: block;
  /* Ensure the button is displayed */
  visibility: visible;
  /* Ensure the button is visible */
}

.dtpicker-custom {
  position: absolute;
}

/* .dtpicker-custom a.header--clearBtn svg {
  display: none;
}

.dtpicker-custom a.header--clearBtn:before {
  content: "Done";
  font-size: 12px;
  position: absolute;
  top: 24rem;
  left: 15.5rem;
  padding: 4px 8px;
  background-color: #4F6367;
  color: #FFFFFF;
  border-radius: 5px;
}
.dtpicker-custom .input-picker--clearBtn svg{
  display: none;
} */
.dtpicker-custom .react-calendar-datetime-picker .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #4f6367;
  padding: 10px 16px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.dtpicker-custom .react-calendar-datetime-picker .header .header--clearBtn {
  opacity: 1 !important;
}

.dtpicker-custom
  .react-calendar-datetime-picker
  .input-picker
  .input-picker--input {
  cursor: pointer;
  width: 100%;
  text-align: center;
  line-height: 26px;
  height: 26px;
  padding: 0 8px;
  border-radius: 4px;
  border-width: 1px;
  border-color: #aaa;
  border: none !important;
  background: none !important;
  color: #414141 !important;
  font-size: 16px !important;
}

.dtpicker-custom input.input-picker--input.undefined::placeholder {
  color: #414141 !important;
}

.dtpicker-custom
  .react-calendar-datetime-picker
  .input-picker
  .input-picker--input {
  /* width: 50%; */
}

.set-reminder-custom {
  display: grid;
  /* display: flex; */
  grid-template-columns: 48% 52%;
  gap: 10px;
}

.done-btn-calender {
  position: absolute;
  bottom: 1rem;
  z-index: 99999;
  right: 0.5rem;
  font-size: 14px;
  font-family: "Poppins";
  background-color: #4f6367;
  padding: 5px 7px;
  border-radius: 5px;
  color: #ffff;
}

.set-reminder-btn {
  font-size: 16px;
  font-family: "Poppins";
  color: #414141;
}

.dtpicker-custom .react-calendar-datetime-picker .daysList .is-today {
  box-shadow: inset 0 0 0 2px #4f6367;
}

.dtpicker-custom .react-calendar-datetime-picker .daysList .is-selected-day {
  background-color: #4f6367;
  color: #fff;
}

.suplements-main-outer img {
  border-radius: 8px 0 0;
  height: 190px;
  object-position: center;
}

.suplements-main-outer-empty {
  border: 1px solid grey;
  width: 100%;
  height: 98%;
  min-height: 391px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fffdf9;
}

button.done-btn-calender.close-rem {
  right: inherit;
  left: 0.5rem;
  background: red;
}

.suplements-main-outer-empty .fa-2xl {
  font-size: 7em;
  line-height: 0.03125em;
  vertical-align: -0.1875em;
}

/* .dtpicker-custom .react-calendar-datetime-picker .daysList .daysList-day {

  color: #ffff !important;

} */
.more-recommendations {
  font-weight: bold;
  font-size: 12px;
}

.small-circle-bg-color {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.equal-height-cust {
  padding-top: 1vh;
}

.equal-height-cust-2 {
  padding-top: 0.6vh;
}

.equal-height-cust-3 {
  padding-top: 1.8vh;
}
.equal-height-cust-4 {
  padding-top: 1.5vh;
}

.andmoreitem {
  font-weight: 800;
}

.borderbg.overlaybg:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #000000a1;
  z-index: 1;
  border-radius: 15px;
}

.circleprobarbiomarkers.overlaybg:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #000000a1;
  z-index: 1;
  border-radius: 15px;
}

.cust-overview-slider button.slick-arrow.slick-next {
  display: none !important;
}

.borderbg .seemore-heart a {
  color: white;
  text-decoration: underline !important;
  font-size: 13px;
  font-weight: 700;
}

.chart-wrap canvas {
  width: 200px !important;
  height: 200px !important;
}

.vital-chart-main .canvasjs-chart-toolbar {
  display: none !important;
}

.vitals-report-chart canvas.canvasjs-chart-canvas {
  height: 280px !important;
  width: 530px !important;
}

.biomarkerbar {
  background: white;
  border-radius: 10px;
  padding: 8px 5px !important;
  display: flex;
  flex-direction: column;
}

section.admin-side-side.equal-height-cust-3 p {
  font-size: 18px;
}

button.set-reminder-btn {
  font-size: 12px;
  font-weight: 600;
}

.vital-chart-main div#chartContainer {
  height: 271px !important;
  width: 522px;
  margin: 0 auto;
}

.biomarker-listing {
  width: 100%;
}

.fertin .canvasjs-chart-toolbar {
  display: none !important;
}

/* CUSTOMER RECOMMEND */
.weekly-table .form-input:checked ~ .form-label-third::before {
  width: 8px !important;
  height: 19px !important;

  top: 40% !important;
  left: 63% !important;
}

.annual-heading-modal h2 {
  color: #444444 !important;
  text-transform: none;
}

.annual-heading-modal span {
  color: #4f6367;
  font-weight: 700;
}

.account-deleted.annual-heading-modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.monthly-sbs {
  display: flex;

  flex-direction: column;
  align-items: self-start;
}

.monthly-sbs span {
  font-size: 13px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.annual-subs span {
  font-size: 13px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.total-month > span {
  font-weight: 500;

  font-size: 13px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.total-month .total-subs {
  font-size: 12px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}

.annual-planfirst {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 40px;
}

.right-subs-top {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.quest-coupon p {
  font-size: 13px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  text-align: start;
  margin-bottom: 5px;
  color: #4f6367;
}

.quest-coupon input {
  padding: 5px;
  font-size: 13px;
  font-family: "Poppins", sans-serif;

  width: 100%;
  border: 1px solid #4f6367;
  border-right: none;
  border-radius: 5px 0 0 5px;
}

.quest-coupon input:focus-visible {
  outline: none;
}

.quest-coupon input::placeholder {
  font-size: 13px;
}

.inp-coup button {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: #f5f5f5;
  border-radius: 5px;
  font-size: 13px;
  background-color: #4f6367;
  padding: 6px 30px;
  margin-left: -10px;
}

.inp-coup {
  display: flex;
}

.month-plan {
  font-weight: 600 !important;
  color: #4f6367 !important;
}

.act-annual-plan-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.act-annual-plan-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.act-annual-plan-right span,
.act-annual-plan-left span {
  font-family: "Poppins", sans-serif;
  color: #444444;
  font-weight: 500;
}

.act-annual-plan {
  display: flex;
  justify-content: space-between;
  margin: 1rem 0;
  color: #444444;
  font-size: 13px;
  font-family: "Poppins", sans-serif;
}

.subsc-modal .modal-body {
  text-align: center;
  background-color: #fbf7ef;
}

.month-days span {
  font-size: 13px;
  color: #444444;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}

.month-days {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}

.month-charge span {
  font-weight: 600 !important;
  font-size: 13px;
  font-family: "Poppins", sans-serif;

  color: #4f6367;
}

.month-charge {
  display: flex;
  justify-content: space-between;
  margin: 1rem 0;
}

.subs-card span {
  font-weight: 500;
  font-size: 13px;
  color: #444444;
  font-family: "Poppins", sans-serif;
}

.subs-card {
  text-align: start;
  margin-bottom: 1rem;
}

.subs-term-cond {
  text-align: start;
}

.subs-term-cond p {
  font-size: 13px;
  color: #444444;
  font-family: "Poppins", sans-serif;

  font-weight: 400;
}

.subs-term-cond i {
  font-size: 13px;
  color: #00443f;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}

.confirm-suybs button {
  background-color: #4f6367;
  font-size: 13px;
  font-weight: 600;
  color: #f5f5f5;
  width: 100%;
  height: 40px;
  border-radius: 8px;
  font-family: "Poppins", sans-serif;
}

.subsc-modal .modal-dialog.modal-lg.modal-dialog-centered {
  width: 25% !important;
}

.annual-planmodal {
  width: 50%;
  margin: 0 auto;
}

.annual-planmodal i.fa-solid.fa-xmark.closehandlebtndelet {
  position: absolute;
  top: -11px;
  right: -12px;
  font-size: 16px;
  color: white;
  /* color: red; */
  background: red;
}

.coupon {
  color: #4f6367;
  background: white;
  border-radius: 3px;
  margin-left: 2px;
  /* padding-left: 0rem; */
}

.annual-planmodal .modal-body {
  background-color: #fbf7ef;
  border-radius: 8px;
}

.confirm-subs button {
  font-size: 13px;
  background-color: #4f6367;
  width: 100%;
  height: 40px;
  border-radius: 30px;
  color: #f5f5f5;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}

.monthly-rate-modal {
  color: #444444 !important;
}

/* CUSTOMER RECOMMEND */

/* .set-reminder-btn{
  font-size: 13px;
} */

/* ------ */
.ContentAddBlog h4,
.titleAddBlogs h4,
.select-add-blog-biomarker h4 {
  font-size: 13px !important;
  font-weight: 700;
}

.visibility-opts button,
.visibility-opts div button,
.publish-btn {
  font-size: 13px !important;
}

/* ------ */
.monthly-data {
  font-size: 13px !important;
}

/* 0----- */
.box-parent-customer-subs {
  background-color: #b8d9d9;
  border-radius: 10px;
  padding: 1rem;
}

.box-innerparent-customer-subs {
  border-radius: 5px;
  background-color: #ffffff;
  padding: 10px 1rem;
}

.customer-subs-text {
  font-size: 13px;
  color: #444444;
  font-weight: 500;
  width: 35%;
  /* text-align: end; */
}
.cust-sub-text-2 {
  text-align: end;
}
.customer-subs-text-hover-still:hover {
  color: #444444;
}

.customer-subs-text-15 {
  font-size: 15px;
  color: #444444;
  font-weight: 500;
}

.customer-subs-box-head {
  font-size: 15px;
  color: #4f6367;
  font-weight: 500;
}

.cancel-rewal-main-content-parent {
  display: grid;
  grid-template-columns: 50% 50%;
  background-color: var(--Pale-Aqua);
  padding: 1.15rem 1rem;
  border-radius: 10px;
  margin-top: 1rem;
}

.cancel-rewal-left .checkbox-custom::before {
  border-color: #fff;
}

.cancel-rewal-left .checkbox-custom {
  border-color: #4f6367;
}

.cancel-rewal-left .checkbox-input:checked ~ .checkbox-custom {
  background-color: #4f6367;
}

.cancel-renewal-box {
  background: #fff;
  padding: 1rem;
  border-radius: 5px;
}

.cancel-rewal-right-textarea {
  border: 1px solid #4f6367;
  border-radius: 6px;
  font-size: 13px;
  color: #4f6367;
  height: 160px;
  padding: 0.6rem 1rem;
  outline: none;
}

.cancel-rewal-right {
  width: 85%;
  margin: 0 auto;
}

/* 0----- */

/* Modify Billing Information Modal */
.input-form-main {
  width: 92%;
  margin: 0px auto;
}

.billing-info-head {
  font-size: 20px;
  font-weight: 500;
  color: #4f6367;
  text-align: center;
  font-family: "Poppins", sans-serif;
  padding-bottom: 15px;
  border-bottom: 1px solid #00443f;
}

.input-field-cutsomer-modal {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
}

.input-field-label {
  text-align: start;
  font-size: 13px;
  font-weight: 500;
  color: #4f6367;
  font-family: "Poppins", sans-serif;
}

.modal-cutomer-input {
  border-radius: 6px;
  outline: none;
  border: 1px solid #4f6367;
  padding: 5px;
  font-size: 13px;
  font-family: "Poppins", sans-serif;
}

.save-changes-btn {
  font-size: 13px;
  font-weight: 600;
  color: white;
  background-color: #4f6367;
  border-radius: 3px;
  padding: 5px 10px;
  margin-top: 15px;
}

.modify-billing-info-modal-main {
  max-width: 62% !important;
}

/* Modify Billing Information Modal */

/* Invoice Modal */
.customer-Subcribtion-modl2 h2 {
  color: #4f6367;
  font-size: 20px;
  text-transform: capitalize;
  border-bottom: 2px solid #00443f;
  padding-bottom: 20px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.customer-subcribtion-modal2 {
  padding: 20px 0;
  background-color: #fbf7ef;
}

.customer-subcribtion-inner-modl2 {
  padding: 10px 30px;
}

.customer-subcribtion-content-modl2 ul {
  display: grid;
  grid-template-columns: 20% 20% 20% 20% 20%;
  padding-bottom: 15px;
  padding-top: 5px;
  border-bottom: 2px solid #00443f;
}

.customer-subcribtion-content-modl2 ul li {
  color: #4f6367;
  font-size: 13px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.customer-subcribtion-content-modl2-body ul {
  display: grid;
  grid-template-columns: 20% 20% 20% 20% 20%;
  padding-bottom: 10px;
  border-bottom: 1px solid #00443f;
  padding: 15px 0;
  align-items: center;
  justify-content: center;
}

.customer-subcribtion-content-modl2-body ul li {
  color: #4f6367;
  font-size: 13px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

/*Invoice Modal */

/*0--- */
.content-subs-two {
  width: 48%;
}

.content-subs-two-box-shadow {
  box-shadow: 0 0 5px #00000054;
}

.content-subs-two-main-radius {
  border-radius: 10px;
}

.left-of-subs-two-grid-text {
  font-size: 13px;
  color: #4f6367;
  font-weight: 400;
}

.left-of-subs-two-grid-header {
  width: 52%;
  margin-left: auto;
  display: grid;
  grid-template-columns: 50% 50%;
  text-align: center;
  padding: 0.6rem;
}

.left-of-subs-grid-body {
  display: grid;
  grid-template-columns: 50% 25% 25%;
}

.TickcrossMark {
  width: 16px;
  height: 16px;
  object-fit: contain;
}

.left-of-subs-grid-body-parent {
  border-top: 1px solid #cbcbcb;
  gap: 0.65rem;
  padding: 0.5rem 1rem;
}

.right-of-subs-two-box-txt-one {
  font-size: 15px;
  font-weight: 500;
  color: #444444;
}

.right-of-subs-two-box-txt-two {
  font-size: 20px;
  font-weight: 600;
  color: #444444;
}

.right-of-subs-two-box-txt-three {
  font-size: 13px;
  font-weight: 400;
  color: #444444;
}

.right-of-subs-two-box-txt-four {
  font-size: 13px;
  font-weight: 500;
  color: #444444;
}

.right-of-subs-two-box {
  padding: 1.2rem 1rem;
}

.customer-substwo-btn {
  background-color: var(--Slate);
  color: #fff;
  font-size: 13px;
  border-radius: 6px;
  padding: 0.45rem 2rem;
  font-weight: 600;
}

.customer-substwo-btn:hover {
  color: #fff;
}

.content-customer-subs-two-main-parent {
  padding: 1rem 0;
}

/*0--- */
/* //////////////// update payment detail modal//////////////// */
.customer-payment-detail-modl4 {
  padding: 20px 0;
}

.customer-payment-detail-modl4 h3 {
  color: #4f6367;
  font-size: 13px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  padding-bottom: 5px;
}

.customer-form-detail-modl4 {
  position: relative;
}

.customer-form-detail-modl4 input {
  padding: 9px 140px 9px 40px;
  font-size: 13px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  border: 1px solid #b8d9d9;
  outline: none;
  border-radius: 5px;
  width: 66%;
}

.customer-form-detail-modl4-img1 {
  position: absolute;
  top: 9px;
  width: 5%;
  left: 19%;
}

.customer-form-detail-modl4-img2 {
  position: absolute;
  top: 13px;
  left: 58%;
}

.customer-form-detail-modl4-img3 {
  position: absolute;
  top: 8px;
  left: 67%;
}

.customer-form-detail-modl4-img4 {
  position: absolute;
  top: 8px;
  left: 75%;
}

.customer-strip-btn-modl4 a {
  width: 66%;
  border: 1px solid #b8d9d9;
  border-radius: 5px;
  padding: 6px 129px;
  background-color: #ffffff;
}

.customer-strip-btn-modl4 {
  margin-top: 20px;
}

.customer-pay-withstripe {
  width: 80%;
  margin: 0 auto;
  text-align: initial;
}

.customer-pay-withstripe-inner span {
  color: #4f6367;
  font-size: 13px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.customer-pay-withstripe-inner {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin: 15px 0 20px 0;
}

.customer-pay-withstripe-inner i.fa-solid.fa-arrow-left {
  color: #4f6367;
  font-size: 13px;
}

.customer-pay-withstripe p {
  text-align: center;
  color: #4f6367;
  font-size: 13px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  width: 90%;
  margin: 0 auto;
}

.continue-tostrip {
  text-align: center;
  margin: 25px 0;
}

.continue-tostrip a {
  background-color: #4f6367;
  color: #ffff;
  font-size: 13px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  padding: 7px 30px;
  border-radius: 5px;
}

.customer-card-subcribtion {
  display: flex;
  gap: 1rem;
  position: relative;
}

.customer-card-subcribtion-inp1 {
  width: 70%;
  border: 1px solid #4f6367;
  font-family: "Poppins", sans-serif;
  padding: 5px 0 2px 40px;
  outline: none;
  font-size: 13px;
  border-radius: 5px;
}

.customer-card-subcribtion-inp2 {
  width: 15%;
  border: 1px solid #4f6367;
  font-family: "Poppins", sans-serif;
  padding: 5px;
  outline: none;
  font-size: 13px;
  border-radius: 5px;
}

.customer-card-subcribtion-inp3 {
  width: 15%;
  border: 1px solid #4f6367;
  font-family: "Poppins", sans-serif;
  font-size: 13px;
  padding: 5px;
  outline: none;
  border-radius: 5px;
}

.customer-card-subcribtion-debitcard {
  position: absolute;
  width: 5%;
  top: 8px;
  left: 2%;
}

.recenttestcustomer.recent-two {
  margin-top: 13px;
}

.recenttestcustomer.sp-added {
  margin-top: 15px;
}
section.admin-side-side.report-custom.custom-section-gap.cust-gap-2 {
  margin-top: 7rem !important;
}
/* //////////////// update payment detail modal//////////////// */

.greyColorBox {
  filter: grayscale(100%);
  opacity: 0.4;
}

.checkup-height {
  padding-top: 1.5vh;
}
.check-up-detail-outer {
  background-color: #b8d9d9;
  border-radius: 10px;
  padding: 1rem 2rem;
  margin: 1rem 0;
}
.check-up-detail-outer-accordiun {
  width: 100%;
  background-color: #b8d9d9;
  border-radius: 10px;
  padding: 2rem 2rem;
  margin: 0 0 1rem 0;
}
.checkups-accordiun-list-head {
  font-size: 15px;
  color: #4f6367;
  margin-top: 2rem;
}
.checkup-specific {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 1rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 12px 0 0 0;
  cursor: pointer;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 3px 5px 0px;
}
.checkup-specific i.fa-solid.fa-chevron-down,
.checkup-specific i.fa-solid.fa-chevron-up {
  font-size: 18px;
  color: #4f6367;
  font-weight: 800;
}
.checkup-specific-inner-accor {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 1rem 1rem 0 1rem;
  width: 100%;
  margin: 4px 0 12px 0;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.checkup-specific-inner-accor h4 {
  color: #393939;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}
.checkup-specific h2 {
  font-size: 20px;
  color: #4f6367;
  margin-bottom: 0;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  text-transform: capitalize;
}
.checkup-specific p {
  font-size: 13px;
  color: #4f6367;
  margin: 0 0 5px !important;
}
.check-up-accordiun-main {
  margin-bottom: 1rem;
}
.This-checkup-content {
  /* display: flex; */
  display: grid;
  grid-template-columns: 4% 95.5%;
  gap: 1rem;
  margin: 0.3rem 0;
  align-items: baseline;
}
.This-checkup-content h3 {
  color: #393939;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}
.This-checkup-content p {
  color: #393939;
  font-size: 13px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}
.check-up-detail-inner {
  overflow-y: auto;
  max-height: 710px;
}

.check-up-detail-inner::-webkit-scrollbar {
  width: 0.5em;
  height: 0.5em;
}

.check-up-detail-inner::-webkit-scrollbar-button {
  height: 8px;
}
.check-up-detail-inner::-webkit-scrollbar-corner {
  border: 1px solid #ffffff;
}

.check-up-detail-inner::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
  border-radius: 4px;
  background-color: #ffffff;
}

.check-up-detail-inner::-webkit-scrollbar-thumb {
  background-color: #4f6367;
  border-radius: 4px;
  border: 1px solid #ffffff;
}
.perosonalized-btn .biomarkeraddbtn {
  background-color: #7a9ea0 !important;
  width: 180px !important;
  font-weight: 600;
}
.perosonalized-btn {
  display: flex;
  justify-content: center;
}
.personalize-checkup-head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.personalizer-inner-head {
  font-size: 20px;
}
.personalizer-detail-inner {
  margin-top: 20px;
}
.personalize-specific {
  font-size: 18px;
  color: var(--Slate);
  background-color: #ffffff;
  padding: 5px 10px;
  border-radius: 8px;
  margin-bottom: 12px;
  box-shadow: 10px 10px 5px -7px rgba(0, 0, 0, 0.26);
  -webkit-box-shadow: 10px 10px 5px -7px rgba(0, 0, 0, 0.26);
  -moz-box-shadow: 10px 10px 5px -7px rgba(0, 0, 0, 0.26);
}
.personalize-detail-outer {
  width: 50%;
}
.personalize-checkup-head {
  width: 50%;
}
.test-show .responsve-alltest-box ul {
  grid-template-columns: 25% 25% 25% 25%;
}

.test-show .customer-all-test-result {
  grid-template-columns: 40% 60%;
}
.test-report-render {
  display: grid;
  grid-template-columns: 50% 50%;
  align-items: center;
}
.order-list-2 {
  border: none !important;
  padding: 0;
}
.checkup-specific-inner-accor h3 {
  font-size: 14px;
  color: #393939;
  font-weight: 600;
}
.health-profile-markers span {
  font-size: 20px !important;
}
.health-profile-markers {
  padding-bottom: 1rem;
}
/* .customer-dashboard {
  overflow: none !important;
} */
