.light-blue {
  color: #2e96d7;
}

.light-Gray {
  color: #797f82;
}

p {
  padding: 0;
}
.health-icon-list {
  height: 35px;
}
/* Practitioner Dashboard front page start */
.font-poppins-class {
  font-family: "Poppins", sans-serif;
}

.namecart h4 {
  font-size: 25px;
  font-weight: 300;
}
span.arrow-back.practitioner {
  left: 0rem;
}
.addproduct-tabs li.nav-item .nav-link.active img {
  filter: grayscale(4) invert(2) brightness(2.5);
}
.testtbicon {
  filter: grayscale(1);
}
.namecart h4 {
  font-size: 25px;
}

.namecart p i {
  color: red;
}
.test-box-include .ordertablebody3 ul:last-child {
  margin-bottom: 0;
}
.pract-noti-inner h6 {
  font-size: 16px;
}

.pract-noti-inner p {
  font-size: 13px;
}

.pract-noti-inner span {
  font-size: 13px;
}

.namecart p span {
  color: red;
  border: 1px solid;
  border-radius: 40px;
  padding: 2px 11px;
  font-weight: bold;
  font-size: 24px;
  margin-left: 40px;
}
.pract-Notes p {
  font-size: 13px;
}
.namecart {
  display: grid;
  gap: 2%;
  grid-template-columns: 54% 44%;
  align-items: center;
  background: var(--Admin-gradiant);
  border-radius: 10px;
  padding: 2rem 1.8rem;
}
.notification-handle {
  background-color: #4f6367;
  padding: 5px;
  border-radius: 50%;
}
img.notification-img {
  width: 65px;
}

.test-result-practitioner-list {
  background: var(--Pale-Aqua);
  border-radius: 10px;
  padding: 1rem 2rem;
  color: #434343;
}

.test-result-practitioner-list span h2 {
  font-size: 13px;
  font-weight: 500;
}
.mob-overtableboy-client-2 {
  /* height: 51vh; */
  max-height: 51vh;
  overflow-y: auto;
}

.testBtn {
  font-size: 20px;
  font-weight: 600;
  color: white;
  background: #2e96d7;
  padding: 10px 30px;
  border-radius: 40px;
  width: 200px;
  display: block;
  margin: 0 auto;
  margin-top: 2rem;
}

.cart-inner-name {
  background: white;
  border-radius: 50px;
  max-width: 370px;
  padding: 1rem 9rem 1rem 1rem;
  position: relative;
}

.cut-cart {
  background: red;
  padding: 7px 10px;
  border-radius: 30px;
  border: 3px solid white;
  position: absolute;
  right: -15px;
}

.cut-cart i {
  display: flex;
  color: white;
  font-size: 22px;
  align-items: center;
  justify-content: center;
}

.cartTotalAmount p {
  font-size: 20px;
  color: white;
}

.cartTotalAmount p span {
  font-weight: 600;
}

.pract-cart-list {
  background-image: url(../images//bgcartinner.png);
  background-repeat: no-repeat;
  background-position: 100%, 100%;
  background-size: 100% 100%;
  padding: 2rem 2rem;
}

.practitionerHomepage {
  display: grid;
  grid-template-columns: 70% 27%;
  grid-column-gap: 2rem;
  margin-top: 1rem !important;
}

.testbtnbox {
  background: #2e96d7;
  padding: 2rem 2rem;
  border-radius: 20px;
}

.testbtn2 {
  font-size: 11px;
  font-weight: 600;
  color: #2e96d7;
  background: #ffffff;
  padding: 18px 30px;
  border-radius: 40px;
  display: flex;
  margin: 0 auto;
  text-align: center;
  gap: 10px;
  justify-content: center;
}

.request-form button {
  font-size: 20px;
  font-weight: 600;
  color: white;
  background: #2e96d7;
  padding: 10px 30px;
  border-radius: 30px;
}

.request-form a {
  font-size: 20px;
  font-weight: 600;
  color: white;
  background: #2e96d7;
  padding: 10px 30px;
  border-radius: 30px;
}

.request-form {
  text-align: end;
  position: absolute;
  right: -5rem;
  background: #ffffff;
  border: 2px solid #2e96d7;
  border-radius: 16px;
  padding: 30px 20px;
  width: calc(86vw - 61vw);
  margin-top: 19px;
  z-index: 1;
}

.request-form .css-xn6ruc > :not(style) {
  width: 100%;
}

.req-icon {
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
  color: #2e96d7;
  font-size: 39px;
  z-index: -1;
}

.semibold {
  font-weight: 600;
  font-size: 20px;
}

.request-form .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-color: #409fd5;
}

.frequent-box .testbtn2 {
  padding: 10px 20px;
  border-radius: 0;
}

.pract-noti-inner {
  background: #faf9f76b;
  border-radius: 20px;
  padding: 5px 10px;
  gap: 17px;
  position: relative;
  width: 97%;
  justify-content: space-between;
}

.pract-noti-inner h6 {
  font-size: 20px;
  color: #3d2c3e;
  font-weight: 600;
}

.onscrolling {
  overflow-y: scroll;
  max-height: 640px;
}

.onscrolling::-webkit-scrollbar {
  width: 1em;
  border-radius: 20px;
}

.onscrolling::-webkit-scrollbar-track {
  background-color: #fff;
  border-radius: 20px;
}

.onscrolling::-webkit-scrollbar-thumb {
  background-color: var(--Slate);
  border: 2px solid rgb(255, 255, 255);
  border-radius: 8px;
}

.practitioner-notifications h2 {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  color: #434343;
}

.pract-sett-tabs li.nav-item .nav-link.active span img {
  filter: grayscale(3) invert(2) brightness(3.5);
}

.tabprofile .test-field label {
  color: #797f82;
}

.tabprofile .test-btn button {
  border-radius: 50px;
  font-weight: 600;
}

.pract-sett-tabs .detail-page-test {
  width: 80%;
}

/* Practitioner Dashboard front page End */

/* toggle button  */
.detail-page-test .switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 28px;
}

.detail-page-test .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.detail-page-test .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.detail-page-test .slider:before {
  position: absolute;
  content: "";
  height: 21px;
  width: 21px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
}

.detail-page-test input:checked + .slider {
  background-color: var(--Slate);
}

.detail-page-test input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

.detail-page-test input:checked + .slider:before {
  -webkit-transform: translateX(32px);
  -ms-transform: translateX(32px);
  transform: translateX(32px);
}

/* Rounded sliders */
.detail-page-test .slider.round {
  border-radius: 34px;
}

.detail-page-test .slider.round:before {
  border-radius: 50%;
}

.swithtoggle p {
  color: #1282cd;
  font-weight: 500;
  font-size: 20px;
}

/* deleted modal in practitioner */

.account-deleted h2 {
  color: #087bca;
  font-size: 20px;
  font-weight: 600;
  text-align: left;
}

.account-deleted p {
  color: #087bca;
  font-size: 16px;
  font-weight: 400;
  margin: 10px 0;
  text-align: left;
}

.account-deleted img {
  margin-bottom: 20px;
}

.deletedmodalpractit .modal-content {
  background: #dcf1ff;
  padding: 20px 20px;
  border-radius: 20px;
}

.deletedmodalpractit .formgroup input {
  width: 100%;
  border-radius: 11px;
  border: 1px solid #087bca;
  height: 48px;
  padding: 10px 10px;
}

.deletedmodalpractit .formgroup {
  text-align: left;
  display: flex;
  flex-direction: column;
}

.deletedmodalpractit .formgroup label {
  color: #087bca;
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 6px;
}

.btndele {
  background: white;
  padding: 10px 30px;
  border-radius: 50px;
  font-size: 13px;
  font-weight: 500;
  color: #087bca;
  border: 1px solid #087bca;
  margin-right: 20px;
  width: 130px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.closehandlebtndelet {
  position: absolute;
  right: 7px;
  border-radius: 20px;
  color: #087bca;
  border: 1px solid #087bca;
  padding: 4px 7px;
  top: 9px;
  font-size: 27px;
  cursor: pointer;
  z-index: 99;
}

.my-file-upload p a {
  color: white;
  font-size: 13px;
  margin-top: 10px;
}

.my-file-upload p .active {
  background: white;
  padding: 2px 21px;
  border-radius: 10px;
}

.my-file-upload p .active a {
  color: #2e96d7 !important;
}

/* edit profile page  */
.accr-editpro button.accordion-button {
  background: #087bca;
  color: white;
}

.accr-editpro .accordion-button::after {
  filter: grayscale(3) brightness(10.5);
}

.detail-page-test .test-field input::placeholder {
  color: #c7c7c7;
}

.backtohome .modal-body {
  background: #1282cd;
  border-radius: 18px 18px 0 0px;
}

.backtohome .modal-content {
  border-radius: 20px !important;
}

.backtodashboard {
  padding: 11px 49px;
  display: flex;
  background: #087bca;
  color: white;
  font-size: 16px;
  font-weight: 500;
  border-radius: 27px;
  justify-content: center;
  margin: 0 auto;
  gap: 10px;
  align-items: center;
  width: 40%;
}

.backtodashboard i {
  font-size: 30px;
}

/* all test page */

.customertable {
  background-color: #2e96d7;
  border-radius: 7px;
  padding-left: 20px;
  padding-top: 20px;
  padding-bottom: 1px;
}

.customertable tbody tr td {
  background: initial;
}

.customertable thead th,
tbody td {
  color: white !important;
  font-weight: 400;
  font-size: 14px;
}

.customertable h6 {
  color: white;
  font-size: 20px;
  font-weight: 500;
}

.tbicons {
  background: white;
  display: initial;
  padding: 3px 10px;
  border-radius: 4px;
}

.tbicons i {
  color: #2e96d7;
}

.customertable .table > tbody {
  border: #2e96d7;
}

.pract-Notes textarea {
  width: 100%;
  border-radius: 10px;
  padding: 10px 10px;
  height: 280px;
}

.Pract-dashboards .search-product input {
  width: 380px;
  background: #f2f2f2;
  outline: none;
}

.search-product {
  display: flex;
  justify-content: end;
  align-items: center;
}

.All-Time1 {
  padding: 11px;
  border-radius: 5px;
  outline: none;
  border: 1px solid #f2f2f2;
  background-color: #f2f2f2;
  width: 200px;
  margin-right: 50px;
}

.customertable.customer-pract {
  padding-bottom: 11px;
}

.customertable.customer-pract thead th {
  padding-bottom: 1rem;
}

.custnamebtn {
  background: white;
  padding: 7px 21px !important;
  border-radius: 30px;
  color: #2e96d7 !important;
  font-weight: 500;
}

.desire-abnormal span {
  background: red;
  color: white;
  padding: 7px 12px;
  border-radius: 30px;
  margin-right: 8px;
}

.namecart h1 {
  font-size: 25px;
}

.desire-abnormal div:first-child {
  margin-right: 25px;
}

.notif a.back-trans.border-btn span {
  background: var(--dark-Pale-Aqua);
  border: 1px solid var(--dark-Pale-Aqua);
  color: white;
}

.customers-tabs .namecart p {
  font-size: 40px;
  font-weight: 300 !important;
}

.titlehealth h1 {
  font-size: 50px;
}

.basicinfobox h4 {
  color: var(--Slate);
  font-size: 30px;
  text-align: center;
  font-weight: 500;
}

.basicinfobox {
  background: white;
  border: 1px solid var(--dark-Pale-Aqua);
  padding: 15px 10px;
  border-radius: 20px;
}

.custominformations {
  background: var(--Pale-Aqua);
  padding: 40px 20px;
  border-radius: 20px;
}

.para-in input {
  width: 140px;
  border: 1px solid var(--dark-Pale-Aqua);
  border-radius: 6px;
  text-align: center;
  height: 45px;
  background: white;
  padding: 20px 30px;
}

.para-in input::placeholder {
  color: var(--dark-Pale-Aqua);
  font-size: 18px;
}

.para-in p {
  font-size: 20px;
  font-weight: 500;
  color: var(--Slate);
}

.progress-done {
  width: 80%;
  background: var(--dark-Pale-Aqua);
  border-radius: 20px;
}

.progress-data {
  width: 100%;
  height: 16px;
  background: var(--Pale-Aqua);
  border-radius: 50px;
  display: flex;
}

.progressbar h1 {
  color: var(--Slate);
  font-size: 31px;
  font-weight: 500;
  margin-bottom: 16px;
}

.customers-tabs .addproduct-tabs li.nav-item .nav-link.active span img {
  filter: brightness(0.5) invert(1) grayscale(2);
}

.sel-search {
  display: flex;
}

.sel-search .showentries select {
  padding: 14px 10px;
  background: #ebe8e8;
  border: none;
  margin-right: 50px;
  width: 180px;
}

.sel-search .search-product input {
  width: 300px;
  background: #ebe8e8;
}

.vital-inner ul {
  display: grid;
  align-items: center;
  grid-template-columns: 44% 23% 33%;
  background: #7a9ea0;
  border-radius: 10px;
  padding: 20px 20px;
  transition: all 0.5s ease-in-out;
}

.vital-inner ul li span {
  color: white;
  font-size: 20px;
  display: flex;
  transition: all 0.5s ease-in-out;
  gap: 19px;
  align-items: center;
}

.vital-inner ul li:last-child span {
  text-align: end;
  display: block;
}

.vital-inner {
  background: var(--Antique-White);
  padding: 20px 20px;
  border-radius: 11px;
  border: 1px solid var(--Slate);
}

.vitals-data h2 {
  color: var(--Slate);
  font-size: 25px;
  font-weight: 600;
}

/*  */
.heartscore span {
  font-size: 180px;
  /* transform: translate(-50%, -50%); */
  background-image: url(https://htmlcolorcodes.com/assets/images/colors/red-color-solid-background-1920x1080.png);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-stroke-width: 6px;
  -webkit-text-stroke-color: #4f6367;
  background-size: 9%;
  /* animation: mymove 10s infinite; */
  /* -webkit-box-reflect: below -30px linear-gradient(transparent, rgba(0, 0, 0, 0.4)); */
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: bottom;
  background-repeat: no-repeat;
  width: 100%;
}

@keyframes mymove {
  50% {
    background-position-x: 100%;
  }
}

.heartscore .link-4 {
  position: absolute;
  top: 90%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 250px;
  text-align: center;
  color: white;
  font-weight: 999;
  font-size: 4vw;
  background: #ff2442;
  height: 35px;
  font-size: 20px;
  padding-top: 5px;
  border-radius: 5px;
  margin-bottom: 5vw;
  text-decoration: none;
}

.heartscore .link-4:hover {
  color: black;
}
.borderbg .seemore-heart {
  z-index: 5;
  position: relative;
  position: absolute;
  bottom: 5px;
  left: 50%;
  transform: translateX(-50%);
}
.borderbg {
  border: 0px solid #4f6367;
  border-radius: 15px;
  background: #b8d9d9;
  position: relative;
  height: 100%;
}

.borderbg h2 {
  color: white;
  font-size: 19px;
  text-align: center;
}

span.probar {
  background: red;
  display: block;
  height: 6px;
  border-radius: 10px;
}

/*  */

.circleprobarbiomarkers .meter {
  background-color: transparent;
  padding: 10px 20px;
  border-radius: 10px;
  position: relative;
  display: inline-block;
  text-align: center;
  position: relative;
}

.circleprobarbiomarkers #needle {
  position: absolute;
  left: 44%;
  top: 11%;
  transform-origin: 50% calc(100% - 15px);
  transform: rotate(85deg);
  /* animation: rotate 5s ease-in-out alternate infinite; */
}

@keyframes rotate {
  to {
    transform: rotate(110deg);
  }
}

.circleprobarbiomarkers svg {
  margin: auto;
  max-width: 100%;
}

.polygon-shap-score {
  width: 100px;
  height: 24px;
  background-color: #ff7200;
  clip-path: polygon(100% 0%, 50% 50%, 100% 100%);
  transform: translate(12px, -109px) rotate(300deg);
  transform-origin: right;
}

.circleprobarbiomarkers {
  text-align: center;
  padding-bottom: 20px;
}

.meter img {
  width: 230px;
  height: inherit;
}

.circleprobarbiomarkers .meter h3 {
  display: block;
  text-align: center;
  margin-top: -150px;
  font-size: 21px;
  position: relative;
  top: -13px;
  font-weight: 600;
  color: #2e96d7;
}

.borderbg .meter .meter p {
  background: #4f6367;
  width: 100px;
  margin: 0 auto;
  border-radius: 9px;
  font-size: 14px;
  color: white;
  font-weight: 600;
  height: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: -20px;
}

.meter h4 span {
  font-size: 33px;
}

.meter h4 {
  font-size: 13px;
  position: relative;
  top: -27px;
  color: #4f6367;
  text-align: center;
  position: absolute;
  top: 83%;
  left: 50%;
  font-weight: 600;
  transform: translate(-50%, -50%);
}

.biomarkerbar p {
  color: #4f6367;
  font-size: 13px;
  padding: 1px 4px;
}

.heartrating {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 30px;
}

.colordot {
  width: 15px;
  height: 15px;
  background: #46b710;
  border-radius: 30px;
  position: absolute;
  right: -14px;
  top: -20px;
}
.vital-inner ul {
  position: relative;
}
.vital-inner ul li {
  position: relative;
}
li.addtofav-left {
  position: absolute !important;
  left: 0;
  top: 60%;
}
/* Product order */
section.productOrdersec {
  padding-top: 120px;
}

.title-po .define-pathlogy-heading h3 {
  transform: translateY(0px);
  line-height: 31px;
}

.title-po .define-pathlogy-heading h3 a {
  border-bottom: 3px solid #1080cc;
  color: inherit;
}

.orderpaybtn1 {
  padding: 10px 50px;
  border-radius: 8px;
  font-size: 20px;
  font-weight: 500;
  color: #ffff;
  font-family: "Poppins";
  border: 1px solid #087bca;
  background: #087bca;
}

.btn-text h5 {
  color: #087bca;
  font-size: 20px;
}

.btn-text h5 i {
  background: #087bca;
  padding: 2px 7px;
  border-radius: 10px;
  color: white;
  margin-right: 4rem;
}

.patientform .order-btn a {
  border-radius: 5px;
}

.patientform {
  background: #f5fafd;
  padding: 30px 30px;
  border-radius: 10px;
}

.yourorder .cartbox {
  position: sticky;
  z-index: 0;
}

.yourorder {
  background: #087bca;
  border-radius: 40px;
  padding: 50px 109px;
  position: relative;
  /* height: 408px; */
  display: flex;
  flex-direction: column;
  align-items: end;
}

.returndash h4 {
  font-size: 22px;
  color: #087bca;
  font-weight: 600;
}

.returndash i {
  font-size: 110px;
  color: #087bca;
}

.yourorder h2 {
  font-size: 42px;
  color: white;
  font-weight: 500;
  text-align: end;
}

.Pract-dashboards .admin-logout {
  position: absolute !important;
  bottom: 20px;
}

/* practitioner-new-styles */
.sidebarpractitioner {
  height: 95vh;
  position: fixed;
  top: 1.5rem;
}

.sidebaradmin.sidebarpractitioner h4 {
  font-size: 40px;
  color: white;
  font-weight: 600;
}

.practitioner-dash-top-search-parent input {
  border-radius: 0.625rem;
  border: none;
  outline: none;
  color: var(--dark-Pale-Aqua);
}

.practitioner-dash-top-search-parent input::placeholder {
  color: var(--dark-Pale-Aqua);
  font-size: 13px;
}

.practitioner-dash-top-search-parent span {
  top: 50%;
  transform: translateY(-50%);
  left: 1rem;
  color: var(--dark-Pale-Aqua);
}

.resultcartside {
  background-color: var(--Antique-White);
  border-radius: 0.9rem;
  overflow-y: auto;
}

.resultcartside h4 {
  color: var(--Slate);
}

.test-result-practitioner-list p {
  margin-bottom: 0;
  font-size: 13px !important;
}

.bg-gradient-aqua {
  background: var(--Admin-gradiant);
}

.radius-10 {
  border-radius: 0.625rem;
}

.right-practitioner-heading-one {
  font-size: 1rem;
}

.right-practitioner-heading-two {
  font-size: 2.9rem;
}

.practitioner-dash-main-right-inner {
  display: grid;
  gap: 2%;
  grid-template-columns: 28% 70%;
  align-items: center;
}

.practitioner-dash-main-notification {
  background-color: var(--Antique-White);
  border-radius: 1.2rem;
}

.pract-Notifications-heading {
  color: var(--Slate);
  font-size: 1.2rem;
}

.notification-box-heading {
  font-size: 0.75rem;
  /* display: -webkit-box; */
  -webkit-box-orient: vertical;
  /* overflow: hidden; */
  -webkit-line-clamp: 1;
}

.notification-box-para {
  font-size: 0.5rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 3;
}

.notification-box-sm {
  font-size: 0.438rem;
}

.notifications-box-text-content,
.notifications-box {
  gap: 0.3rem;
}

.notifications-box {
  border-radius: 0.625rem;
  width: 98%;
}

.notification-dot {
  width: 0.438rem;
  height: 0.438rem;
  background-color: var(--Slate);
}

.serum-steve-order {
  background-color: var(--Antique-White);
  margin: 20px 0;
  padding: 20px 30px;
  border-radius: 10px;
}

.AddClientbtn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.AddClientbtn a {
  border: 1px solid var(--Slate);
  padding: 10px 20px;
  border-radius: 10px;
  background-color: var(--Slate);
  color: #ffffff;
}

.AddClientbtn h1 {
  margin-bottom: 20px;
  color: var(--dark-Pale-Aqua);
  font-size: 50px;
}

.ordertablerow ul {
  display: grid;
  align-items: center;
  grid-template-columns: 20% 20% 20% 20% 20%;
  text-align: center;
  list-style: none;
  margin: 0 0 10px 0;
  background-color: var(--Slate);
  padding: 10px 0 10px 0;
  border-radius: 50px;
  font-size: 20px;
}

.ordertablerow ul li {
  color: var(--white);
  font-size: 13px;
}

.ordertablebody ul {
  display: grid;
  align-items: center;
  grid-template-columns: 20% 20% 20% 20% 20%;
  text-align: center;
  list-style: none;
  padding: 5px 0 10px 0;
  margin: 0 0 10px 0;
  background-color: var(--Pale-Aqua);
  padding: 8px 0 10px 0;
  border-radius: 50px;
}

.ordertablerow2 ul {
  display: grid;
  align-items: center;
  grid-template-columns: 20% 20% 20% 30% 10%;
  text-align: center;
  list-style: none;
  margin: 0 0 10px 0;
  background-color: var(--Slate);
  padding: 10px 0 10px 0;
  border-radius: 50px;
}

.ordertablerow2 ul li {
  color: var(--white);
}

.ordertablebody2 ul {
  display: grid;
  align-items: center;
  grid-template-columns: 20% 20% 20% 30% 10%;
  text-align: center;
  list-style: none;
  padding: 5px 0 10px 0;
  margin: 0 0 10px 0;
  background-color: var(--Pale-Aqua);
  padding: 8px 0 10px 0;
  border-radius: 30px;
}

.ordertablebody2LiSed {
  text-align: left;
}

.ordertablebody3 ul {
  display: grid;
  align-items: center;
  grid-template-columns: 12% 14% 17% 23% 17% 17%;
  text-align: center;
  list-style: none;
  padding: 5px 0 10px 0;
  margin: 0 0 10px 0;
  background-color: var(--Pale-Aqua);
  padding: 8px 0 10px 10px;
  border-radius: 30px;
}

.ordertablerow3 ul {
  display: grid;
  align-items: center;
  grid-template-columns: 12% 14% 17% 23% 17% 17%;
  text-align: center;
  list-style: none;
  margin: 0 0 10px 0;
  background-color: var(--Slate);
  padding: 5px 0 5px 0;
  border-radius: 50px;
}

.fiveColumn ul {
  grid-template-columns: 20% 15% 30% 20% 15% !important;
}

.ordertablerow3 ul li {
  color: var(--white);
  font-size: 13px;
}

.ordertablebody3 ul li {
  font-size: 13px;
  color: var(--Slate);
}

.Overview {
  margin: 0 30px;
  border: 1px solid #4f6367;
  padding: 8px 25px;
  border-radius: 10px;
  color: var(--Slate);
}

.Overview:hover {
  background-color: var(--Slate);
  color: #ffffff;
}

.Health-Profile {
  border: 1px solid #4f6367;
  padding: 8px 25px;
  border-radius: 10px;
  color: var(--Slate);
}

.Health-Profile:hover {
  background-color: var(--Slate);
  color: #ffffff;
}

.All-Tests {
  border: 1px solid #4f6367;
  padding: 8px 25px;
  border-radius: 10px;
  color: var(--Slate);
}

.All-Tests:hover {
  background-color: var(--Slate);
  color: #ffffff;
}

.Biomarker {
  margin: 50px 0 20px 0;
  color: var(--Slate);
}

.From-Jun {
  text-align: center;
  margin: 10px 0 10px 200px;
}

.Need-improvement {
  width: 100%;
  height: 180px;
  background-color: var(--Pale-Aqua);
  border-radius: 20px;
  margin-bottom: 30px;
}

.custumer-ordertablerow ul {
  display: grid;
  align-items: center;
  grid-template-columns: 20% 35% 34% 7% 1%;
  /* text-align: center; */
  list-style: none;
  margin: 0 0 10px 0;
  background-color: var(--dark-Pale-Aqua);
  padding: 15px 0 10px 30px;
  border-radius: 10px;
}

.custumer-ordertablerow ul li {
  color: var(--white);
}

.Biochemistry {
  margin: 50px 0;
  color: #4f6367;
  text-transform: uppercase;
}

.alltest-steve {
  font-size: 40px;
  font-weight: bold;
  color: var(--Slate);
}

.alltest-Hi {
  font-size: 40px;
  margin-right: 5px;
  color: var(--Slate);
}

.alltest-H3 {
  font-size: 30px;
  color: var(--Slate);
}

.ordertablebody ul li {
  color: var(--Slate);
  font-size: 13px;
}

.ordertablebody2 ul li,
.TaboverviewsOrder {
  color: var(--Slate);
  text-transform: uppercase;
}

.Latest-Result {
  background-color: var(--dark-Pale-Aqua);
  width: 100%;
  height: 200px;
  border-radius: 20px;
  padding: 10px 15px 15px 30px;
}

.addproduct-tabs li.nav-item .nav-link.active {
  background: var(--Slate);
  color: white;
}

.addproduct-tabs li.nav-item .nav-link {
  background: transparent;
  border: none;
  border: 1px solid var(--Slate);
  border-radius: 50px;
  font-size: 13px;
  font-weight: 400;
  color: var(--Slate);
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.detail-page-test h1 {
  color: var(--Slate);
  font-size: 20px;
}

.detail-page-test {
  background: #e4f1f1;
}

.test-btn.dl-btn a {
  font-size: 13px;
  height: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 210px;
  color: white;
}

.test-btn button {
  border: 1px solid var(--Slate);
  background-color: var(--Slate);
}

.swithtoggle p {
  color: var(--Slate);
}

.deletedmodalpractit .modal-content {
  background: var(--Pale-Aqua);
}

.account-deleted h2 {
  color: var(--Slate);
}

.account-deleted p {
  color: var(--Slate);
}

.deletedmodalpractit .formgroup label {
  color: var(--Slate);
}

.deletedmodalpractit .formgroup input {
  border: 1px solid var(--Slate);
}

.btndele {
  color: var(--Slate);
  border: 1px solid var(--Slate);
}

.closehandlebtndelet {
  color: var(--Slate);
  border: 1px solid var(--Slate);
}

.my-file-upload p .active a {
  color: var(--Slate) !important;
}

.ordertablebody-Client ul li {
  color: var(--Slate);
  font-size: 13px;
}

.ordertablebody-Client ul {
  display: grid;
  align-items: center;
  /* grid-template-columns: 20% 20% 15% 15% 15% 15%; */
  grid-template-columns: auto auto auto auto auto auto;
  list-style: none;
  padding: 20px 0 20px 40px;
  margin: 0 0 15px 0;
  background-color: var(--Pale-Aqua);
  border-radius: 20px;
}

.serum-steve-order2 {
  background-color: var(--Pale-Aqua);
  margin: 20px 0;
  padding: 30px;
  border-radius: 10px;
  height: 79vh;
}

.serum-steve-order2 h1 {
  text-transform: uppercase;
  font-size: 25px;
  margin: 20px 0 30px 0;
  color: var(--Slate);
}

.serum-steve-order2 label {
  width: 100%;
  margin: 5px 0;
  font-size: 13px;
  color: var(--Slate);
}

.serum-steve-order2 input {
  width: 50%;
  padding: 15px;
  border-radius: 10px;
  outline: none;
  border: none;
  margin-bottom: 30px;
  font-size: 13px;
}

.Add-Client-End {
  text-align: center;
}

.Add-Client-End a {
  border: 1px solid var(--Slate);
  padding: 10px 35px;
  border-radius: 10px;
  background-color: var(--Slate);
  color: #ffffff;
  font-size: 20px;
}

.ordertablerow3 ul {
  display: grid;
  align-items: center;
  grid-template-columns: 12% 14% 17% 23% 17% 17%;
  text-align: center;
  list-style: none;
  margin: 0 0 10px 0;
  background-color: var(--Slate);
  padding: 5px 0 5px 0;
  border-radius: 50px;
}

.plus-test-icon {
  border: 1px solid var(--Slate);
  background-color: var(--Slate);
  padding: 5px 10px 3px 10px;
  font-size: 20px;
  color: white;
}

/* Health-Profile-tab */

.Health-Profile-tab {
  border: 1px solid var(--Slate);
  background-color: var(--Slate);
  text-align: center;
  /* width: 90%; */
  margin: 0px 0 30px 0;
  padding: 10px 0;
  border-radius: 20px;
  color: white;
}

.main-Health-Profile-tab .innertitle {
  margin-bottom: 20px;
  color: var(--Slate);
  font-size: 20px;
  font-weight: 600;
  text-align: center;
}

.Health-Profile-loader {
  width: 100%;
  background-color: var(--Pale-Aqua);
  height: 15px;
  border-radius: 50px;
  margin: 0 0 30px 0;
}

.Health-Profile-loader span {
  width: 80%;
  background-color: var(--Slate);
  height: 15px;
  border-radius: 50px;
  display: block;
}

.Health-Profile-Content {
  width: 100%;
  background-color: var(--Pale-Aqua);
  border-radius: 20px;
  margin-bottom: 30px;
  padding: 40px 0;
}

.Health-Profile-inner {
  width: 90%;
  margin: 0 auto;
}

.Health-Profile-smoke h3 {
  font-size: 20px;
}

.Health-Profile-Information {
  text-align: center;
  border: 0px solid var(--Slate);
  width: 100%;
  background-color: white;
  padding: 15px 0;
  border-radius: 20px 20px 0 0;
  color: var(--Slate);
}

.Health-Profile-Information h1 {
  font-size: 20px;
  color: var(--Slate);
  margin: 0;
}

.bio-result-colors {
  display: grid;
  width: 100%;
  background: var(--Antique-White);
  padding: 50px 30px;
  border-radius: 11px;
  margin-top: 20px;
}

.bio-result-colors p {
  color: #4f6367;
  font-size: 16px;
  text-align: center;
  text-wrap: nowrap;
}

.Health-Profile-Height {
  border: 0px solid var(--Slate);
  width: 100%;
  background-color: white;
  padding: 30px 0;
  border-radius: 0 0 20px 20px;
  color: var(--Slate);
}

.Health-Profile-Age {
  display: flex;
  justify-content: space-around;
}

.Health-Profile-Age input {
  width: 100px;
  height: 40px;
  border-radius: 7px;
  border: 1px solid var(--Slate);
  margin: 10px 0 30px 0;
  padding: 0 0px 0 18px;
  font-size: 16px;
}

.Health-Profile-Age label {
  font-size: 16px;
  font-weight: 600;
  color: var(--Slate);
}

.Health-Profile-Whats {
  margin: 5px 0 5px 90px;
  width: 80%;
}

.Health-Profile-Whats label {
  font-size: 18px;
  margin: 5px 0;
}

.Health-Profile-Whats input {
  padding: 10px;
  font-size: 16px;
  border-radius: 10px;
  border: 1px solid var(--dark-Pale-Aqua);
}

.Health-Profile-smoke {
  width: 80%;
  margin: 0 auto;
}

.Health-Profile-smoke label {
  margin: 7px 0;
  font-size: 13px;
  font-weight: 600;
}

.Health-Profile-smoke input {
  padding: 10px;
  font-size: 13px;
  border-radius: 10px;
  margin-bottom: 20px;
  border: 1px solid var(--Slate);
}

.Health-Profile-Whats input::placeholder {
  color: var(--Pale-Aqua);
  font-size: 16px;
}

.Health-Profile-Age input::placeholder {
  color: var(--Pale-Aqua);
  font-size: 16px;
}

.Health-Profile-smoke input::placeholder {
  color: var(--Pale-Aqua);
  font-size: 16px;
}

/* serum steve test */

.serum-steve-order-Test {
  background-color: white;
  /* width: 95%; */
  padding: 10px 50px 10px 30px;
}

.serum-steve-order-Test-inner {
  background-color: #e9f6ff;
  border-radius: 30px;
  padding: 10px 0;
  margin: 5px 0 20px 0;
}

.serum-steve-order-Test-inner ul {
  display: grid;
  grid-template-columns: 15% 15% 20% 35% 15%;
  text-align: center;
  align-items: center;
}
.mob-overtableboy-client-2::-webkit-scrollbar {
  width: 0.4em;
}

.mob-overtableboy-client-2::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.mob-overtableboy-client-2 .ordertable {
  margin-right: 10px;
}
.mob-overtableboy-client-2::-webkit-scrollbar-thumb {
  background-color: #80a4a6;
  outline: 1px solid slategrey;
}
.serum-steve-order-Test-inner ul li {
  color: var(--Slate);
}

.Serum-order-steve-inp {
  width: 700px;
  position: relative;
}

.Serum-order-steve-inp span {
  top: 16px;
  left: 15px;
  font-size: 22px;
  color: var(--Slate);
}

.serum-ordertablerow {
  background-color: white;
  border-radius: 30px;
  padding: 10px 0;
  margin: 5px 0 20px 0;
}

.serum-ordertablerow ul {
  display: grid;
  grid-template-columns: 13% 13% 13% 13% 35% 13%;
  text-align: center;
  align-items: center;
}

.serum-ordertablerow ul li {
  color: var(--Slate);
}

.xmark-btn {
  right: -3px;
  top: -10%;
  font-size: 27px;
  color: #087bca;
}
.find-test-head {
  font-size: 13px !important;
}
.custom-select-scroll .css-1jqq78o-placeholder {
  font-size: 13px;
}
@media only screen and (max-width: 1800px) {
  .serum-steve-order2 {
    background-color: var(--Pale-Aqua);
    margin: 20px 0;
    padding: 30px;
    border-radius: 10px;
    height: 77.5vh;
  }
  .notification-box-parent {
    overflow-y: scroll;
    height: 327px !important;
  }
  .onscrolling {
    overflow-y: scroll;
    max-height: 600px;
  }

  .request-form {
    right: -1rem;
  }

  .test-result-practitioner-list p {
    margin: 0;
  }

  .testbtn2 {
    padding: 12px 30px;
  }

  .semibold {
    font-size: 17px;
  }

  .testBtn {
    font-size: 17px;
    text-align: center;
    width: 180px;
  }
}

@media only screen and (max-width: 1600px) {
  .serum-steve-order {
    background-color: var(--Antique-White);
    margin: 13px 0;
  }
  .serum-steve-order2 {
    background-color: var(--Pale-Aqua);
    margin: 20px 0;
    padding: 30px;
    border-radius: 10px;
    height: 74.3vh;
  }
  .notification-box-parent {
    overflow-y: scroll;
    height: 255px !important;
  }
  .practitioner-dash-main-right-inner {
    grid-template-columns: 24% 74%;
  }
  .onscrolling {
    overflow-y: scroll;
    max-height: 469px;
  }

  .practitioner-dash-main-right img {
    width: 2.3rem;
    position: relative;
    right: 5px;
  }

  .rounded-img-parent {
    max-height: 4rem;
  }

  .right-practitioner-heading-one {
    font-size: 0.8rem;
  }

  .right-practitioner-heading-two {
    font-size: 2.6rem;
  }

  .notification-box-para {
    font-size: 0.4rem !important;
  }

  .notification-box-heading {
    font-size: 0.5rem;
  }
  .vital-inner ul li span {
    font-size: 13px;
  }

  .vital-inner ul {
    padding: 10px 20px;
  }
  .practitionerHomepage {
    margin-top: 1rem !important;
  }
  .pract-noti-inner h6 {
    font-size: 18px;
    color: #3d2c3e;
    font-weight: 600;
  }
  section.admin-side-side.equal-height-cust-3 p {
    font-size: 14px;
  }
}

@media only screen and (max-width: 1440px) {
  .namecart {
    padding: 1rem 1.8rem;
  }
  .mob-search-pracClient {
    padding: 10px 36px !important;
  }

  .ordertablebody3 ul li {
    font-size: 12px;
    color: var(--Slate);
  }
  .serum-steve-order2 h1 {
    text-transform: uppercase;
    font-size: 20px;
    margin: 0px 0 0px 0;
    color: var(--Slate);
  }
  .serum-steve-order2 input {
    margin-bottom: 40px !important;
  }
  .serum-steve-order2 {
    background-color: var(--Pale-Aqua);
    margin: 20px 0;
    padding: 30px;
    border-radius: 10px;
    height: 72vh;
  }
  .namecart h4 {
    font-size: 20px;
  }
  .onscrolling {
    max-height: 368px;
  }
  .heartscore span {
    font-size: 150px;
  }
  .polygon-shap-score {
    left: -20px;
    position: relative;
    top: 20px;
  }

  .meter img {
    width: 184px;
    height: inherit;
  }

  .meter h4 {
    font-size: 10px;
  }

  .meter h4 span {
    font-size: 20px;
  }

  .per-results {
    height: 26px;
    width: 85px;
    font-size: 11px;
  }

  .borderbg {
    height: 100%;
  }

  .practitionerHomepage {
    grid-column-gap: 1rem;
    margin-top: 1.5rem !important;
  }
  .notification-box-parent {
    overflow-y: scroll;
    height: 195px !important;
  }
  .cart-inner-name {
    padding: 0.5rem 7rem 0.5rem 1.5rem;
  }

  .cart-inner-name p {
    font-size: 15px;
  }

  .cartTotalAmount p {
    font-size: 17px;
  }

  .pract-cart-list {
    padding: 1rem 2rem;
  }

  .testbtnbox {
    padding: 2rem 1rem;
  }

  .testbtn2 {
    padding: 10px 20px;
  }

  .semibold {
    font-size: 15px;
  }

  .swithtoggle p {
    font-size: 16px;
  }

  .account-deleted h2 {
    font-size: 20px;
  }

  .account-deleted p {
    font-size: 16px;
    width: 80%;
  }

  .btndele {
    padding: 5px 23px;
    font-size: 16px;
  }

  .deletedmodalpractit .formgroup label {
    font-size: 19px;
  }

  .deletedmodalpractit .formgroup input {
    height: 37px;
  }

  .pract-noti-inner h6 {
    font-size: 15px;
  }
  section.admin-side-side.equal-height-cust-3 p {
    font-size: 13px;
  }

  .pract-noti-inner p {
    font-size: 15px;
    width: 90%;
  }

  .notitime {
    font-size: 14px;
  }
  .seemore-heart {
    margin-top: 2rem;
  }

  .pract-sett-tabs .addproduct-tabs li.nav-item .nav-link {
    font-size: 11px;
    padding: 4px 16px;
  }

  .pract-sett-tabs .addproduct-tabs li.nav-item .nav-link img {
    height: 15px;
  }
}

@media only screen and (max-width: 1366px) {
  .practitioner-dash-main-right img {
    width: 1.8rem;
  }

  .rounded-img-parent {
    max-height: 3rem;
  }

  .practitioner-dash-main-right .dummy-img {
    bottom: 6px;
    right: 7px;
  }

  .practitioner-dash-main-right .send-img {
    bottom: 5px;
    right: 9px;
  }

  .right-practitioner-heading-one {
    font-size: 0.6rem;
  }

  .right-practitioner-heading-two {
    font-size: 2.2rem;
  }

  .notification-box-heading {
    font-size: 0.6rem;
  }

  .notification-box-para {
    font-size: 0.3rem;
  }
}

.test-field.cit-st select {
  width: 100%;
  font-size: 13px;
  border: 1px solid #c5c5c5;
  border-radius: 6px;
  height: 45px;
  padding: 0px 9px;
  background: transparent;
  color: black;
}

.notification-box-parent {
  overflow-y: scroll;
  height: 405px;
}
.notification-box-parent::-webkit-scrollbar {
  width: 0.4em;
}

.notification-box-parent::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.notification-box-parent::-webkit-scrollbar-thumb {
  background-color: var(--Slate);
  outline: 1px solid var(--Antique-White);
}

.Health-Profile-smoke input::placeholder {
  color: #4f6367;
}

.d-flex input::placeholder {
  color: #4f6367;
}

.test-box-include {
  height: 100%;
  border: none;
  background: white;
  padding: 8px 30px;
  border-radius: 5px;
}

.test-box-include .ordertablebody3 ul {
  position: relative;
  border: 1px solid #087bca;
  grid-template-columns: 20% 20% 20% 20% 20%;
}

.close-test-btn {
  background: white;
  border-radius: 20px;
  font-size: 15px;
  color: #087bca;
  padding: 4px 5px;
  position: absolute;
  right: -6px;
  top: -4px;
  border: 1px solid #087bca;
}

.pr-info-client {
  background: #fbf7ef;
  border-radius: 20px;
  padding: 20px 0;
  width: 600px;
}
i.fa-solid.fa-chevron-left.back-arrow-icon {
  margin-left: -4rem;
}
.pr-info-client ul {
  display: grid;
  grid-template-columns: 61% 41%;
  justify-content: space-around;
  width: 90%;
  margin: 0 auto;
  background: #b8d9d9;
  border-radius: 40px;
  margin-bottom: 10px;
  box-shadow: -1px -1px 5px -2px #707070;
  -webkit-box-shadow: -1px -1px 5px -2px #707070;
  -moz-box-shadow: -1px -1px 5px -2px #707070;
  padding: 0 15px;
}
.pr-info-client h1 {
  margin-bottom: 10px !important;
}

.cust-pract-profile-detail {
  margin-left: 6rem !important;
}
.pr-info-client ul li {
  font-size: 16px;
  font-weight: 600;
  color: #4f6367;
  padding: 8px 0px;
}

.pr-info-client ul li:last-child {
  color: #749598;
  font-weight: 500;
}

.serum-steve-order2 .css-b62m3t-container {
  width: 50%;
  border-radius: 10px;
  outline: none;
  border: none;
  margin-bottom: 30px;
  font-size: 13px;
}

.serum-steve-order2 .css-13cymwt-control {
  border-radius: 10px;
}

.serum-steve-order2 .css-t3ipsp-control {
  outline-color: none !important;
  border: none !important;
  border-radius: 10px !important;
  box-shadow: black !important;
}

.css-t3ipsp-control:hover {
  border-color: red;
}

.css-1fdsijx-ValueContainer {
  height: 50px;
}

@media only screen and (max-width: 1280px) {
  .test-result-practitioner-list {
    padding: 1rem 0.8rem;
  }

  .test-result-practitioner-list p {
    font-size: 12px !important;
  }
}

.serum-steve-order .css-1fdsijx-ValueContainer {
  height: 29px;
  font-size: 13px;
}

.serum-steve-order .css-13cymwt-control {
  border-radius: 12px;
}

.single-line-time span {
  text-wrap: nowrap;
}

.font-weight-medium {
  font-weight: 500;
}
.css-19gyxg0-option {
  font-size: 14px !important;
}
.css-48xten-MenuList {
}

/* RESPONSIVE */

@media only screen and (max-width: 999px) {
  .sidebarpractitioner {
    height: 100vh;
    position: fixed;
    top: 0 !important;
  }
  .sidebaradmin {
    border-radius: 0 0px 0px 0;
  }
}
@media only screen and (max-width: 767px) {
  .mob-overtableboy-client-2 {
    /* height: 51vh; */
    max-height: initial;
    overflow-y: initial;
  }

  /* .mob-overtableboy-client-2 {
    max-height: initial;
    overflow-y: auto;
  }
  .mob-overtableboy-client-2::-webkit-scrollbar {
    display: inline !important;
  }
  .mob-overtableboy-client-2::-webkit-scrollbar {
    width: 1px !important;
    height: 1px !important;
    background-color: red !important;
  } */

  /* .mob-overtableboy-client-2::-webkit-scrollbar-thumb {
    background: var(--dark-Pale-Aqua);
  } */

  .practitioner-dash-main-right-inner {
    margin-top: 1rem;
    grid-template-columns: 73% 25%;
  }
  .notification-box-parent {
    overflow-y: auto;
    max-height: 250px !important;
  }
  .practitioner-dash-main-notification {
    margin-bottom: 1rem;
  }
  .serum-steve-order2 .css-qbdosj-Input {
    height: 15px;
  }
  /* .serum-steve-order2 .css-166bipr-input {
    line-height: 2;
  } */
  .cust-pract-profile-detail {
    margin-left: 0.5rem !important;
  }
  .pr-info-client {
    background: #fbf7ef;
    border-radius: 20px;
    padding: 20px 0;
    width: 100%;
  }
  .pr-info-client ul {
    display: grid;
    grid-template-columns: 50% 50%;
  }
  .pr-info-client ul li {
    font-size: 10px !important;
  }
  .namecart h1 {
    font-size: 20px;
  }
  .namecart {
    display: grid;
    gap: 0%;
    grid-template-columns: 100%;
    align-items: center;
    background: var(--Admin-gradiant);
    border-radius: 10px;
    padding: 2rem 1.8rem;
  }
  .practitionerHomepage {
    display: block;
  }
  .namecart h4 {
    font-size: 20px;
  }
  .practitionerHomepage {
    /* display: block; */
    margin-top: 1rem !important;
  }
  .mob-test-reult-practitioner {
    overflow-x: scroll;
  }
  .mob-test-reult-practitioner div {
    width: 700px;
  }
  .mob-date-test-result {
    display: flex;
    justify-content: center;
  }
  .rounded-img-parent {
    padding: 10px !important;
    width: fit-content;
    /* background-color: initial !important; */
    background: white;
    padding: 1rem;
  }
  .practitioner-dash-main-right .mob-heading-client {
    padding: 0 !important;
  }
  .practitioner-dash-main-right .dummy-img {
    bottom: initial !important;
    left: initial !important;
    right: initial !important;
  }
  .practitioner-dash-main-right .send-img {
    bottom: initial !important;
    left: initial !important;
    right: initial !important;
  }
  /* .rounded-img-parent {
  max-height: initial !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.practitioner-dash-main-right .dummy-img {
  bottom: initial !important; 
right: initial !important;
}
.practitioner-dash-main-right .send-img {
  bottom: initial !important; 
  right: initial !important;
} */

  .mob-date-test-result {
    display: flex;
    justify-content: center !important;
    align-items: center;
    text-align: center;
  }
  .right-practitioner-heading-two {
    font-size: 20px;
  }
  .mob-overtable-main {
    overflow-x: scroll;
  }
  .mob-overtable-main div {
    width: 710px;
  }
  .ordertablebody3 ul {
    padding: 5px 0 5px 10px;
    border-radius: 30px;
  }

  /* .mob-overtableboy-client-2 div {
    width: 700px;
    max-height: 60vh;
  } */
  .ordertablebody-Client ul {
    padding: 20px 0 20px 20px;
  }
  .ordertablebody-Client ul {
    padding: 20px 0 20px 20px;
    text-align: center;
  }
  .mob-search-pracClient {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
  }
  .Health-Profile-tab h1 {
    font-size: 20px;
    margin: 0;
  }
  .Health-Profile-Whats {
    margin: 0 auto;
    width: 80%;
  }
  .mob-label-prac-healthprofile {
    justify-content: center !important;
  }
  .bio-result-colors {
    padding: 30px 30px;
  }
  .vital-inner ul li span {
    color: white;
    font-size: 10px;
  }
  .vital-inner ul li span {
    color: white;
    font-size: 10px;
    display: grid;
    transition: all 0.5s ease-in-out;
    gap: 2px;
    text-align: center;
    justify-content: center;
    /* align-items: center; */
  }
  .vital-inner ul {
    display: grid;
    align-items: center;
    grid-template-columns: 34% 32% 31%;
    background: #7a9ea0;
    border-radius: 10px;
    padding: 10px 10px;
    transition: all 0.5s ease-in-out;
  }
  .vitals-data {
    margin-top: 1rem;
  }
  .vitals-data h2 {
    color: var(--Slate);
    font-size: 20px;
    font-weight: 600;
    margin: 0;
  }
  .customer-test-title h1 {
    font-weight: 200;
    font-size: 20px;
  }
  .customer-test-title {
    margin-bottom: 1rem !important;
  }
  .mob-customer-all-test-result {
    overflow-x: scroll;
  }
  .mob-customer-all-scroll {
    width: 1000px;
  }

  .view-result-mob {
    width: 100% !important;
  }
  .alltest-box-1 li {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .serum-steve-order2 .css-b62m3t-container {
    width: 100%;
  }
  .serum-steve-order2 .css-1fdsijx-ValueContainer {
    height: 39.5px !important;
  }
  .serum-steve-order2 input {
    width: 100%;
    padding: 10px;
    border-radius: 3px;
    outline: none;
    border: none;
    margin-bottom: 30px;
    font-size: 13px;
  }
  .ordertablebody3 ul {
    margin-bottom: 0;
  }
  .mob-ordertablebody3 {
    overflow-x: scroll;
  }
  .mob-ordertablebody3 div {
    width: 700px;
  }
  .mob-ordertablebody3 .ordertablebody3 ul {
    border-radius: 10px !important;
  }
  .ordertablebody3 ul li {
    font-size: 10px;
    color: var(--Slate);
    text-align: center;
  }
  .serum-steve-order {
    padding: 20px 20px;
  }
  .test-box-include {
    height: 100%;
    border: none;
    background: white;
    padding: 8px 5px;
  }
  .mob-ordertable {
    overflow-x: scroll;
  }
  .mob-ordertable div {
    width: 700px;
  }
  .ordertablebody3 ul {
    margin-bottom: 0.6rem;
  }
  .mob-chart-adjust div {
    height: 260px !important;
    width: 310px;
  }
  .ordertable {
    overflow-x: scroll;
    max-height: 50vh;
  }
  .ordertable div {
    width: 700px;
  }
  span.arrow-back i {
    font-size: 20px;
  }
  .practitioner-dash-top-search-parent input {
    width: 100% !important;
  }
  .mob-biormarker-btn {
    margin-left: 0 !important;
  }
  .serum-steve-order2 .css-b62m3t-container {
    margin-bottom: 20px;
  }
  .pract-sett-tabs .detail-page-test {
    width: 100%;
  }
  span.mob-password-toggle {
    top: 50% !important;
  }
  .account-deleted img {
    width: 150px;
    margin-bottom: 20px;
  }
  .account-deleted h2 {
    font-size: 20px;
    text-align: center;
  }

  .account-deleted p {
    font-size: 11px;
    width: 100%;
  }
  .deletedmodalpractit .formgroup input {
    font-size: 14px;
  }
  .deletedmodalpractit .formgroup label {
    font-size: 16px;
  }
  .btnsubmodal {
    margin-top: initial !important;
    justify-content: space-between;
  }
  .btndele {
    margin-right: initial !important;
    font-size: 12px;
    width: 100px;
  }
  .onscrolling {
    /* overflow-x: auto; */
  }
  .onscrolling div {
    width: 500px;
  }

  /* PRACTITIONER >>>> CLIENT RESULT */
  .mob-chart-adjust {
    height: 215px;
  }
  .test-report-render {
    grid-template-columns: 100%;
  }
  .vital-result-detail {
    background-color: #ffffff;
    padding: 0 20px 20px 20px;
    margin-top: 0rem;
    border-radius: 8px;
  }
  .range-sld {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .print-btn button {
    font-size: 14px;

    width: 115px;

    height: 40px;
  }
  .view-result-mob {
    display: flex;
    justify-content: end;
  }

  .onscrolling > div {
    width: 500px;
  }
  .onscrolling {
    max-height: 460px;
    /* overflow-y: auto; */
  }

  .onscrolling::-webkit-scrollbar {
    width: initial;
    border-radius: initial;
    height: initial;
  }

  .onscrolling::-webkit-scrollbar-track {
    background-color: initial;
    border-radius: initial;
  }

  .onscrolling::-webkit-scrollbar-thumb {
    background-color: initial;
    border: initial;
    border-radius: initial;
  }
  /* PRACTITION >>>> CLIENT RESULT */
}
@media only screen and (max-width: 400px) {
  .mob-chart-adjust div {
    height: 260px !important;
    width: 280px;
  }
  .Patient-report-name {
    padding: 20px 2rem;
  }
  .mt-4.mob-chart-adjust div {
    height: 230px !important;
  }
  .mob-chart-adjust div {
    height: 260px !important;
    width: 235px;
  }
  .inner-recom-box h2 {
    font-size: 17px;
  }
  .Health-Profile-Height {
    padding-top: 10px;
  }
}
@media only screen and (max-width: 365px) {
  .vital-data-cust {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;
  }
  .vital-inner {
    background: var(--Antique-White);
    padding: 15px 5px 0;
    border-radius: 11px;
    border: 1px solid var(--Slate);
  }
}

/* RESPONSIVE */
