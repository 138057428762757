@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;400;600;700;800;900&display=swap");

:root {
  --Pale-Aqua: #b8d9d9;
  --Slate: #4f6367;
  --Antique-White: #fbf7ef;
  --white: #ffff;
  --dark-Pale-Aqua: #7a9ea0;
}

/* universal style  */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h2 {
  text-transform: uppercase;
}

button {
  background: none;
  border: none;
  color: inherit;
}

body {
  font-family: "Poppins", sans-serif;
  font-size: 1.125rem;
}

a {
  text-decoration: none;
  cursor: pointer !important;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 0rem;
  padding-left: 0;
}

li {
  list-style: none;
}

.container-1600 {
  width: 1600px;
  margin: 0 auto;
}

a {
  text-decoration: none !important;
}

/* universal style  */
.prnt-error-txt {
  font-size: 1.125rem;
  font-weight: 500;
  color: rgb(221, 21, 21);
}

/* header */
.menau-alignment {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.web-prdct-text {
  font-size: 40px !important;
  font-family: "Poppins";
  font-weight: 600 !important;
  color: #4f6367;
  margin: 0;
}

.web-prdct-para {
  font-size: 18px !important;
  font-family: "Poppins";
  color: #333333;
  margin: 0;
  padding: 12px 0 30px !important;
}

.cntnu-shop-btn {
  border: 2px solid #4f6367 !important;
  background-color: transparent !important;
  color: #4f6367 !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  border-radius: 50px !important;
  padding: 8px 36px !important;
  margin: 0 12px 0 0 !important;
}

.checkout-btn {
  border: 2px solid #4f6367 !important;
  background-color: transparent !important;
  color: #000 !important;
  font-size: 18px;
  font-weight: 600;
  border-radius: 50px;
  padding: 8px 112px;
}

.huz-modal .modal-footer {
  justify-content: center !important;
  gap: 10px;
}

.main-menu.desktop ul {
  display: flex;
  gap: 30px;
}

.main-menu.desktop ul li a {
  padding: 3px 11px;
  font-size: 1.2rem;
  font-family: "Poppins";
  font-weight: 500;
  text-decoration: none;
  color: var(--Antique-White);
  border-radius: 0;
  position: relative;
}

.btn-login {
  display: flex;
  gap: 20px;
}

.searchbar input {
  border: none;
  background: white;
  border-radius: 7px;
  height: 43px;
  padding: 0 30px 0 8px;
  font-size: 20px;
  font-weight: 400;
  font-family: "Poppins";
  color: black;
}

.logo a img {
  width: 155px;
}

header.Mean-header {
  background: var(--Slate);
  position: fixed;
  width: 100%;
  z-index: 99;
  top: 0;
  padding: 6px 0;
}

.searchbar input::placeholder {
  color: #0000006e;
}

.searchbar {
  position: relative;
  display: flex;
  align-items: center;
}

.searchbar i {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: var(--Slate);
}

.cart-bx i {
  color: var(--Antique-White);
}

a.back-trans.border-btn {
  border: 1px solid var(--Antique-White);
  border-radius: 12px;
  padding: 14px 20px;
  display: flex;
  align-items: center;
  text-decoration: none;
  position: relative;
}

.login-btn {
  background: var(--Pale-Aqua);
  padding: 10px 30px;
  border-radius: 50px;
  font-size: 1rem;
  font-weight: 500;
  color: var(--Slate);
  border: 2px solid var(--Pale-Aqua);
  font-family: "Poppins";
}

/* menus hover header */

.main-menu.desktop ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style-type: none;
}

.main-menu.desktop a:hover {
  background-color: var(--Antique-White);
  color: white;
}

.main-menu.desktop a:before {
  content: "";
  position: absolute;
  bottom: 12px;
  left: 12px;
  width: 12px;
  height: 12px;
  border: 3px solid var(--Pale-Aqua);
  border-width: 0 0 3px 3px;
  opacity: 0;
  transition: all 0.3s;
}

.main-menu.desktop a:hover:before {
  opacity: 1;
  bottom: -8px;
  left: -8px;
}

.main-menu.desktop a:after {
  content: "";
  position: absolute;
  top: 12px;
  right: 12px;
  width: 12px;
  height: 12px;
  border: 3px solid var(--Pale-Aqua);
  border-width: 3px 3px 0 0;
  opacity: 0;
  transition: all 0.3s;
}

.main-menu.desktop a:hover:after {
  opacity: 1;
  top: -8px;
  right: -8px;
}

.main-menu ul .active a:after {
  opacity: 1;
  top: -8px;
  right: -8px;
}

.main-menu ul .active a:before {
  opacity: 1;
  bottom: -8px;
  left: -8px;
}

/* header end */

/* home page style start */

/* banner home */
@keyframes changeword {
  0% {
    transform: translatex(0px);
    opacity: 0;
  }

  5% {
    opacity: 1;
    transform: translatex(0px);
  }

  20% {
    opacity: 1;
    transform: translatex(0px);
  }

  30% {
    opacity: 0;
    transform: translatex(0px);
  }

  80% {
    opacity: 0;
    transform: translatex(0px);
  }
}

.positivity .positivity__words .change:nth-child(1) {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.positivity .positivity__words .change:nth-child(2) {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}

.positivity .positivity__words .change:nth-child(3) {
  -webkit-animation-delay: 4s;
  animation-delay: 4s;
}

.positivity .positivity__words .change:nth-child(4) {
  -webkit-animation-delay: 6s;
  animation-delay: 6s;
}

/* .positivity .positivity__words .change {
  display: inline-block;
  position: absolute;
  top: 31%;
  opacity: 0;
  -webkit-animation: changeword 8s linear infinite;
  animation: changeword 8s linear infinite;
  border-bottom: 5px solid var(--dark-Pale-Aqua);
} */

.positivity .positivity__words .change {
  display: inline-block;
  position: absolute;
  bottom: 4%;
  opacity: 0;
  animation: changeword 8s linear infinite;
  border-bottom: 5px solid var(--dark-Pale-Aqua);
  left: 50%;
}

.banner-img {
  text-align: end;
}

.bannercontent h4 {
  color: #0f0c25;
  font-size: 27px;
  font-weight: 300;
}

.bannercontent h1 {
  font-size: 55px;
  padding: 5px 0px 0px 0px;
  font-weight: 600;
  color: var(--Slate);
  display: flex;
  line-height: 63px;
  flex-direction: column;
  gap: 10px;
  text-transform: capitalize;
  position: relative;
}

.bannercontent p {
  color: #959595;
  font-size: 1.1rem;
  padding: 15px 0;
  display: flex;
  gap: 18px;
  margin: 0;
  margin-top: 10px;
  align-items: center;
  margin-bottom: 10px;
}

.bannercontent p span {
  color: #0f0c25;
  font-size: 24px;
}

/* .bannercontent .positivity span {
  color: var(--dark-Pale-Aqua);
  font-size: 94px;
  line-height: 70px;
} */
.bannercontent .positivity span {
  color: var(--dark-Pale-Aqua);
  font-size: 60px;
  line-height: 70px;
}

.bannercontent > a {
  display: flex;
  background-color: var(--Pale-Aqua);
  color: var(--Slate);
  border-radius: 15px;
  height: 55px;
  font-size: 20px;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
  width: 200px;
  margin-bottom: 19px;
}

a.pop-slide-btn-one.btnAnimated {
  background: transparent;
}

section.secbanner {
  padding: 200px 0px;
  /* background-image: url(../images/bannerhome.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; */
}

.row.home-row-ban {
  padding-left: 180px;
}

section.secbanner {
  position: relative;
  height: 90vh;
  background-color: var(--Antique-White);
}

.bannercontent {
  position: relative;
  z-index: 1;
}

span.left-ban-img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0.5;
  left: -13%;
}

span.left-ban-img img {
  width: 80%;
  animation: animName 50s linear infinite;
}

@keyframes animName {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.banner-img img {
  width: 80%;
}

.inputBox {
  width: 600px;
  background: var(--white);
  border: 1px solid var(--Slate);
  padding: 27px 20px;
  border-radius: 15px;
  z-index: 9;
  position: absolute;
  bottom: -45px;
  display: flex;
  top: initial;
  /* gap: 20px; */
  height: 90px;
}

.inputBox input:focus {
  outline: none;
}

.inputBox i.fa-solid.fa-magnifying-glass {
  color: var(--Slate);
  font-size: 38px;
  padding-right: 16px;
}

.inputBox input {
  background: transparent;
  border: 0;
  font-size: 22px;
  font-weight: 300;
  color: var(--Slate);
  font-family: "Poppins", sans-serif;
}

.inputBox input::placeholder {
  color: var(--Slate);
  font-size: 1.125rem;
  font-weight: 600;
}
.footer-logo-2 {
  display: none !important;
}
.askquestion {
  position: fixed;
  right: 15px;
  bottom: 3%;
  z-index: 11;
}

.askquestion a {
  text-decoration: none;
  font-size: 1.125rem;
  font-weight: 700;
  padding: 8px 16px;
  border: 3px solid var(--Slate);
  border-radius: 15px;
  font-family: "Poppins";
  color: var(--Slate);
  transition: all 0.5s ease-in-out;
  background: var(--Antique-White);
}

.askquestion a:hover {
  background: var(--Pale-Aqua);
}

/* home sec 1 */
.main-banner-sec {
  position: relative;
  background-image: url(../images/sec1bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  padding-bottom: 100px;
  background-color: #4f6367;
}

.contentbox {
  padding: 100px 0 50px;
  width: 65%;
  margin: 0 auto;
  text-align: center;
}

.contentbox {
  padding: 120px 0 50px;
  width: 65%;
  margin: 0 auto;
  text-align: center;
}

.contentbox h2 {
  color: var(--Pale-Aqua);
  font-size: 50px;
  font-weight: 600;
}

.cards.card1 {
  text-align: center;
  margin-bottom: 30px !important;
  background: var(--Antique-White);
  border-radius: 50%;
  height: 280px;
  width: 280px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.cards.card1 img:first-child {
  position: absolute;
  opacity: 0;
  display: none;
  transition: all 0.5s ease-in-out;
}

.parentBox:hover .cards.card1 img:first-child {
  opacity: 1;
  transition: all 0.5s ease-in-out;
}

.parentBox:hover {
  background: var(--Pale-Aqua);
  transition: 0.5s ease-in-out;
}

.parentBox:hover p {
  color: var(--Slate);
}

.parentBox:hover h3 {
  color: var(--Slate);
}

.parentBox {
  padding: 30px 0px;
  transition: all 0.5s ease-in-out;
  border-radius: 5px;
}

.grid-col.workBox:hover .circlemain {
  background: var(--Slate);
  color: white;
}

.contentbox p {
  color: var(--white);
  font-size: 1.125rem;
  font-weight: 300;
}

.parentBox p {
  font-size: 1.125rem;
  text-align: center;
  color: var(--white);
  font-weight: 400;
  width: 85%;
  margin: 0 auto;
}

.grid-nav3 {
  display: grid;
  justify-content: center;
  width: 90%;
  margin: 0 auto;
  grid-template-columns: 33.33% 33.33% 33.33%;
}

.btn.btnBox {
  width: 100%;
}

.btn.btnBox a {
  display: flex;
  background: var(--Pale-Aqua);
  padding: 8px 35px;
  border-radius: 15px;
  height: 55px;
  font-size: 22px;
  color: var(--Slate);
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
  width: 300px;
  margin: 0 auto;
  margin-top: 50px;
}

.parentBox h3 {
  color: var(--Pale-Aqua);
  font-size: 30px;
  text-align: center;
  font-weight: 600;
}

/* sec third */

.how-work-sec {
  padding: 100px 0;
  background: var(--Pale-Aqua);
  position: relative;
  overflow: hidden;
}

.psotbox {
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  height: 240px;
  text-align: center;
  margin-bottom: 20px;
}

.circlemain {
  border: 5px solid var(--Slate);
  height: 90px;
  width: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: -0 auto;
  background: var(--Antique-White);
  border-radius: 100px;
  font-size: 38px;
  color: var(--Slate);
  margin-top: 0px;
  margin-bottom: 20px;
  transition: all 0.5s ease-in-out;
}

.psotbox img {
  width: 160px;
  position: absolute;
  transition: all 0.5s ease-in-out;
}

.left-side-test-icon {
  position: absolute;
  left: -9%;
  top: -20%;
}

.right-side-test-icon {
  position: absolute;
  right: -9%;
  bottom: -30%;
}

.how-work-sec h2 {
  margin-bottom: 30px;
  font-weight: 600;
  color: var(--Slate);
  font-size: 50px;
  text-align: center;
}

.grid-col.workBox img:first-child {
  opacity: 0;
  transition: all 0.5s ease-in-out;
}

.grid-col.workBox:hover img:first-child {
  opacity: 1;
  transition: all 0.5s ease-in-out;
}

.grid-col.workBox:hover img:last-child {
  opacity: 0;

  transition: all 0.5s ease-in-out;
}

.blogs h2 {
  font-weight: 500;
}

.how-work-sec p {
  font-size: 1.125rem;
  text-align: center;
  color: var(--Slate);
}

.btn.how-btn {
  display: table;
  margin: 0 auto;
  padding: 6px 20px;
  background: var(--Slate);
  margin-top: 10px;
  box-shadow: 0px 0 7px 1px #00000059;
  border-radius: 10px;
  color: var(--white);
  font-size: 1.125rem;
  font-weight: 500;
  width: 180px;
}

.workBox {
  transition: all 0.5s ease-in-out;
}

.how-work-sec:before {
  content: "";
  position: absolute;
  height: 660px;
  width: 32%;
  background-image: url("../images/howworkleftimg.png");
  background-repeat: no-repeat;
  background-position: top 40px center;
  background-size: 100%;
  left: -23%;
  opacity: 0.5;
  top: 0;
  animation: animName 50s linear infinite;
}

.how-work-sec:after {
  content: "";
  opacity: 0.5;
  position: absolute;
  height: 660px;
  width: 32%;
  background-image: url("../images/howworkleftimg.png");
  background-repeat: no-repeat;
  background-position: top 40px center;
  background-size: 100%;
  right: -16%;

  bottom: 0;
  animation: animName 50s linear infinite;
}

section.personlize-sec {
  padding: 100px 0;
  /* background-image: url("../images/bg-post.png"); */
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}

.navGrid-col2 {
  display: grid;
  grid-gap: 2%;
  grid-template-columns: 54% 44%;
}

.navGrid-col2 img {
  width: 580px;
}

.contentBox2 h2 {
  font-size: 45px;
  text-align: left;
  line-height: 53px;
  margin-bottom: 16px;
  font-weight: 600;
}

.contentBox2 p {
  width: 95%;
  color: #959595;
  font-weight: 500;
  font-size: 20px;
}

.contentBox2 {
  padding-top: 88px;
}

.grid-col.workBox h4 {
  color: var(--Slate);
  font-size: 30px;
  text-align: center;
  font-weight: 500;
}

main {
  overflow-x: hidden;
}

/* sec fourth */
.personalize img {
  width: 100%;
  border-radius: 27px;
  box-shadow: 0px 0px 19px 7px #0000002b;
  position: relative;
}
/* .personalize::before {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(../images/overlay-img.png);
  background-position: bottom;
  background-size: 100% 100%;
  z-index: 1;
} */

.personalize-3::before {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(../images/overlay-img.png);
  background-position: bottom;
  background-size: 100% 100%;
  z-index: 1;
}
.perosonalize-content h2 span {
  font-weight: 400;
  font-size: 40px;
}

.contact-top-form input::placeholder {
  font-weight: 400;
}

.perosonalize-content {
  padding: 20px 0;
  text-align: center;
  position: absolute;
  bottom: 0;
  z-index: 2;
}

.personalize {
  position: relative;
}

.perosonalize-content h2 {
  margin-bottom: 10px;
  font-weight: 700;
  color: var(--white);
  font-size: 50px;
  text-align: left;
  line-height: 50px;
}

.perosonalize-content p {
  font-size: 1.125rem;
  text-align: left;
  width: 70%;

  color: var(--white);
  font-weight: 400;
}

section.section-3 {
  background-image: url("../images/populartest.png");
  background-size: cover;
  padding: 130px 0px;
  background-repeat: no-repeat;
  background-color: #fffdf6;
}

/*  */
video.fullscreen {
  position: absolute;
  z-index: 0;
  object-fit: inherit;
  width: 100%;
  height: 400px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -40%);
  z-index: 3;
}

.row.row-one video.fullscreen {
  transform: translate(-35%, -40%);
}

.row.row-two video.fullscreen {
  transform: translate(-65%, -40%);
}

.row.row-three video.fullscreen {
  transform: translate(-35%, -40%);
}

.row.row-one .perosonalize-content,
.row.row-three .perosonalize-content {
  padding-left: 9rem;
}

.row.row-two .perosonalize-content {
  padding-left: 4rem;
}

video.fullscreen::-webkit-media-controls {
  display: none !important;
}

.videofull-screen {
  position: relative;
}

/* faq sec */
.homefaq {
  background: white;
  padding: 24px 30px;
  border-radius: 16px;
  box-shadow: 0px 0px 14px 1px #00000030;
  width: 95%;
  margin: 0 auto;
  transition: all 0.5s ease-in-out;
  margin-bottom: 30px;
}

section.sechomefaq {
  padding: 100px 0;
  position: relative;
  overflow: hidden;
  background-image: url(../images/faqbg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-color: white;
}

section.sechomefaq > h1 {
  margin-bottom: 70px;
  font-weight: 600;
  color: var(--Slate);
  font-size: 50px;
  text-align: center;
  text-transform: uppercase;
}

.homefaq:hover {
  transform: scale(1.1);
  background: var(--Slate);
}

.homefaq:hover h2 {
  color: white;
}

.homefaq h2 {
  font-size: 1.3rem;
  font-weight: 500;
  font-family: "Poppins";
  margin: 0;
  transition: all 0.5s ease-in-out;
  color: var(--Slate);
}

.container-1100 {
  max-width: 1100px;
  margin: 0 auto;
}

.Faqbtn {
  text-align: center;
  margin-top: 50px;
}

.Faqbtn a i {
  margin-left: 13px;
  border: 1px solid;
  border-radius: 50px;
  padding: 7px 10px;
}

.Faqbtn a {
  padding: 18px 30px;
  border-radius: 40px;
  font-size: 30px;
  color: var(--Slate);
  font-weight: 400;
  display: inline-block;
  background-color: var(--Pale-Aqua);
  box-shadow: -1px 7px 14px 0px #25232338;
}

span.circle-left {
  position: absolute;
  left: -21%;
  top: -4%;
}

span.circle-left img {
  width: 82%;
  animation: animName 50s linear infinite;
}

span.circle-right img {
  width: 82%;
  animation: animName 50s linear infinite;
}

span.circle-right {
  position: absolute;
  right: -23%;
  bottom: -47%;
}

/*  popular test*/

/* CHANGED SLIDER HOME PAGE */

.pop-test {
  background-image: url("../images/populartest.png");
  background-size: cover;
  background-repeat: no-repeat;
  /* height: 500px; */
  padding: 90px 0px;
  overflow: hidden;
  background-color: #fefcf5;
  background-position: center;
}

.top-txt-pop h2 {
  color: var(--Slate);
  text-align: center;
  margin-bottom: 70px;
  font-weight: 600;
  font-size: 50px;
  text-align: center;
}

.grid-pop-tests {
  display: grid;
  grid-gap: 2%;
  grid-template-columns: 32% 32% 32%;
  padding: 50px 0px;
}

.box-pop-inner {
  background: #ffffff;
  padding: 30px 22px 30px 22px;
  border-radius: 30px;
  margin: 0 auto;
  width: 90%;
  border: 3px solid #4e6266;
  z-index: 2;
  position: relative;
}

.pop-slide-btn-parent button.viewdemo.viewtest {
  width: 190px;
  font-size: 14px;
}

.pop-slide-btn-parent .viewdemo:hover:before {
  width: 50px;
}

.pop-slide-btn-parent .viewdemo:before {
  width: 190px;
}

.pop-slide-btn-parent .viewdemo:after {
  font-size: 19px;
  right: 15px;
}

/* .box-pop-txt-grid {
  display: grid;
  grid-gap: 4%;
  grid-template-columns: 66% 30%;
} */

.inner-grid-txt-left h2 {
  font-size: 1.5rem;
  text-align: start;
  color: var(--Slate);
  line-height: 34px;
  font-weight: 600;
}

.inner-grid-txt-left h4 {
  font-size: 22px;
  font-weight: 400;
  text-align: start !important;
  color: var(--Slate);
}

.inner-grid-txt-left h5 {
  font-size: 30px;
  line-height: 10px;
  color: #087bca;
  font-weight: 600;
}

.box-pop-inner p {
  padding-top: 16px;

  color: var(--Slate);
  font-size: 1rem;
  text-align: start;
  font-weight: 400;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 3;
}

.monial-box-txt-chnge p {
  font-size: 1rem;
}

.monial-box-txt-chnge .Date-time-testimonial {
  font-size: 0.9rem;
}

.inner-grid-txt-right h6 {
  color: #ff5e54;
  font-size: 19px;
  font-weight: 600;
  margin-right: 15px;
}

.inner-grid-txt-right {
  display: flex;
  justify-content: center;
  padding-top: 10px;
}

.pop-slide-btn-parent {
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
}

.box-pop-inner h5 {
  font-size: 30px;
  color: var(--Slate);
  font-weight: 600;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 1;
}

.pop-slide-btn-one {
  border-radius: 20px;
  padding: 6px 39px;
  font-size: 20px;
  margin: 0 14px 0 0;
  transition: all 0.5s ease-in-out;
}

.pop-slide-btn-one {
  border: 1px solid var(--Slate);
  color: var(--Slate);
}

button.pop-slide-btn-one:hover {
  background: #4f6367;
  color: white;
}

.pop-slide-btn-one i {
  padding-right: 7px;
  font-size: 13px;
}

button.pop-slide-btn-one:hover {
  background: var(--Slate);
  color: white;
}

.pop-slide-btn-two {
  background-color: var(--Pale-Aqua);
  border-radius: 20px;
  color: var(--Slate);
  padding: 0 20px;
  transition: all 0.5s ease-in-out;
  border: 1px solid var(--Pale-Aqua);
}

.pop-slide-btn-two:hover {
  border-color: var(--Slate);
  background: transparent;
}

.pop-slide-btn-two span {
  color: var(--Slate);
}

.top-txt-pop {
  margin-bottom: 40px;
}

/* pop-test-slider-styles */
div#news-slider .owl-item.active.center {
  transform: scale(1.1);
}

div#news-slider .owl-stage {
  padding-bottom: 20px !important;
}

.Home-sec-Slider-set .slick-dots {
  position: absolute;
  bottom: -60px;

  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}

.Home-sec-Slider-set .slick-dots li.slick-active button:before {
  color: var(--Slate);
}

.Home-sec-Slider-set .slick-dots li button:before {
  font-family: "slick";
  font-size: 18px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: "•";
  text-align: center;
  opacity: 1.1;
  color: var(--Pale-Aqua);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.Home-sec-Slider-set .slick-dots li button:hover::before {
  color: #087bca;
  transition: all 0.42s ease;
}

.Home-sec-Slider-set .slick-prev:before {
  content: "\f104";
  font-family: FontAwesome;
  color: var(--Slate);
  font-size: 42px;
  z-index: 999;
  position: absolute;
  top: 50%;
  right: 0%;
}

.Home-sec-Slider-set .slick-next:before {
  content: "\f105";
  font-family: FontAwesome;
  color: var(--Slate);
  font-size: 42px;
  z-index: 999;
  position: absolute;
  top: 50%;
  right: 0%;
}

/*Subscriptions start  */
.subcription h2 {
  font-size: 30px;
  color: white;
  padding-bottom: 7rem;
  text-align: center;
  font-weight: 600;
}

.subcription ul li {
  color: var(--Slate);
  font-size: 1.1rem;
  width: 90%;
  margin: 0 auto;
}

.Schedule-price p {
  font-size: 35px;
  color: white;
  margin: 0;
  display: flex;
  flex-direction: column;
  font-weight: 600;
}

.Schedule-price p span {
  font-size: 27px;
}

.Schedule-price {
  width: 170px;
  height: 170px;
  border-radius: 80px;
  background: var(--Slate);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -5rem;
  left: 50%;
  transform: translateX(-50%);
}

.sub-price ul {
  padding-top: 9rem;
}

.sub-price {
  background: white;
  padding: 0px 0rem 1rem 0rem;
  text-align: center;
  position: relative;
  margin-bottom: 20px;
}

.subcribe-btn {
  color: white;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  width: 180px;
  margin: 0 auto;
  font-size: 20px;
  font-weight: 400;
  background: linear-gradient(
    0deg,
    rgba(79, 99, 103, 1) 0%,
    rgba(94, 112, 116, 1) 24%,
    rgba(107, 118, 120, 1) 48%,
    rgba(104, 118, 120, 1) 74%,
    rgba(83, 101, 104, 1) 100%
  );
}

.subcription {
  background-image: url(../images/subscriptionbg.png);
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 20px 30px 30px;
}

/* testimonial slider */
.testimonial-section {
  padding: 130px 0;
  background-color: white;
  overflow: hidden;
  background-image: url(../images/faqbg.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.monial-txt-chnge {
  text-align: center;
  margin-bottom: 70px;
}

.monial-txt-chnge h2 {
  font-size: 60px;
  font-weight: 600;
  color: var(--Slate);
}

/* .Home-Third-Slider-Set .slick-prev {
  left: -2%;
  z-index: 99;
}
.Home-Third-Slider-Set .slick-prev:before {
  content: "\f104";
  font-family: FontAwesome;
  background: var(--Slate);
  padding: 10px 15px;
  font-size: 32px;
  border-radius: 50%;
  z-index: 999;
  position: relative;
}
.Home-Third-Slider-Set .slick-next {
  right: 0%;
  z-index: 99;
}
.Home-Third-Slider-Set .slick-next:before {
  content: "\f105";
  font-family: FontAwesome;
  background: var(--Slate);
  padding: 10px 15px;
  font-size: 32px;
  border-radius: 50%;
  z-index: 999;
  position: relative;
}
.monial-box-chnge {
  border-radius: 40px;
}

.monial-box-img-chnge {
  padding-left: 100px;
}

.monial-box-img-chnge img {
  max-width: 150px;
}

.monial-box-txt-chnge {
  background: #faf9f7;
  border-radius: 30px;
  padding: 70px 50px 60px 90px;
  box-shadow: -4px 16px 12px -3px #0000005c;
  margin: 30px 0;
  position: relative;
}
.monial-box-txt-chnge h3 {
  color: var(--Slate);
  text-align: start;
  font-size: 44px;
  font-weight: 500;
  line-height: 52px;
}
.monial-box-txt-chnge:before {
  content: "";
  position: absolute;
  left: 4px;
  top: -10px;
  width: 101%;
  background: var(--Pale-Aqua);
  height: 100%;
  z-index: -1;
  border-radius: 33px;
}
.monial-box-txt-chnge h4 {
  color: var(--Slate);
  text-align: start;
  font-size: 26px;
  font-weight: 500;
  padding-bottom: 17px;
}

.monial-box-txt-chnge p {
  font-size: 15px;
  width: 90%;
  color: var(--Slate);
  font-weight: 500;
  margin-top: -17px;
  padding-bottom: 15px;
}

hr.monial-box-line-chnge {
  position: relative;
  border: #ff5e54 1px solid;
  left: 142px;
  width: 35%;
  bottom: 40px;
}

.monial-box-chnge {
  margin: 0px 65px;
} */

.monial-box-txt-chnge span i {
  color: #ecc828;
  margin-bottom: 10px;
}

.monial-box-txt-chnge {
  background: white;
  padding: 1rem 1rem;
  border: 0.5px solid #70707040;
  box-shadow: 2px 6px 13px -1px #00000099;
}

.Date-time-testimonial {
  font-size: 15px;
  color: #a8a8a8;
}

.monial-box-grid-chnge {
  margin: 0rem 1rem 1rem 1rem;
}

section.testinal-slider.testimonial-section .slick-dots {
  position: absolute;
  bottom: -50px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}

section.testinal-slider.testimonial-section
  .slick-dots
  li.slick-active
  button:before {
  color: var(--Slate);
}

.footer-serch input::placeholder {
  font-weight: 500;
}

section.testinal-slider.testimonial-section .slick-dots li button:before {
  font-family: "slick";
  font-size: 18px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: "•";
  text-align: center;
  opacity: 1.1;
  color: var(--Pale-Aqua);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

section.testinal-slider.testimonial-section
  .slick-dots
  li
  button:hover::before {
  color: #087bca;
  transition: all 0.42s ease;
}

/* home page style end */

/* footer */

/* footer styling */
.footer-first-grid {
  position: relative;
  display: grid;
  grid-gap: 2%;
  grid-template-columns: 15% 83%;
  min-height: 100px;
}

.footer-serch {
  top: 0;
  right: 0% !important;
}

.footer-logo img {
  max-width: 170px;
}

.vl {
  width: 100%;
  border: 1px solid #a69e9e3b;
}

.qa-btn {
  position: relative;
  text-align: start;
}

.footer-inner-third h4 {
  font-size: 21px;
  text-align: start;
  color: #fff;
}

.footer-inner-third {
  margin: 15px 0px 20px 0px;
}

.qa-btn a {
  position: relative;
  top: 35px;
  font-weight: 500;
  border-radius: 6px;
  color: var(--Slate) !important;
  background-color: var(--Pale-Aqua) !important;
  padding: 12px 32px;
}

.social {
  position: relative;
}

.social .inputBox {
  position: unset;
  width: 100%;
  box-shadow: 0px 9px 22px -9px #484848;
}

.fo-brdr {
  border: 2px solid #fff;
}

.footer {
  background: var(--Slate);
  background-size: 100% 100%;
  background-position: 100% 100%;
  background-repeat: no-repeat;
  padding: 50px 0px 0px 0px;
}

.footer-itms-grid {
  display: grid;
  grid-gap: 2%;
  grid-template-columns: 23.5% 23.5% 26.5% 20.5%;
  padding: 30px 0px;
  margin: 20px auto;
  position: relative;
}

.vl-fo {
  height: 100%;
  position: absolute;
  border-left: 2px solid #ffffff59;
  top: 0;
  right: 25px;
}

ul.links-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-top: 10px;
}

.footer-logo {
  display: flex;
  text-align: start;
  flex-direction: column;
  justify-content: center;
}

.footer-serch input {
  font-weight: 600;
}

ul.links-list li {
  list-style: none;
  line-height: 60px;
}

ul.links-list li a {
  font-size: 1.2rem;
  text-decoration: none;
  color: #fff;
  padding: 5px 10px;
  transition: all 0.4s ease-in-out;
  margin-left: -10px;
  font-weight: 400;
}

ul.links-list li a:hover {
  background-color: var(--Antique-White);
  color: var(--Slate);
}

ul.React-icn-parents {
  display: flex;
  gap: 40px;
  padding: 15px 0 0 0;
}

ul.React-icn-parents li a i {
  font-size: 23px;
  color: #fff;
}

.link {
  position: relative;
}

.link h4 {
  color: #033e65;
  font-size: 22px;
  text-align: left;
  margin-bottom: 5px;
  font-weight: 600;
  text-decoration: underline;
}

.address {
  display: flex;
  align-items: center;
  justify-content: center;
}

.address ul li {
  list-style: none;
  margin-bottom: 14px;
}

.address ul li a {
  color: #333;
  text-decoration: none;
  padding: 15px;
}

.address ul li a i {
  margin-right: 7px;
  color: #127fca;
  background-color: #ffffff;
  padding: 8px 8px;
  border-radius: 4px;
}

.address ul li span {
  border-radius: 15px;
  padding: 5px;
  color: #fff;
}

.address ul li span:hover {
  color: #ffffff;
  background-color: #033e65;
  transition: all 0.5s ease;
}

.copyright {
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  min-height: 70px;
  color: #fff;
}

.copyright span {
  position: relative;
  font-size: 20px;
}

.btn.loginbt a {
  padding: 16px 0px;
  width: 158px;
  display: flex;
  color: var(--Slate);
  background-color: var(--Pale-Aqua);
  font-size: 18px;
  font-weight: 600;
  border-radius: 7px;
  justify-content: center;
}

/* footer styling end */

/* PRODUCT PAGE */
.product-banner {
  background-color: var(--Antique-White);
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  height: 90vh;
  margin-top: 80px;
  display: flex;
  align-items: center;
}

.product-ban-txt h2 {
  display: inline;
  text-transform: capitalize;
  text-align: start;
  font-weight: 600;
  font-size: 71px;
  padding: 0px 20px;

  color: var(--Slate);
  border-radius: 16px;
}

.product-ban-txt p {
  color: #ffffff;
  font-weight: 500;
  font-size: 20px;
  width: 77%;
  line-height: 30px;
  padding: 15px 0px;
}

.inner-box-prodsmall a {
  margin-bottom: 10px !important;
  display: block;
}

.product-banner-box-parent {
  background-color: var(--white);
  border-radius: 16px;
  max-width: 80%;
  margin: 20px 0px;
  position: relative;
  border: 1px solid var(--Slate);
  z-index: 1;
}

.product-banner-box {
  padding: 20px 0;
}

.test-panel-top-txt h2 {
  font-weight: 600;
  font-size: 1.5rem;
  padding-bottom: 10px;
  text-align: center;
  color: var(--Slate);
}

.inner-prod-box {
  /* display: grid;
  grid-gap: 20px;
  grid-template-columns: 17% 17% 17% 17% 17%; */
  padding: 15px 40px;
  row-gap: 16px;
  justify-content: space-between;
}

.box-prod {
  width: 95%;
  margin: 0 auto;
  text-align: center;
  background-color: var(--Pale-Aqua);
  border-radius: 11px;
  padding: 10px 0px;
  border: 1px solid var(--Slate);
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.box-prod h3 {
  font-size: 16px;
  font-weight: 600;

  margin: 0;
  color: var(--Slate);
}

.prod-boxes-sec {
  padding: 40px 0;
}

.grid-product-boxes {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  padding: 0px 0 50px;
  justify-content: space-between;
}

.box-product-prnt {
  background-color: var(--Antique-White);
  min-height: 460px;
  width: 96%;
  border-radius: 15px;
  border: 1px solid #4f6367;
  margin-bottom: 35px !important;
  margin: 0 auto;
  overflow: hidden;
}

.box-product-inner {
  position: relative;
  max-width: 90%;
  height: 100%;
  border-radius: 30px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.img-product-box {
  /* text-align: center; */
  /* margin: 0px 60px; */
  /* padding-top: 40px; */
}

.content_product_bx {
  position: relative;
  text-align: center;
  background-color: var(--Antique-White);
}

.content_product_bx h5 {
  color: #087bca;
  font-weight: 600;
  font-size: 28px;
}

.label-seller {
  position: absolute;
  right: 0;
  display: flex;
  gap: 10px;
}

.label-seller h5 {
  font-size: 14px;
  text-align: center;
  min-height: 30px;
  border-radius: 9px;
  border: 2px solid var(--Slate);
  color: var(--Slate);
  background-color: var(--white);
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 7px;
}

.cart-inner-own-test {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.total-test img {
  padding: 2px 3px;
  border: 1px solid;
  width: 34px;
  height: 30px;
  border-radius: 5px;
  object-fit: contain;
  margin-right: 10px;
}

.content_product_bx p {
  /* width: 190px; */
  /* margin: 0 auto; */
  font-weight: 600;
  /* background-color: var(--Pale-Aqua); */
  color: var(--Slate);
  margin-top: 12px !important;
  margin-bottom: 6px !important;
  /* height: 40px; */
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: center; */
  /* box-shadow: 0px 1px 10px #0000009c; */
  text-align: left;
}

.view-more-btn {
  color: #fff;
  background-color: var(--Slate);
  padding: 10px 42px;
  border-radius: 6px;
}

.crd-btn-grid-prod {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: 15px;
}

.ad-to-crt {
  text-align: center;
}

.top-zero-absolute {
  top: 0 !important;
}

.ad-to-crt {
  font-size: 16px;
  border-radius: 50px;
  padding: 7px 26px;
  background-color: var(--Slate);
  color: #ffff;
  border: 2px solid var(--Slate);
  font-weight: 600;
  box-shadow: #fff 0px 0px 10px;
  transition: all 0.5s ease-in-out;
}

.lrn-more-btn:hover {
  background: var(--Slate);
  color: white;
  border-color: white;
}

.ad-to-crt:hover {
  background-color: transparent;
  color: var(--Slate);
  border-color: var(--Slate);
}

/* product style */

.lrn-more-btn {
  font-size: 16px;
  padding: 7px 26px;
  border-radius: 50px;
  background-color: transparent;
  color: var(--Slate);
  border: 2px solid var(--Slate);
  font-weight: 600;
  transition: all 0.5s ease-in-out;
}

.look-somethng {
  background-color: var(--Pale-Aqua);
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  padding: 140px 0;
  overflow: hidden;
}

.look-somethng::after {
  position: absolute;
  height: 100%;
  width: 28%;
  background-image: url("../images/Faqrightimg.png");
  background-repeat: no-repeat;
  background-size: 100%;
  bottom: -16%;
  left: -11%;
  animation: animName 50s linear infinite;
  opacity: 0.6;
}

.look-somethng::before {
  position: absolute;
  height: 100%;
  width: 22%;
  background-image: url("../images/Faqleftimg.png");
  background-repeat: no-repeat;
  background-size: 100%;
  top: -5%;
  right: -12%;
  animation: animName 50s linear infinite;
  transform: rotate(155deg);
}

.content-somthng,
.build-own-btn {
  text-align: center;
}

.healthsearch input::placeholder {
  color: #ffffff6b !important;
}

.content-somthng h2 {
  font-weight: 500;
  margin-bottom: 30px;
  color: var(--Slate);
  font-size: 50px;
}

.build-own-btn {
  padding: 20px 0;
}

.build-own-btn a {
  background-color: var(--Slate);
  color: #fff;
  padding: 10px 60px;
  border-radius: 15px;
  font-size: 20px;
  font-weight: 600;
}

.product-ban-txt {
  margin-bottom: 39px;
  position: relative;
  z-index: 1;
}

.buildown a {
  color: var(--white);
  background-color: var(--Slate);
  padding: 16px 42px;
  border-radius: 10px;
  margin-right: 48px;
  font-weight: 600;
}

.buildown {
  margin-top: 60px;
}

.img-product-box h5 {
  color: var(--Slate);
  font-weight: 600;
  font-size: 24px;
  height: 60px;
  font-weight: 600;
}

.total-test {
  display: flex;
  /* justify-content: space-between; */
  margin-bottom: 30px;
  margin-top: 30px;
  flex-direction: column;
  text-align: left;
  gap: 20px;
}

.total-test span {
  font-size: 16px;
  color: var(--Slate);
}

.total-test span:last-child {
  font-weight: 600;
}

.total-test span:first-child {
  position: relative;
}

.test-pro-info span {
  font-size: 16px;
  color: var(--Slate);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 3;
  height: 41px;
}

.box-prod:hover {
  background-color: var(--Antique-White);
  transition: all 0.4s ease;
  border-color: var(--Antique-White);
}

.box-prod-active {
  background-color: var(--Antique-White);
  transition: all 0.4s ease;
  border-color: var(--Antique-White);
}

.test-pro-info {
  padding-bottom: 10px;

  overflow: hidden;
  text-overflow: ellipsis;
}

.sign-btn a {
  padding: 12px 0px;
  width: 158px;
  display: flex;
  color: #fff;
  font-size: 18px;
  border: 1px solid #e6f4ff;
  font-weight: 600;
  border-radius: 7px;
  justify-content: center;
}

.centerBox2 .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: #087bca !important;
}

.centerBox2 .owl-dots .owl-dot span {
  background: #e5f4ff !important;
  width: 12px !important;
  height: 12px !important;
}

.owl-carousel.owl-theme.centerBox2.owl-loaded.owl-drag {
  width: 71% !important;
  transform: translateX(32%);
}

.centerBox2 .owl-nav button.owl-next {
  display: none;
}

.centerBox2 .owl-nav .owl-prev:after {
  content: "\f104";
  font-family: FontAwesome;
  font-size: 24px;
  color: #fff;
  position: absolute;
  top: -41px;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: #087bca;
  border-radius: 100px;
}

.centerBox2 .owl-nav .owl-prev span {
  position: absolute;
  top: -40px;
}

.centerBox2 .owl-dots {
  transform: translate(-165px, 28px);
}

.centerBox2 .owl-nav .owl-prev {
  box-shadow: none;
}

.centerBox2 .owl-nav button.owl-prev {
  position: absolute;
  background: none;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
  left: -95px;
}

.sign-btn {
  margin: 16px 0px;
}

.address {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.grid-col2_divider {
  display: grid;
  margin-top: 10px;
}

.foot-info p {
  text-align: center;
  color: white;
  padding-bottom: 20px;
  font-size: 1.1rem;
  font-weight: 300;
  font-family: "Poppins";
}

/* product page styling end*/
/* cart box  start*/
.cartbox {
  position: fixed;
  width: 420px;
  background: #ffffff;
  box-shadow: 10px 10px 10px #00000096;
  top: 5.2rem;
  left: initial;
  padding: 10px 20px;
  z-index: 99;
  right: 0;
  height: -moz-fit-content;
  height: fit-content;
}
.putnum-parent {
  max-height: 336px;
  overflow-y: scroll;
  margin-bottom: 12px;
}

.putnum {
  display: flex;
  justify-content: space-between;
  padding-right: 16px;
}

.putnum p {
  font-size: 13px;
  font-family: "Poppins";
  font-weight: 600;
  color: var(--Slate);
}

.total-am-dollar {
  width: 100%;
}

.total-am-dollar p {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

/* product style */
.v-counter {
  display: block;
  position: relative;
  width: 110px;
}

input.count {
  width: 110px;
  background: var(--dark-Pale-Aqua);
  padding: 0 40px;
  color: white;
  height: 30px;
  border: none;
  border-radius: 5px;
  text-align: center;
  font-size: 13px;
}

.minusBtn {
  position: absolute;
  left: 6px;
  font-size: 21px;
  color: white;
  background: none;
  border: none;
  top: 1px;
}

.plusBtn {
  position: absolute;
  right: 4px;
  font-size: 18px;
  color: white;
  background: none;
  border: none;
  top: 3px;
}

.minusBtn::placeholder {
  color: white;
}

.putnum p span {
  font-size: 15px;
}

.putnum {
  padding-top: 10px;
  padding-bottom: 9px;
  display: flex;
  align-items: center;
}

.putnum a {
  background: var(--Slate);
  padding: 10px 30px;
  border-radius: 22px;
  font-size: 18px;
  font-weight: 500;
  color: #ffff;
  font-family: "Poppins";
}

.cartbox span img {
  position: absolute;
  top: -23px;
  right: 67px;
  z-index: -1;
}

.main-menu > ul > li > a:hover {
  background: var(--Antique-White);
  color: var(--Slate) !important;
}

.main-menu ul .active > a {
  color: var(--Slate) !important;
  background: var(--Antique-White);
}

.overlay {
  position: relative;
}

.overlay::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
}

body.overlay a.back-trans.border-btn {
  border-color: white;
}

body.overlay a.back-trans.border-btn i {
  color: white;
}

/* cart box  end*/
/*............... LOGIN STYLING................. */
section.login-sect.Login-one-screen {
  padding: 0;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

section.login-sect.Login-one-screen p {
  margin: 0;
  width: 100%;
  text-align: left;
  color: #b2b2b2 !important;
}

section.login-sect.Login-one-screen .parent-box {
  transform: translate(10px, 10px);
}

section.login-sect.Login-one-screen .log-txt {
  margin-bottom: 1rem;
}

.login-sect {
  background: var(--Antique-White);
  padding: 240px 0px 270px 0;
  position: relative;
  overflow: hidden;
}

.log-txt {
  text-align: center;
  display: flex;
  justify-content: center;
}

.log-txt a h1 {
  font-size: 30px;
  color: var(--Slate);
  padding-right: 32px;
  font-weight: 600;
}

.log-txt a .reg {
  color: var(--Slate);
  padding-left: 50px;
}

hr.under-line {
  width: 15%;
  position: relative;
  left: 31%;
  border: 2px solid #fff;
}

.parent-form-box {
  background: #fff;
  border: 1px solid #fff;
  box-shadow: 0 0 0 10px var(--Slate);
  border-radius: 47px;
  width: 850px;
  padding: 20px 60px 20px 45px;
  position: relative;
}

.form-login-txt {
  padding: 20px;
  padding-left: 0 !important;
}

.form-login-txt h4 {
  text-align: start;
  font-size: 34px;
  font-weight: 300;
}

.form-login-txt h4 i {
  color: var(--Slate);
  font-weight: 200;
  font-size: 51px;
}

.form-login-txt h1 {
  line-height: 31px;
  font-size: 51px;
  font-weight: 700;
  color: var(--Slate);
}

.parent-box {
  display: flex;
  justify-content: center;
  transform: translateY(-205px);
  z-index: 2;
  position: relative;
}

.participates-check {
  padding: 0 0px 18px;
  position: relative;
  right: 16px;
}

.participates-check input {
  margin: 0 5px 0 17px;
}

.register.align {
  padding: 0 0 0 5px;
}

label.partici-character {
  font-size: 13px;
  font-weight: 300;
  color: #959595;
}

.input-form input[type="email"] {
  width: 84%;
  height: 55px;
  border-radius: 5px;
  outline: none;
  border: 1px solid #c9c1c1;
  padding: 0 0 0 12px;
  margin: 9px 0 16px;
}

.input-form label.email {
  color: #4e4d4d;
  padding-bottom: 12px;
  display: block;
  font-size: 22px;
  font-weight: 300;
  padding-bottom: 0;
}

.input-form input {
  width: 84%;
  height: 55px;
  border-radius: 5px;
  outline: none;
  border: 1px solid #c9c1c1;
  padding: 0 0 0 12px;
}

.input-form label.password {
  color: #4e4d4d;
  padding: 0 176px 0 0;
  font-size: 22px;
  font-weight: 300;
}

.input-form label.sec-pass {
  color: #959595;
  font-size: 22px;
  font-weight: 300;
}

.form-txt {
  display: flex;
  align-items: center;
  padding: 15px 2px 20px;
}

.form-txt input {
  cursor: pointer;
  accent-color: var(--Slate);
  margin: 0 8px 0 0px;
}

.form-txt label {
  color: #4e4d4d;
  font-size: 22px;
  font-weight: 300;
  margin: 0;
}

.input-form label.sec-pass a {
  color: #b2b2b2 !important;
}

.form-btn {
  padding: 0 74px 44px 0px;
}

.form-btn input {
  border: 1px solid #087bca;
  background-color: #087bca;
  padding: 8px 235px;
  border-radius: 6px;
  color: #fff;
  font-size: 24px;
  font-weight: 600;
}

.form-btn button {
  border: 2px solid var(--Slate);
  padding: 8px 235px;
  border-radius: 6px;
  color: var(--Slate);
  font-size: 24px;
  font-weight: 600;
  width: 94%;
  display: block;
  text-align: center;
  background-color: transparent;
}

.form-login-txt-parent {
  position: relative;
}

.form-box-icn {
  position: absolute;
  top: 0rem;
  right: 0rem;
}

.form-box-icn img {
  max-width: 291px;
}

.form-btn button {
  border: 2px solid var(--Slate);

  padding: 8px 235px;
  border-radius: 6px;
  color: var(--Slate);
  font-size: 24px;
  font-weight: 600;
  width: 94%;
  background-color: transparent;
}

.log-txt a {
  position: relative;
}

.log-txt .login-click:before {
  position: absolute;
  left: 2px;
  width: 73%;
  height: 5px;
  background: var(--Slate);
  content: "";
  bottom: 0;
  border-radius: 22px;
}

.pass-forget {
  width: 84%;
  justify-content: space-between;
  display: flex;
}

/*............... LOGIN STYLING end................. */

/*............... REGISTER STYLING start................. */
.pass-requir {
  background: #4f6367b0;
  padding: 9px 10px;
  border-radius: 10px;
  position: absolute;
  width: 100%;
  z-index: 9;
  display: none;
}

.pass-requir h5 {
  font-size: 1rem;
  font-weight: 600;
  color: #fbf7ef;
}

.pass-requir ul li {
  font-size: 13px;
  color: white;
  margin-bottom: 5px;
}
.pass-requir ul li i.fa-solid.fa-check {
  color: green;
}
input.pas:focus-visible ~ div {
  display: block;
}

.reg-float-box-parent {
  position: absolute;
  top: 340px;
  right: -92px;
  background-color: #087bca;
  border-radius: 20px;
  width: 410px;
  padding: 20px 40px 45px 40px;
}

.heading-float-box {
  padding: 10px 0;
}

.cont-float-bx-grid h3 {
  text-align: start;
  font-size: 18px;
}

.inner-float-box {
  background-color: #fff;
  min-width: 280px;
  padding: 20px 30px 20px 30px;
  border-radius: 20px;
}

.heading-float-box h2 {
  font-size: 24px;
  color: #fff;
  font-weight: 300;
  text-align: end;
}

.grid-float-bx {
  display: grid;
  grid-gap: 2%;
  grid-template-columns: 70% 28%;
}

.grid-float-box-two {
  padding-top: 40px;
  display: grid;
  grid-gap: 2%;
  grid-template-columns: 28% 70%;
}

.cont-float-bx-flex-end {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.cont-float-bx-flex-end a {
  font-size: 14px;
  background-color: #087bca;
  color: #fff;
  padding: 10px 15px;
  border-radius: 13px;
}

.cont-float-bx-flex-end a span {
  font-size: 12px;
  padding-left: 11px;
}

hr.regis {
  left: 49%;
  width: 17%;
}

.form-box-register {
  width: 100%;
  background: #fff;

  position: relative;
  z-index: 2;
  /* transform: translateY(-169px); */
}

/* .register-form-box-parent {
  display: flex;
  justify-content: center;
} */

.form-box-side1 {
  padding: 40px 0px 40px 0px;
  width: 90%;
  margin: 0 auto;
}

.form-box-side-txt h1 {
  font-size: 30px;
  color: var(--Slate);
  font-weight: 600;
  text-align: center;
}

.form-align-parent .register.align {
  padding-right: 30px;
  color: #6d6d6d;
}

input.pas.date-in {
  padding: 0 9px;
  font-size: 15px;
  font-weight: 500;
  color: #959595;
}

.form-align-parent h3 {
  color: #959595 !important;
  font-size: 15px;
  font-weight: 500;
  padding: 6px 0 16px;
  text-align: center;
  font-weight: 400;
}

.form-align-parent .Name {
  height: 42px;
  padding: 0 0 0 14px;
  width: 41.5%;
  margin-right: 70px;
  outline: none;
}

.form-align-parent .lname {
  height: 42px;
  padding: 0 0 0 14px;
  width: 41.5%;
  outline: none;
  margin-bottom: 30px;
}

.form-align-parent .email {
  width: 100%;
  height: 40px;
  padding: 0 0 0 14px;
  margin-bottom: 15px;
  outline: none;
  font-weight: 500;

  font-size: 15px;
}

.inputs-inner label {
  font-weight: 400;
  font-size: 15px;
  font-family: "Poppins";
  color: #b2afaf;
}

.form-align-parent .pas {
  height: 40px;
  padding: 0 40px 0 14px;
  width: 100%;
  margin-right: 70px;
  outline: none;
  color: #959595;
  margin-bottom: 15px;
  border: 1px solid #cbcbcb;
}

.form-align-parent .cnfrm-pas {
  height: 40px;
  padding: 0 0 0 14px;
  width: 100%;
  outline: none;
  margin-bottom: 15px;
}

.div-inputs {
  display: flex;
  justify-content: flex-start;
  margin: 5px 0px;
  color: #797f82;
}

.grid-input-register {
  display: grid;
  grid-gap: 10%;
  grid-template-columns: 45% 45%;
}

.grid-input-register input {
  border: 1px solid #cbcbcb;
  border-radius: 4px;
}

.form-align-parent input::placeholder {
  font-weight: 500;
  color: #959595;
  font-size: 15px;
}

.grid-input-register .email {
  border: 1px solid #cbcbcb;
  border-radius: 4px;
}

input::placeholder {
  font-family: "Poppins", sans-serif !important;
}

.inputs-inner {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
}

.gender {
  margin: 0px 0px 15px;
}

.gender p {
  font-size: 15px !important;
  color: #b2afaf;
  font-weight: 400;
}

.form-align-parent .email {
  border: 1px solid #cbcbcb;
  color: #959595;
}

.form-align-parent input[type="text"],
.form-align-parent input.password {
  border: 1px solid #cbcbcb;
  border-radius: 4px;
  width: 100%;
  height: 40px;
  padding: 0 40px 0 14px;
  margin-bottom: 15px;
  outline: none;
  font-weight: 500;
  font-size: 15px;
  color: #959595;
}

.form-align-parent h3 span a {
  color: var(--Slate);
}

.form-align-parent input:hover {
  /* background-color: var(--Pale-Aqua);
  transition: all 0.4s ease; */
}

p.bank-det-head a {
  color: var(--Slate);
}

.form-align-parent .inpt-submit {
  background-color: var(--Slate);
  border-radius: 4px;
  padding: 10px 30px;
  color: #fff;
  font-size: 19px;
  font-weight: 700;
}

.radio-check-direct input {
  accent-color: black;
}

.inputs-inner input {
  accent-color: black;
}

.cr-acc {
  background-color: var(--Slate);
  border-radius: 4px;
  padding: 10px 30px;
  color: #fff !important;
  font-size: 19px;
  font-weight: 700;
}

.form-box-side2 {
  position: relative;
}

.img-sheild img {
  position: absolute;
  max-width: 320px;
  top: -67px;
  right: -57px;
}

.radio-check-direct {
  text-align: center;
}

h1.mean-title {
  text-align: center;
  font-size: 45px;
  font-weight: 600;
  color: var(--Slate);
}

.how.did {
  /* background: white; */
  border-radius: 30px;
  /* box-shadow: -1px -1px 50px -19px #0000006e; */
  padding: 0;
  margin-bottom: 15px;
  /* border: 1px solid #a6a4a4; */
}

.how.did input {
  border-radius: 10px;
  background: #f6f6f6;
  margin-bottom: 18px;
  color: black;
}

.how.did input::placeholder {
  font-size: 25px;
  color: black;
}

.detail-page-test p {
  font-size: 13px;
}

.how.did h1 {
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 10px;
}

.how.did button.cr-acc {
  width: 85%;
  margin-bottom: 40px;
}

.how.did input:focus {
  background-color: #bbdcf4;
  border: 1px solid var(--Slate);
}

.form-align-parent input:focus {
  background-color: #b8d9d957;
  border: 1px solid var(--Slate);
}

.disc-box-chk span {
  font-size: 15px;
  color: #b2afaf;
  font-weight: 400;
}

h5.bank-det-head {
  font-size: 20px;
  color: #b2afaf;
  font-weight: 400;
  margin-top: 30px;
  margin-bottom: 15px;
}

.disc-box-chk {
  display: flex;
  gap: 11px;
}

section.login-sect p {
  font-size: 1.1rem;
  font-weight: 400;
  color: var(--Slate);
  width: 39%;
  margin: 0 auto;
  text-align: center;
  padding-bottom: 20px;
}

.disc-box-chk span input {
  padding: 10px 10px;
  font-size: 20px !important;
}

/*............... REGISTER end STYLING................. */

/* ---------- Express Checkout PAGE CSS ---------- */
.express-heading {
  margin: 40px 0px 0px;
  padding: 10px 0px 0px;
}

.main.express-checkout.payments-pg .express-heading {
  margin: 0;
}

.express-heading h2 {
  text-align: start;
  color: #959595;
  font-size: 32px;
  font-weight: 500;
}

.express-heading p {
  color: var(--Slate);
  font-size: 13px;
}

.details-cart-grid {
  display: grid;
  grid-gap: 2%;
  grid-template-columns: 49% 49%;
}

.details-pathlogy {
  background-color: #f5fafd;
  padding: 50px;
  width: 90%;

  height: max-content;
}

/* age radio input styles */
.age-inpt-radio {
  display: inline-flex;
  margin: 13px 0px;
}

.age-inpt {
  display: grid;
  grid-gap: 7%;
  grid-template-columns: 49% 49%;
  align-items: end;
}

.age-inpt-radio .option {
  /* background: #fff; */
  height: 100%;
  width: 50px;
  display: flex;
  margin-right: 10px;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 5px;
  cursor: pointer;
  padding: 7px 10px;
  border: 2px solid lightgrey;
  transition: all 0.3s ease;
  font-weight: 500;
}

.age-inpt-radio .option .dot {
  height: 20px;
  width: 20px;
  background: #d9d9d9;
  border-radius: 50%;
  position: relative;
}

input.radio-gender-express {
  display: none;
}

#option-1:checked:checked ~ .option-1,
#option-2:checked:checked ~ .option-2 {
  background: var(--Pale-Aqua);
}

.age-inpt-radio .option span {
  font-size: 13px;
  color: #808080;
}

#option-1:checked:checked ~ .option-1 span,
#option-2:checked:checked ~ .option-2 span {
  color: #fff;
}

/* age radio input styles */
.name-email-inpt input::placeholder,
.address-inpt input::placeholder {
  color: #c5c5c5 !important;
  font-size: 13px;
}

.textbox-n::placeholder {
  color: #c5c5c5;
}

.name-email-inpt input,
.address-inpt input {
  width: 100%;
  padding: 13px;
  outline: none;
  color: #c5c5c5;
  background: transparent;
  font-size: 13px;
  margin: 13px 0px;
  border-radius: 7px;
  border: 1px solid #c5c5c5;
}

.define-pathlogy-heading h3 {
  color: #959595;
  font-size: 20px;
  transform: translateY(-10px);
  font-weight: 400;
  text-align: start;
}

.frst-checkbox-path {
  padding: 13px;
  outline: none;
  color: #c5c5c5;
  background: transparent;
  margin: 13px 0px;
  border-radius: 7px;
  border: 1px solid #c5c5c5;
}

.frst-checkbox-path input {
  height: 18px;
  width: 18px;
  transform: translateY(3px);
  margin-right: 11px;
}

.frst-checkbox-path label {
  font-size: 13px;
  font-weight: 400;
  color: #959595;
}

.name-email-inpt input::placeholder,
.address-inpt input::placeholder {
  font-size: 13px;
  font-weight: 400;
  color: #959595 !important;
}

.age-inpt-1 span {
  display: block;
  font-size: 13px;
  color: var(--Slate);
}

.age-inpt p {
  margin-bottom: 0;
  font-size: 13px;
  margin-top: 20px;
  color: #959595;
}

.age-inpt .textbox-n {
  width: 100%;
  padding: 13px;
  color: #c5c5c5;
  outline: none;
  background: transparent;
  font-size: 16px;
  margin: 13px 0px;
  border-radius: 7px;
  border: 1px solid #c5c5c5;
}

.cnfrm-order-main {
  /* background-color: var(--dark-Pale-Aqua); */
  /* padding: 40px; */
  width: 96%;
  border-radius: 35px;
}

.cnfrm-heading h2 {
  text-align: start;
  font-size: 20px;
  color: var(--dark-Pale-Aqua);
  font-weight: 500;
  text-align: center;
}

.order {
  background: #fff;
  padding: 15px 30px 15px;
  border-radius: 30px;
  box-shadow: 0 0 4px black;
  width: 70%;
  margin: 0 auto;
}

.hrmone-qty-price {
  width: 100%;
  grid-gap: 2%;
  color: #1282cd;
  grid-template-columns: 53% 18% 25%;
  margin-bottom: 20px;
}

.qty-hrmone h1,
.price-hrmone h1,
.name-hrmone h1 {
  font-size: 22px;
  font-weight: 600;
}

.sub-total {
  color: #1282cd;
  padding: 2px 0px;
}

.total-order {
  color: #959595;
  font-size: 13px;
  font-weight: 600;
  margin-right: 10px;
}

span.total-price {
  font-size: 13px;
  font-weight: 700;
  color: var(--Slate);
}

.add-cmnts input {
  height: 18px;
  width: 18px;
  transform: translateY(3px);
}

.add-cmnts {
  padding-top: 20px;
}

.use-copn {
  padding-top: 8px;
}

p.bank-det-head {
  font-size: 20px;
  font-weight: 400;
  color: #b2afaf;
  margin-bottom: 20px;
}

.add-cmnts label {
  color: #959595;
  font-size: 16px;
  font-weight: 400;
  margin-left: 10px;
}

.by-cmplting {
  /* padding: 20px 0px; */
  font-size: 13px;
}

.by-cmplting span {
  color: var(--Slate);
}

.pay-meth {
  color: #dbdbdb;
}

.pay-meth h1 {
  font-size: 13px;
  padding: 0px 0;
  font-weight: 400;
  color: #959595;
}

.pay-meth {
  position: relative;
}

.pay-meth .first-check {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 20px;
  left: -15px;
  position: absolute;
  bottom: 1px;
  width: 20px;
}

.pay-meth .first-check:after {
  border: 6px solid var(--Slate);
  content: "";
  height: 5px;
  left: 3px;
  border-radius: 50%;
  opacity: 0;
  position: absolute;
  top: 3px;
  width: 12px;
}

.pay-meth input {
  /* visibility: hidden; */
}

.pay-meth.ab input {
  visibility: hidden;
}

.pay-meth input:checked + label {
  border-color: var(--Slate);
}

.pay-meth input:checked + label:after {
  opacity: 1;
}

.order-btn a {
  display: table;
  width: 100%;
  padding: 15px;
  border-radius: 30px;
  margin: 30px 0px 0px;
  font-size: 20px;
  cursor: pointer;
  color: var(--Slate);
  outline: none;
  border: none;
  background-color: var(--Pale-Aqua);
  border: 1px solid var(--Slate);
  text-align: center;
  font-weight: 500;
}

.pay-meth label {
  color: #959595;
  font-size: 13px;
  font-weight: 400;
  margin-left: 15px;
}

.return-dash a {
  color: #1282cd;
  font-size: 20px;
  display: table;
  margin: 0 auto;
}

.return-dash {
  padding: 50px 0px;
}

p.signed-email {
  color: #787f82;
}

p.signed-email a {
  color: #787f82;
  border-bottom: 3px solid #087bca;
}

.age-inpt .textbox-n::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #959595;
}

.age-date p {
  color: #959595;
  font-size: 13px;
  font-weight: 400;
  margin-left: 0px;
}

.age-date input::placeholder {
  color: #959595;
  font-size: 16px;
  font-weight: 400;
  margin-left: 0px;
}

.age-date input {
  width: 100%;
  border: 1px solid var(--Slate);
  height: 50px;
  border-radius: 10px;
  padding: 0 15px;
}

.age-date {
  margin-top: 20px;
}

.by-cmplting p {
  color: #959595;
  font-size: 13px;
  margin-top: 5px;
}

.details-cart-grid .view-more-btn {
  /* justify-content: start; */
  /* top: 0; */
  border-radius: 50px;
  /* position: relative; */
  /* left: -40px; */
  margin-top: 20px;
  display: inline-block;
  color: white;
}

.details-cart-grid .view-more-btn a {
  padding: 8px 60px;
}

.fade.modal.fade.show {
  transition: opacity 0.15s linear !important;
  opacity: 1;
}

/* ---------- Express Checkout PAGE CSS end ---------- */

/* thanks modal  */

.fade.thanksmodal.modal.show .modal-dialog {
  top: 50%;
  transform: translateY(-50%);
  width: 55%;
}

.huz-modal.modal.show .modal-dialog {
  top: 50%;
  transform: translateY(-50%);
  max-width: 38% !important;
}

.huz-modal.modal.show .modal-dialog .modal-content {
  padding: 65px 62px !important;
  border-radius: 22px !important;
}

.huz-cncl-mark {
  color: #fff;
  padding: 6px 10px;
  border-radius: 50%;
  position: absolute;
  right: -3rem;
  top: -3rem;
}

.fade.thanksmodal.modal.show .modal-header {
  display: none;
}

.modal-body {
  text-align: center;
}

.fade.thanksmodal.modal.show h1 {
  font-size: 50px;
  font-family: "Poppins";
  font-weight: 600;
  color: #087bca;
}

.fade.thanksmodal.modal.show p {
  font-size: 23px;
  font-family: "Poppins";
  color: #087bca;
  font-weight: 400;
  padding-bottom: 50px;
  padding-top: 13px;
}

.fade.thanksmodal.modal.show a.cr-acc {
  border-radius: 17px !important;
  font-weight: 400;
}

.fade.thanksmodal.modal.show .modal-content {
  border-radius: 30px;
  padding: 40px 0;
}

.fade.thanksmodal.modal.show .modal-body img {
  width: 230px;
}

.fade.modal-backdrop.show {
  opacity: 0.7;
}

section.form-sect-log.verify .form-box-icn {
  top: 10%;
  right: 2%;
}

section.form-sect-log.verify .form-login-txt p {
  font-size: 22px;
  margin-top: 20px;
  color: #abb5bc;
}

section.form-sect-log.verify .form-txt {
  justify-content: space-between;
  align-items: center;
}

/* section.form-sect-log.verify .form-txt a:last-child {
  display: flex;
  background: var(--Slate);
  padding: 8px 35px;
  border-radius: 10px;
  height: 55px;
  font-size: 20px;
  font-weight: 300;
  color: #efeded;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
} */
section.form-sect-log.verify h1 {
  font-size: 40px;
  margin-top: 10px;
}

section.form-sect-log.verify.verificode :where(.vi__character) {
  background: transparent;
  border-radius: 6px;
}

section.form-sect-log.verify.verificode .input-form {
  padding-bottom: 40px;
}

section.form-sect-log.verify.verificode .input-form label.email {
  margin-bottom: 20px;
}

section.form-sect-log.verify.verificode :where(.vi__container) {
  display: flex;
  gap: 12px;
  height: 60px;
  width: 330px;
}

body::-webkit-scrollbar {
  width: 0.5em;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: var(--dark-Pale-Aqua);
  outline: 1px solid slategrey;
}

.putnum-parent::-webkit-scrollbar {
  width: 0.5em;
}

.putnum-parent::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.putnum-parent::-webkit-scrollbar-thumb {
  background-color: var(--dark-Pale-Aqua);
  outline: 1px solid slategrey;
}

.sidebaradmin::-webkit-scrollbar {
  width: 0.5em;
  border-radius: 10px;
}

.sidebaradmin::-webkit-scrollbar-thumb {
  background-color: white;
  outline: 1px solid slategrey;
  border-radius: 10px;
}

.form-align-parent select {
  border: 1px solid #cbcbcb;
  border-radius: 4px;
  width: 100%;
  height: 60px;
  padding: 0 0 0 6px;
  margin-bottom: 30px;
  outline: none;
  font-weight: 500;
  color: #959595;
  font-size: 15px;
}

.validation-wrap {
  position: relative;
}

span.red-alert {
  position: absolute;
  bottom: 9px;
  left: 0;
  font-size: 14px;
  color: red;
}

.how.did span.red-alert {
  bottom: 17px !important;
  left: 40px !important;
}

.sidebaradmin::-webkit-scrollbar {
  width: 0.3em;
  border-radius: 10px;
}

.sidebaradmin::-webkit-scrollbar-thumb {
  background-color: white;
  outline: 1px solid slategrey;
  border-radius: 10px;
}

/*  */
input#noon {
  border-radius: 10px;
  background: #f6f6f6;
  margin-bottom: 18px;
  color: black;
  width: 100%;
  height: 50px;
  background: red;
  border: 1px solid black;
  opacity: 0;
}

.sidebaradmin::-webkit-scrollbar {
  width: 0.3em;
  border-radius: 10px;
}

.sidebaradmin::-webkit-scrollbar-thumb {
  background-color: white;
  outline: 1px solid slategrey;
  border-radius: 10px;
}

/*  */

.how.did label {
  width: 100%;
  border: 1px solid #cbcbcb;
  border-radius: 4px;
  width: 100%;
  height: 40px;
  padding: 0 0 0 14px;
  margin-bottom: 30px;
  outline: none;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 13px;
  font-family: "Poppins";
  color: #797f82;
  border-radius: 10px;
  background: #f6f6f6;
  margin-bottom: 10px;
  color: black;
}

.how.did .btn-outline-primary {
  --bs-btn-active-bg: var(--Pale-Aqua);
  --bs-btn-active-color: initial;
}

.validation-wrap.gendr-validate span.red-alert {
  bottom: -18px;
}

.logoutmodal.delet-modale.Alert-message.modal.show .modal-content {
  background: #e8e8e830;
}

/* baseline page */
section.innerbanner {
  background-color: var(--Antique-White);
  height: 70vh;
  overflow: hidden;
  margin-top: 82px;
}

.innerbannercontent h1 {
  font-size: 70px;
  color: var(--Slate);
  font-weight: 500;
}

.innerbannercontent h4 {
  font-size: 59px;
  color: var(--Slate);
  font-weight: 600;
  margin: 0;
}

.innerbannercontent p {
  font-size: 1.1rem;
  font-weight: 400;
  color: var(--Slate);
}

.innerbannercontent a {
  background: var(--Pale-Aqua);
  padding: 10px 40px;
  border-radius: 30px;
  border: 1px solid var(--Slate);
  font-size: 19px;
  font-weight: 600;
  color: var(--Slate);
  margin-top: 20px;
  display: inline-block;
}

.innerbannercontent button {
  background: var(--Pale-Aqua);
  padding: 10px 40px;
  border-radius: 30px;
  font-size: 19px;
  font-weight: 600;
  color: var(--Slate);
  margin-top: 20px;
  display: inline-block;
}

.innerbanner-img {
  text-align: center;
}

section.innerbanner .row {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 9;
}

section.productinnersec2 {
  padding: 30px 0;
}

section.productinnersec2 h2 {
  font-size: 50px;
  color: var(--Slate);
  font-weight: 500;
}

section.productinnersec2 p {
  font-size: 20px;
  font-weight: 400;
  color: var(--Slate);
}

section.baseline-sec {
  padding: 70px 0;
  position: relative;
  background: #f2f2f2;
  overflow: hidden;
}

section.baseline-sec span.circle-left {
  top: 50%;
  transform: translateY(-50%);
}

section.baseline-sec span.circle-right {
  bottom: -30%;
}

.baselinecontent h1 {
  font-size: 50px;
  color: var(--Slate);
  font-weight: 500;
  text-transform: capitalize;
}

.baselinecontent p {
  font-size: 24px;
  font-weight: 400;
  line-height: 42px;
  color: #959595;
}

.baseline-img img {
  width: 92%;
}

.inner-grid-txt-left {
  display: flex;
  justify-content: space-between;
}

section.baseline-sec .row {
  width: 90%;
  margin: 0 auto;
  position: relative;
  z-index: 9;
}

.corporation-content {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  background: rgb(79, 99, 103);
  background: linear-gradient(to right, #4f6367, #849ea0, #a6b4b5);
}

.corporation-img {
  width: 50%;
}
span.female-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #4f6367;
  border-radius: 50%;
  height: 150px;
  width: 150px;
}
.corporation-img img {
  width: 100%;
  border-radius: 10px;
}

.corporation-content h4 {
  font-size: 24px;
  font-weight: 600;
  color: var(--white);
}

.enquire-contetnt {
  text-align: center;
}

.enquire-contetnt p {
  font-size: 24px;
  font-weight: 400;
  color: #333333;
  width: 70%;
  margin: 0 auto;
  padding-bottom: 30px !important;
}

.enquire-contetnt ul li {
  width: 600px;
  font-size: 20px;
  margin: 0 auto;
  list-style: disc;
  color: var(--Slate);
  text-align: left;
}

.enquire-contetnt ul {
  margin-bottom: 30px;
}

.affilatecon h5 {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  color: var(--white);
  text-align: center;
  font-size: 60px;
  font-weight: 600;
}

.enquire-btn {
  background: var(--Slate);
  padding: 10px 40px;
  border-radius: 12px;
  font-size: 20px;
  font-weight: 600;
  color: #ffff;
  font-family: "Poppins";
}

.affilatecon img {
  width: 100%;
  margin-bottom: 30px;
  /* opacity: 0.6; */
  filter: grayscale(0.4);
  border-radius: 12px;
}

.affilatecon {
  width: 90%;
  position: relative;
  margin: 0 auto;
}

section.Affiliatesec {
  padding: 60px 0px;
  background-color: var(--Antique-White);
}

.praFrontcon {
  background: rgb(79, 99, 103);
  background: linear-gradient(to right, #4f6367, #849ea0, #a6b4b5);
  padding: 10px 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.box-Solution {
  background: var(--Antique-White);
  padding: 20px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px solid var(--Slate);
  height: 270px;
  box-shadow: 0 0 11px 6px #0000001f;
}

.solution-title {
  font-size: 42px;
  color: var(--Slate);
  font-weight: 500;
  text-align: center;
}

.box-Solution p {
  font-size: 20px;
  font-weight: 600;
  color: #363636;
  text-align: center;
  margin-top: 20px;
}

.praFrontcon h2 {
  color: white;
  font-size: 50px;
  font-weight: 600;
}

.praFrontcon h5 {
  color: white;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 20px;
}

.praFrontcon p {
  color: white;
  font-size: 20px;
}

/* about us start */

.our-value-heading {
  text-align: center;
}

.healthsearch input::placeholder {
  color: #ffffff6b;
}

.our-value-heading h1 {
  font-weight: 500;
  font-size: 50px;

  color: var(--Pale-Aqua);
  text-transform: uppercase;
  display: table;
  border-radius: 20px;
  margin: 0 auto;
  text-align: center;
}

.our-value-sec {
  background-image: url(../images/sec1bg.png);
  padding: 100px 0;
}

.box-inner-img img {
  width: 105px;
}

.our-value-heading p {
  color: var(--Antique-White);
  padding: 10px 0px;
  font-size: 40px;
}

.our-value-carts {
  display: grid;
  grid-gap: 3%;
  grid-template-columns: 49% 49%;
  align-items: center;
}

.box-inner-img {
  margin: 0 auto;
}

.our-box {
  display: grid;
  grid-gap: 2%;
  grid-template-columns: 20% 78%;
  align-items: center;
  width: 97%;
  margin: 20px auto;
  background-color: white;
  padding: 35px 12px;
  border-radius: 20px;
  transition: 0.5s ease-in-out;
}

.our-box:hover {
  background: var(--Pale-Aqua);
  transition: 0.5s ease-in-out;
}

.box-txt h1 {
  font-size: 30px;
  color: var(--Slate);
}

.box-txt p {
  font-size: 14px;
  color: black;
  width: 77%;
}

.book-test a {
  background-color: var(--Pale-Aqua);
  padding: 10px 55px;
  font-size: 20px;
  font-weight: 600;
  color: var(--Slate);
  border-radius: 10px;
  margin: 10px;
  box-shadow: 2px 6px 13px 2px #3333;
}

.book-test {
  display: table;
  margin: 50px auto;
}

.deliverbox {
  text-align: center;
  position: relative;
}

.deliverbox h1 {
  font-size: 90px;
  font-weight: 600;
  color: var(--Slate);
}

.Approach-box1 h4 {
  font-size: 25px;
  font-weight: 600;
  color: var(--Slate);
  padding-top: 5px;
}

.Approach-box1 p {
  color: var(--Slate);
  font-size: 15px;
  font-weight: 500;
}

.deliverbox p {
  font-size: 24px;
  color: #3a3a3a;
}

span.sideicondeliver img {
  position: absolute;
  top: 0;
  height: 400px;
  transition: all 0.9s ease-in-out;
}

span.sideicondeliver.rightside img {
  right: 0;
  left: initial;
  transform: rotate(180deg);
}

section.Deliver-sec:hover span.sideicondeliver img {
  filter: brightness(0.5);
}

section.Deliver-sec {
  background-image: url(../images/deliversec.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s ease-in-out;
}

.deliverbox.ind:before {
  content: "";
  position: absolute;
  right: 0;
  top: 30px;
  height: 80%;
  background: var(--Slate);
  width: 1px;
}

/* represent sec */
.Representcontent h3 {
  font-size: 35px;
  color: var(--Slate);
  border-bottom: 4px solid #fc8a7d;
  display: inline-block;
  font-weight: 500;
}

.Approach-box.box-second {
  width: 90%;
  position: relative;
  margin: 0 auto;
}

.Approach-box.box-second span.arrow-approch {
  height: 100%;
  transform: rotate(0);
  top: 0;
  left: 50%;
}

.Representcontent p {
  font-size: 20px;
  color: #4f6367;
  font-weight: 400;
}

section.Representssec {
  padding-top: 80px;
}

section.Representssec .txt-optima-work {
  padding-bottom: 40px;
}

/* our approch sec */
.Approach-main {
  width: 950px;
  margin: 0 auto;
}

.Approach-main .book-test a {
  background-color: var(--Slate);
  color: white;
}

.Approach-box1 {
  background: white;
  box-shadow: -2px -1px 3px 7px #00000005;
  border: 1px solid #ff5e54;
  text-align: center;
  width: 280px;
  height: 250px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 70px;
}

span.arrow-approch {
  height: 80px;
  width: 3px;
  background: #4f6367;
  position: absolute;
  bottom: -4.8rem;
  left: -2rem;
  transform: rotate(37deg);
}

span.arrow-approch:before {
  position: absolute;
  content: "";
  background: #ff5e54;
  border-radius: 50px;
  width: 8px;
  height: 8px;
  top: -2px;
  left: -2.3px;
}

span.arrow-approch:after {
  position: absolute;
  content: "";
  background: #ff5e54;
  border-radius: 50px;
  width: 8px;
  height: 8px;
  bottom: -2px;
  left: -2.3px;
}

span.arrow-approch.rightarrow {
  right: -2rem;
  left: initial;
  transform: rotate(-38deg);
}

.Approach-box.box-second span.arrow-approch.box-secondright {
  height: 140px;
  right: -4.8rem;
  left: inherit;
  transform: rotate(90deg) translatex(-50%) !important;
  top: 22% !important;
}

.Approach-box.box-second span.arrow-approch.box-secondright.sevenbx {
  left: -4.6rem;
  right: initial;
}

span.arrow-approch.eightbx {
  transform: rotate(0deg);
  left: 50%;
  bottom: -4rem;
  height: 60px;
}

section.Approchsec {
  padding: 80px 0;
  background-image: url(../images/OurApproach.png);
  background-size: cover;
}

.txt-optima-work.approchtit h3 {
  font-size: 40px;
}

.txt-optima-work p {
  color: #4f6367;
  font-weight: 300;
}

/* about end */
/*  */
.put-the-test-sec {
  background-image: url("../images/populartest.png");
  background-size: cover;
  padding: 50px;
  position: relative;
}

.put-the-grid {
  display: grid;
  grid-gap: 2%;
  grid-template-columns: 55% 43%;
  align-items: center;
  width: 80%;
  margin: 0 auto;
}

.put-the-txt h1 {
  font-size: 50px;
  font-weight: 500;
  color: var(--Slate);
  text-transform: uppercase;
}

.put-the-img {
  text-align: center;
  position: relative;
}

.put-the-img img {
  position: relative;
  z-index: 9;
}

.put-the-img:before {
  content: "";
  background-color: var(--Pale-Aqua);
  opacity: 0.5;
  height: 350px;
  width: 350px;
  position: absolute;
  top: 65px;
  border-radius: 50%;
}

.put-icns span img {
  display: table;
  margin: 0 auto;
}

.put-icns {
  display: grid;
  text-align: center;
  grid-gap: 2%;
  grid-template-columns: 32% 32% 32%;
}

.put-icns {
  font-size: 24px;
  color: #959595;
}

.put-icns span img {
  padding-bottom: 6px;
  width: 30px;
}

.put-the-txt a {
  margin: 35px 0px;
}

.put-the-txt p {
  color: var(--Slate);
  font-size: 24px;
}

.delevery-icons {
  height: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/*  */

.work-with-optima {
  background-color: var(--Pale-Aqua);
  position: relative;
  padding: 100px 0px 60px 0;
  overflow: hidden;
}

.work-with-optima::before {
  content: "";
  position: absolute;
  height: 660px;
  width: 32%;
  background-image: url("../images/howworkleftimg.png");
  background-repeat: no-repeat;
  background-position: top 40px center;
  background-size: 100%;
  left: -23%;
  opacity: 0.5;
  top: 0;
  animation: animName 50s linear infinite;
}

.txt-optima-work h2 {
  font-weight: 600;
}

.txt-optima-work p {
  padding: 8px 0px;
  width: 75%;
  margin: 0 auto;
  text-align: center;
  font-size: 24px;
}

.btns-optima {
  display: flex;
  justify-content: space-evenly;
  padding: 30px 0px 0px 0px;
}

.btns-optima a {
  /* color: #fff;
  background-color: var(--Slate);
  padding: 18px 45px;
  font-size: 22px;
  font-weight: 600;
  border-radius: 11px;
  width: 300px;
  display: block;
  text-align: center; */
}

.txt-optima-work h2 {
  font-weight: 600;
  color: var(--Slate);
  font-size: 50px;
  text-align: center;
}

section.ourpartner-sect .row {
  background: var(--dark-Pale-Aqua);
  padding: 70px 0;
  border-radius: 30px;
}

.partnerlogo {
  background: white;
  border-radius: 10px;
  height: 150px;
  width: 150px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  /* transition: ease-in-out 0.36s; */
}

.partner-hr:before {
  content: "";
  position: absolute;
  right: 0;
  top: 30px;
  height: 80%;
  background: white;
  width: 1px;
}

.partner-hr {
  position: relative;
}

.partner-hr h2 {
  font-size: 30px;
  font-weight: 600;
  color: white;
  text-align: center;
}

section.ourpartner-sect {
  padding: 70px 0;
}

/* about inner pages */
span.sidebannericon.right-sd img {
  right: -19%;
  left: initial;
}

.about-innerpages section.innerbanner {
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
  justify-content: center;
}

.about-innerpages .btns-optima .active {
  background: var(--Slate);
  color: var(--white);
}

.about-innerpages .innerbannercontent h1 {
  color: var(--Slate);
  text-align: center;
  font-size: 90px;
  padding: 10px 0;
}

.about-innerpages .btns-optima a {
  width: 220px;
  /* background: transparent;
  border: 1px solid var(--Slate);
  color: var(--Slate); */
}

.about-innerpages .btns-optima {
  width: 73%;
  margin: 0 auto;
}

.about-innerpages .innerbannercontent p {
  text-align: center;
}

.about-innerpages .baseline-img img {
  width: auto;
  border-radius: 20px;
  box-shadow: 36px 19px 70px 0px #4f63679c;
}

.about-innerpages .baseline-img {
  text-align: center;
}

.about-innerpages .baselinecontent p {
  margin-bottom: 30px;
}

.ourteam {
  display: flex;
  align-items: center;
  gap: 20px;

  margin: 0px 0px 120px 0;
}

.cus-ourteam {
  width: 78%;
  margin: 0 auto;
}

/* .teamsec .ourteam{
 
} */

.teamname span h5 {
  font-size: 30px;
  color: var(--Slate);
  font-weight: 600;
}

.teamname span h5 span {
  font-weight: 300;
}

.teamname p {
  font-size: 20px;
  color: var(--Slate);
  font-weight: 300;
}

.ourteam:last-child {
  margin-bottom: 0;
}

section.teamsec {
  padding: 100px 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(../images//put-the-test-bg.png);
}

/* collection-centre-styles */

/* .island-parent{
    position: relative;
}

.inner-img-island-one {
    position: absolute;
    left: 33px;
}

.inner-img-island-two {
    position: absolute;
    top: 0;
    left: 436px;
}

.inner-img-island-three {
    position: absolute;
    top: 0;
    right: 111px;
}

.inner-img-island-four {
    position: absolute;
    top: 446px;
    left: 439px;
}

.inner-img-island-five {
    position: absolute;
    top: 533px;
    right: 110px;
}

.inner-img-island-six {
    position: absolute;
    right: 235px;
    bottom: -203px;
}


.inner-img-island-one span {
    position: absolute;
    font-size: 50px;
    font-weight: 600;
}
.inner-img-island-two span {
    position: absolute;
    font-size: 50px;
    font-weight: 600;
}
.inner-img-island-three span {
    position: absolute;
    font-size: 50px;
    font-weight: 600;
}
.inner-img-island-four span {
    position: absolute;
    font-size: 50px;
    font-weight: 600;
}
.inner-img-island-five span {
    position: absolute;
    font-size: 50px;
    font-weight: 600;
}
.inner-img-island-six span {
    position: absolute;
    font-size: 50px;
    font-weight: 600;
} */

.parent-map {
  position: relative;
}

.mapdiv {
  position: absolute;
  left: 6%;
}

.path-one,
.path-two,
.path-three,
.path-four,
.path-five,
.path-six,
.path-seven {
  fill: var(--Pale-Aqua);
  stroke: #000;
  stroke-width: 4px;
  transition: fill 0.3s;
}
/* .path-one:hover,
.path-two:hover,
.path-three:hover,
.path-four:hover,
.path-five:hover,
.path-six:hover,
.path-seven:hover {
  
  stroke-width: 4px;
 
} */

.mapdiv {
  fill: var(--Pale-Aqua);
}

/* .mapdiv :hover {
  fill: var(--Pale-Aqua);
} */

.ws {
  position: absolute;
  top: 400px;
  z-index: 2;
  left: 246px;
}

.nt {
  position: absolute;
  top: 200px;
  left: 560px;
  z-index: 2;
}

.sa {
  position: absolute;
  top: 490px;
  left: 588px;
  z-index: 2;
}

.qld {
  position: absolute;
  top: 290px;
  left: 790px;
  z-index: 2;
}

.nsw {
  position: absolute;
  top: 590px;
  left: 820px;
  z-index: 2;
}

.TSM {
  position: absolute;
  top: 910px;
  left: 857px;
  z-index: 2;
}

.mapdiv svg {
  overflow: inherit;
}

.vic {
  position: absolute;
  top: 744px;
  left: 792px;
  z-index: 2;
}

path.path-seven {
  transform: translate(180px, 180px);
}

.path-one:hover,
.path-two:hover,
.path-three:hover,
.path-four:hover,
.path-seven:hover {
  position: relative;
  z-index: 99999 !important;
}

.path-one:hover .ws h1 {
  fill: #000;
}

.ws h1,
.nt h1,
.sa h1,
.qld h1 {
  font-size: 58px;
  color: var(--Slate);
  font-weight: 600;
  cursor: pointer;
}

.nsw h1 {
  font-size: 52px;
  color: var(--Slate);
  font-weight: 600;
  cursor: pointer;
}

.vic h1 {
  font-size: 37px;
  color: var(--Slate);
  cursor: pointer;
  font-weight: 600;
}

.TSM h1 {
  font-size: 17px;
  color: var(--Slate);
  font-weight: 600;
  cursor: pointer;
}

.ws.hover h1 {
  color: white;
}

.nt.hover h1 {
  color: white;
}

.qld.hover h1 {
  color: white;
}

.nsw.hover h1 {
  color: white;
}

.sa.hover h1 {
  color: white;
}

.vic.hover h1 {
  color: white;
}

.coll-centre-top {
  background-color: var(--Antique-White);
  padding: 240px 0px 80px 0px;
  position: relative;
  overflow: hidden;
}

.txt-top-sec-collection {
  text-align: center;
}

.txt-top-sec-collection h1 {
  font-size: 52px;
  font-weight: 600;
  color: var(--Slate);
}

.txt-top-sec-collection p {
  width: 60%;
  margin: 0 auto;
  color: #505050;
  font-size: 1.1rem;
}

.boxes-collection-centre {
  position: relative;
  overflow: hidden;
}

.boxes-collection-centre {
  padding-top: 100px;
  padding-bottom: 1100px;
  position: relative;
  z-index: 9;
}

.para-bottom-boxes {
  padding-bottom: 90px;
}

.right-hang-circ:before {
  content: "";
  position: absolute;
  height: 100%;
  width: 32%;
  background-image: url("../images/collection-icon-bg.png ");
  background-repeat: no-repeat;
  background-size: 100%;
  top: 30%;
  right: -16% !important;
}

.left-plus-hang-sec:before {
  content: "";
  position: absolute;
  height: 100%;
  width: 32%;
  background-image: url("../images/collection-icon-bg.png ");
  background-repeat: no-repeat;
  background-size: 100%;
  left: -17% !important;
  top: 43%;
}

.heading-box-coll h2 {
  font-size: 65px;
}

.para-bottom-boxes p {
  color: #595d60;
  text-align: center;
  width: 80%;
  margin: 0 auto;
}

/* collection-centre-styles */

/* Build your own test styling */
/* .build-test-banner {
  padding-bottom: 310px;
} */

.btn-btm-padding {
  padding-bottom: 40px !important;
}

.srch-test-labl {
  display: block;
  color: #fff;
  font-size: 20px;
  padding: 8px 0px;
}

.serch-test {
  position: unset;
  background: #fff;
  padding: 25px 20px !important;
}

.serch-test i {
  color: #1282dc !important;
}

.serch-test input,
.serch-test input::placeholder {
  color: #1282dc;
}

.srch-test-box-main {
  padding: 70px 20px;
}

.cart-out-box-own {
  background-color: var(--Antique-White);
  padding: 20px;
  border-radius: 15px;
  position: relative;
  width: 95%;
  border: 1px solid #4f6367;
  box-shadow: 4px 0px 35px #d5e9f6;
  margin-bottom: 35px !important;
  margin: 0 auto;
}

.cart-own-grid {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  text-align: center;
  padding: 50px 0px;
}

.cart-inner-own-test h1 {
  font-weight: 600;
  padding-top: 20px;
  color: var(--Slate);
  font-size: 24px;
  line-height: 35px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
}

.cart-inner-own-test small {
  color: #7a7a7a;
  margin-bottom: 20px;
  display: block;
  text-align: left;
  font-size: 13px !important;
}

.btn-cart-own {
  text-align: start;
  display: flex;
  position: relative;
}

.btn-cart-own button:last-child {
  /* background: var(--Pale-Aqua);
  border: none; */
}

button.text-truncate[title]:hover:after {
  content: attr(title);
  position: absolute;
  width: 280px;
  font-size: 13px;
  background-color: white;
  border: 1px solid black;
  left: 0;
  color: #3a3a3a;
  top: -2rem;
  z-index: 99;
  padding: 7px 10px;
  border-radius: 10px;
  font-weight: 600;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.btn-cart-own button {
  border: 2px solid #c7c7c7;
  background: none;
  padding: 6px 7px;
  font-size: 13px;
  color: #7a7a7a;
  background-color: var(--white);
  border-radius: 7px;
  margin: 4px 4px;
  width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/* .cart-own-top {
  padding: 20px;
  background-color: var(--Antique-White);
  border-radius: 20px 20px 0px 0px;
  padding-bottom: 45px;
} */

.pric-cart-own-test {
  background-color: var(--Antique-White);
  padding: 20px;
  border-radius: 0px 0px 20px 20px;
}

.price-own span {
  background-color: var(--Pale-Aqua);
  padding: 9px 46px;
  border-radius: 10px;
  margin: 0px auto;
  transform: translateY(-40px);
  color: var(--Slate);
  font-size: 16px;
  font-size: 14px;
  font-weight: 600;
}

.cart-own-learn-flex {
  display: flex;
  justify-content: space-evenly;
  padding-top: 40px;
}

.cart-own-learn-flex .add-to-cart-own {
  background-color: var(--Slate);
  padding: 5px 20px;
  border: 2px solid var(--Slate);
  color: #fff;
  border-radius: 50px;
  transition: all 0.5s ease-in-out;
}

.cart-own-learn-flex .add-to-cart-own:hover {
  background: transparent;
  color: var(--Slate);
}

.cart-own-learn-flex .learn-more-own {
  padding: 2px 10px;
  border: 3px solid #1282dc;
  color: #1282dc;
  border-radius: 4px;
}

section.build-your-own-test-sec {
  /* background-image: url(../images/build-own-test-sec-bg.png); */
  padding: 50px;
}

.build-test-grid {
  display: grid;
  grid-gap: 2%;
  grid-template-columns: 49% 49%;
}

.build-test-sec-heading h1 {
  color: #1282dc;
  font-size: 38px;
}

.build-test-sec-heading p {
  font-size: 26px;
  text-transform: uppercase;
  color: #1282dc;
  padding-bottom: 20px;
}

.build-test-sec-heading select {
  background: transparent;
  padding: 14px;
  font-size: 18px;
  width: 70%;
  border: 2px solid #0009;
  border-radius: 5px;
  margin-bottom: 15px;
}

.test-name-price-get {
  border: 3px solid #1282dc;
  border-radius: 10px;
  position: relative;
  box-shadow: 2px 2px 30px #4444;
  padding: 25px;
  min-height: 400px;
  max-height: 400px;
}

.sortingproducts button {
  border-radius: 10px;
  padding: 5px 8px;
  box-shadow: 0px 2px 6px #000000ab;
  color: var(--Slate) !important;
  font-weight: 600;
  font-size: 20px;
  background: none;
  border: 1px solid;
  width: 160px;
}

.sortingproducts .btn-check:checked + .btn,
.sortingproducts :not(.btn-check) + .btn:active,
.sortingproducts .btn:first-child:active,
.sortingproducts .btn.active,
.sortingproducts .btn.show {
  background: initial;
  border: none;
}

.sortingproducts a.dropdown-item {
  display: flex;
  gap: 10px;
}

.sortingproducts .btn:hover {
  color: initial;
  background: white !important;
}

.sortingproducts .btn:focus-visible {
  color: initial;
  background: none !important;
}

.result-own-test {
  padding: 0px;
  border: 2px solid #4449;
  border-radius: 10px;
}

.vl-build {
  position: absolute;
  width: 100%;
  border: 1px solid #cecece;
}

.test-result-name:last-child {
  border: none;
}

.test-result-name {
  height: 48px;
  position: relative;
  border-bottom: 2px solid #444;
  display: flex;
  justify-content: space-between;
}

.test-result-name span {
  line-height: 49px;
}

.show-more-btn {
  background: #1282dc;
  display: table;
  padding: 8px 20px;
  border-radius: 39px;
  color: #fff;
  cursor: pointer;
}

.card-info-main input {
  margin-right: 5px;
}

.value {
  width: 150px;
  color: #fff;
  background: #1282dc;
  text-align: center;
}

span.name {
  padding-left: 20px;
  color: #1282dc;
}

.test-head {
  display: flex;
  justify-content: space-between;
}

span.price-head {
  padding-right: 55px;
}

.build-your-own-test-select.show-more-item {
  margin-bottom: 15px;
}

section.product-banner.build-test-banner {
  height: 45vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
}
.buildown-head {
  display: flex;
  justify-content: center;
}
/* Build your own test styling */

/* contact page styles */

.contact-top-sec {
  background-image: url(../images//contact-top-bg.png);
  background-size: 100%;
  background-repeat: no-repeat;
  position: relative;
  margin-top: 80px;
  padding: 90px 10px;
  background-size: cover;
}

.top-grid-contact {
  display: grid;
  grid-gap: 2%;
  grid-template-columns: 49% 49%;
  z-index: 9;
  position: relative;
}

.left-top-grid {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #ffffff;
}

.left-top-grid h1 {
  font-size: 72px;
  letter-spacing: 0.5px;
  line-height: 69px;
  color: var(--Slate);
  font-weight: 700;
}

.top-left-para {
  padding-top: 18px;
  color: #303030;
  font-size: 24px;
}

.parent-inner-grid {
  padding-top: 50px;
}

.grid-pd-top {
  padding-top: 25px;
}

.contact-left-top-inner-grid {
  display: grid;
  grid-gap: 3%;
  grid-template-columns: 7% 90%;
}

.left-top-icn i {
  color: #087bca;
  background-color: #ffffff;
  padding: 10px 12px;
  border-radius: 7px;
  transition: 0.4s ease-in-out;
  cursor: pointer;
}

.left-top-icn i:hover {
  background-color: #087bca;
  color: #ffffff;
}

.right-top-txt h5 {
  line-height: 22px;
  font-size: 18px;
}

.right-top-txt p {
  line-height: 22px;
  font-size: 16px;
}

.box-form {
  min-width: 400px;
  min-height: 710px;
  border-radius: 60px;
  background-color: #ffffff;
  padding: 80px 55px 0px 55px;
  border: 18px solid var(--dark-Pale-Aqua);
  position: relative;
}

.grid-form-one {
  display: grid;
  grid-gap: 4%;
  grid-template-columns: 48% 48%;
}

.grid-form-two {
  display: grid;
  grid-template-columns: 100%;
  padding-top: 22px;
}

.contact-top-form label {
  color: var(--Slate);
  font-weight: 700;
  font-size: 19px;
  padding-bottom: 8px;
}

.contact-top-form input {
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  border: 1px solid #e9e9e9;
  border-radius: 3px;
  padding: 11px 25px;
  height: 60px;
  width: 100%;
}

.contact-top-form input::placeholder {
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  color: #636363;
}

.email-input label {
  display: block;
}

.msg-input label {
  display: block;
  padding-bottom: 8px;
}

.email-input input {
  font-family: "Poppins", sans-serif;
  width: 100%;
}

.msg-input textarea {
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  resize: none;
  border: 1px solid #087bca;
  box-shadow: #d9d5d5 0px 2px 7px;
  border-radius: 5px;
  padding: 11px 25px;
  width: 100%;
  min-height: 195px;
}

.msg-input textarea::placeholder {
  font-size: 20px;

  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: #636363;
}

.grid-form-three input {
  font-size: 18px;
}

.grid-form-three {
  display: grid;
  grid-template-columns: 100%;
  padding-top: 22px;
}

.grid-form-three input {
  color: #ffffff;
  background-color: var(--Slate);
  width: 27% !important;
  padding: 4px 14px !important;
  font-size: 17px;
  font-weight: 600;
  border-radius: 50px !important;
}

.inputBox-contact {
  width: 100% !important;
  background: white;
  padding: 47px 50px;
  border-radius: 15px;
  z-index: 9;
  bottom: -69px;
  right: 0px;
  position: absolute;
  display: flex;
  transform: scale(0.8);
  border: 2px solid var(--Slate);
}

.inputBox-contact input::placeholder {
  font-size: 30px;
}

.inputBox-contact i {
  font-size: 40px !important;
}

.inputBox-contact i.fa-solid.fa-magnifying-glass {
  color: var(--Slate);
  font-size: 28px;
  padding-right: 36px;
}

.inputBox-contact input {
  background: transparent;
  border: 0;
  font-size: 22px;
  color: var(--Slate);
  font-family: "Poppins", sans-serif;
}

.inputBox-contact input:focus {
  outline: none;
}

.inputBox-contact input::placeholder {
  color: var(--Slate);
  font-family: "Poppins", sans-serif;
}

.contact-top-form input::placeholder {
  color: #959595;
}

.contact-top-form textarea::placeholder {
  color: #959595;
}

.overflow-contact {
  /* background: #e5f4ff; */
}

.backprobtn a {
  font-size: 30px;
  color: var(--Slate);
}

.backprobtn {
  width: 390px;
  border-radius: 40px;
  padding: 18px 0;
  background-color: var(--Pale-Aqua);
  margin-bottom: 20px;
  text-align: center;
  box-shadow: 0px 2px 10px #000000b3;
}

.backprobtn a {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
}

/* contact page styles */

/*  */
.banner-faq-form {
  background: white;
  padding: 40px 40px;
  border-radius: 20px;
  z-index: 1;
  position: relative;
}

.banner-faq-form input {
  width: 100%;
  border-radius: 11px;
  width: 100%;
  height: 60px;
  padding: 0 0 0 14px;
  margin-bottom: 30px;
  outline: none;
  border: none;
  background: #f6f6f6;
}

.banner-faq-form textarea {
  width: 100%;
  border-radius: 11px;
  width: 100%;
  height: 160px;
  padding: 20px 10px 0 14px;
  margin-bottom: 30px;
  outline: none;
  border: none;
  background: #f6f6f6;
}

.banner-faq-form h2 {
  font-size: 36px;
  color: black;
  font-weight: 700;
}

.banner-faq-form a {
  background: var(--Slate);
  padding: 10px 30px;
  border-radius: 50px;
  font-size: 20px;
  font-weight: 500;
  color: #ffff;
  font-family: "Poppins";
  width: 100%;
  display: flex;
  justify-content: center;
}

section.secbanner.FaqSec {
  background: var(--Antique-White);
  overflow: hidden;
}

section.secbanner.FaqSec h1 {
  color: var(--Slate);
  line-height: 90px;
}

.banner-faq-form input:focus {
  border: 1px solid;
  background: var(--Pale-Aqua);
}

.banner-faq-form textarea:focus {
  border: 1px solid;
  background: var(--Pale-Aqua);
}

.faqtab ul#myTab {
  display: flex;
  flex-direction: column;
  border: none;
}

.faqtab {
  background: #ffffff;
  border: 1px solid #70707042;
  border-radius: 20px;
  box-shadow: 0 0 20px 7px #00000017;
  padding: 30px 40px;
  height: 840px;
}

.faqtab h3 {
  font-size: 36px;
  color: black;
  font-weight: 600;
  margin-bottom: 30px;
}

/* .faqtab ul#myTab li {
  background: #F6F6F6;
  margin-bottom: 20px;
  border-radius: 10px;
  padding: 7px 30px;
  color: black;
} */

.faqtab ul#myTab li button {
  background: none;
  border: none;
  color: black;
  font-size: 25px;
  font-weight: 400;
  width: 100%;
  padding: 7px 30px;
  background: #f6f6f6;
  margin-bottom: 20px;
  border-radius: 10px;
  padding: 14px 30px;
  color: black;
  text-align: left;
  display: flex;
  align-items: center;
  gap: 12px;
}

.faqtab ul#myTab li button.nav-link.active {
  background: var(--Pale-Aqua);
}

section.FaqSecone h2 {
  font-size: 50px;
  padding: 5px 0px 0px 0px;
  font-weight: 600;
  color: var(--Slate);
  text-align: center;
}

section.FaqSecone {
  padding: 50px 0;
}

/* FAQ SCROLL */
.faqtabcontent .accordion::-webkit-scrollbar {
  width: 0.5em;
  height: 0.5em;
}

.faqtabcontent .accordion::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.faqtabcontent .accordion::-webkit-scrollbar-thumb {
  background-color: var(--dark-Pale-Aqua);
  outline: 1px solid slategrey;
  padding-right: 10px;
  margin: 10px;
}
.trust-pilot-handle {
  display: flex;

  align-items: center;
  gap: 10px;
}
.trust-pilot-handle p {
  margin: 0;
}
.trust-logo {
  width: 25px;
}
/* FAQ SCROLL */

.FaqSecone .tab-content {
  background-color: var(--Antique-White);
  border-radius: 20px;
  border: 1px solid #80808059;
  margin-top: 74px;
}
.faqtabcontent .accordion {
  overflow-y: scroll;
  height: 795px;
  padding: 1px 1rem 0rem 1rem;
}
.faqtabcontent {
  padding: 30px 40px;

  /* background-color: var(--Antique-White); */
  height: 840px;
  margin-right: 15px;
}

.faqtabcontent h3 {
  color: #087bca;
  font-size: 20px;
  font-weight: 600;
  margin-top: 30px;
}

.faqtabcontent p {
  font-size: 15px;
  font-weight: 400;
  color: #9e9e9e;
  margin-bottom: 30px;
}

.faqtab ul#myTab li button.nav-link.active img {
  opacity: 1;
}

.faqtabcontent button.accordion-button {
  margin-bottom: 6px;
  border-radius: 15px !important;
  background: white;
  box-shadow: 0 0 9px 4px #0000002e;
  font-size: 20px;
  font-weight: 500;
  color: var(--Slate);
}

.faqtabcontent .accordion {
  background: transparent;
}

.faqtabcontent .accordion-item {
  background: transparent;
  border: none;
  margin-bottom: 30px;
}

.faqtabcontent .accordion-collapse {
  border-radius: 15px;
  background: white;
  box-shadow: 0 0 9px 4px #00000026;
}

.faqtabcontent .accordion-body {
  font-size: 17px;
  color: #646464;
}

.faqtab ul#myTab li button img {
  opacity: 0;
}

/*  */
.test-offer {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  gap: 20px;
}

.test-offer span {
  align-items: center;
  display: flex;
  gap: 5px;
  width: 210px;
}

.test-offer span p {
  margin: 0;
}

.test-offer span:last-child {
  width: 250px;
}

.hambar ul li a {
  color: var(--Slate);
}

.hambar {
  display: flex;
  justify-content: space-between;
  padding-bottom: 5px;
  border-bottom: 1px solid var(--Slate);
  margin-bottom: 40px;
  position: relative;
  z-index: 1;
}

.hambar ul {
  display: flex;
  gap: 40px;
}

.innerbannercontent {
  z-index: 1;
  position: relative;
}

.hambar hr {
  color: var(--Slate);
  font-size: 5px;
}

section.innerbanner.productinnersec1 {
  padding-bottom: 40px;
  height: 100%;
  padding-top: 40px;
}

.innerbannercontent.para p {
  width: 70%;
  text-align: center;
  margin: auto;
}

/* Custom style */
.accordion-button::after {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='%23333' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' d='M8 0a1 1 0 0 1 1 1v6h6a1 1 0 1 1 0 2H9v6a1 1 0 1 1-2 0V9H1a1 1 0 0 1 0-2h6V1a1 1 0 0 1 1-1z' clip-rule='evenodd'/%3e%3c/svg%3e");
  transform: scale(0.7) !important;
  filter: grayscale(1) invert(3) brightness(3.5);
}

.accordion-button:not(.collapsed)::after {
  filter: grayscale(1) invert(3) brightness(3.5);
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='%23333' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' d='M0 8a1 1 0 0 1 1-1h14a1 1 0 1 1 0 2H1a1 1 0 0 1-1-1z' clip-rule='evenodd'/%3e%3c/svg%3e");
}

.accordion.Faqinneracc h1 {
  font-size: 70px;
  color: white;
  text-align: center;
  font-weight: 600;
  font-family: "Poppins";
  text-transform: uppercase;
  margin-bottom: 20px;
}

section.FaqSectioninner .accordion-item {
  border-left: 0;
  border-right: 0;
  border-radius: 0;
  background: var(--dark-Pale-Aqua);
}

.accordion.Faqinneracc {
  background-image: url(../images//faqbgpage.png);

  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 60px 50px 140px;
}

section.FaqSectioninner .accordion-button:not(.collapsed) {
  background: none;
  box-shadow: none;
  color: white;
}

section.FaqSectioninner .card-body p {
  color: white;
  padding: 1rem !important;
}

section.FaqSectioninner .accordion-header button {
  font-size: 22px;
  font-weight: 600;
  border-radius: 0;
  background: var(--dark-Pale-Aqua);
  color: white;
}

/* 
.test-harmones {
  background-image: url(../images//whattest.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  padding: 40px 30px;
} */
.test-harmones {
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  padding: 40px 30px;
  border: 2px solid var(--Slate);
  border-radius: 10px;
  position: relative;
}

.inner-icon-plus {
  position: absolute;
  width: 164px;
  left: -1rem;
  bottom: 0;
}

img.inner-icon-plus.pro-innicontwo {
  right: 0;
  left: initial;
  bottom: initial;
  top: 0;
}

.test-harmones h1 {
  color: var(--Slate);
  font-size: 32px;
}

.test-harmones p {
  color: #3a3a3a;
  font-size: 20px;
}

.test-harmones h4 {
  color: #3a3a3a;
  font-size: 24px;
  margin-bottom: 20px;
}

.test-count {
  display: flex;
  flex-flow: wrap;
  gap: 20px;
}

.test-count span {
  background: var(--Slate);
  border-radius: 10px;
  padding: 3px 22px;
  color: var(--white);
  font-size: 24px;
}

section.wetest > h1 {
  text-align: center;
  margin-bottom: 30px;
  font-size: 50px;
  color: var(--Slate);
  font-weight: 500;
}

section.wetest {
  padding-bottom: 80px;
}

section.wetest.intructionsec {
  background-color: #fbf7ef7d;
  padding-top: 100px;
  position: relative;
}

.test-intruct img {
  width: 90px;
  height: 110px;
}

.test-intruct p {
  text-align: center;
  color: #313131;
  margin-top: 20px;
  font-size: 20px;
}

.test-intruct {
  text-align: center;
}

section.prod-boxes-sec.interested-test h1 {
  text-align: center;
  font-size: 50px;
  color: var(--Slate);
  font-weight: 500;
}

section.prod-boxes-sec.interested-test .grid-product-boxes {
  padding-bottom: 0;
}

.blue-text {
  color: var(--Slate);
}

.blue-white {
  color: var(--Slate) !important;
  background-color: inherit !important;
}

/*  */

ul.tab-health {
  display: flex;
  padding: 14px 30px;
  background: #faf9f7;
  border: 10px solid var(--Slate);
  border-radius: 20px;
  position: relative;
  top: -40px;
  justify-content: space-between;
  width: 80%;
  margin: 0 auto;
}

ul.tab-health li a {
  color: var(--Slate);
  padding: 6px 30px;
  border-radius: 40px;
}

ul.tab-health li.active a {
  background: var(--Slate);
  color: var(--white);
}

.healthhubblogleft {
  padding: 20px 40px;
  background: var(--Antique-White);
  margin: 0 auto;
  position: relative;
  border-radius: 30px;
  border: 2px solid var(--Slate);
}

.healthhubblogleft img {
  width: 100%;
}

.healthhubblogleft h3 {
  font-size: 35px;
  color: var(--Slate);
  font-weight: 600;
  margin: 0;
  margin-top: 30px;
  text-transform: uppercase;
}

.healthhubblogleft p {
  font-size: 30px;
  color: #676767;
  font-weight: 400;
}

textarea {
  resize: none;
}

.healthhubblogleft span {
  color: #676767;
  font-size: 20px;
  font-weight: 400;
}

.healthhubblogleft a.btn.how-btn {
  position: absolute;
  right: 40px;
  bottom: 30px;
  background: var(--white);
  border: 2px solid var(--Slate);
  color: var(--Slate);
  border-radius: 5px;
}

.recentlyblog {
  display: grid;
  gap: 9px;
  margin-bottom: 15px;
  cursor: pointer;
  grid-template-columns: 50% 50%;
}

.recentlyblog img {
  width: 100%;
  height: 190px;
  object-fit: cover;
  border-radius: 18px;
}

.recentlyblog h6 {
  font-size: 20px;
  color: #b1b1b1;
  font-weight: 600;
}

.recentlyblog h3 {
  font-size: 20px;
  color: var(--Slate);
  font-weight: 600;
}

.fourth-sect {
  padding: 40px 0px 50px 0px;
}

.fourth-grid {
  display: grid;
  gap: 20px;
  grid-template-columns: 30.6% 30.6% 30.6%;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
  width: 90%;
}

.fourth-box {
  border: 1px solid var(--Slate);
  background-color: var(--Antique-White);
  border-radius: 15px;

  min-height: 490px;

  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.under-graph .slick-prev:before,
.slick-next:before {
  color: #4f6367;
  font-size: 28px;
}

.under-graph .slick-prev:before {
  left: 10px;
}

.under-graph .slick-prev {
  left: -40px;
}
.cutom-img img {
  max-width: 300px;
}

.fourth-img h5 {
  padding: 0 12px;
  height: 53px;
  padding-top: 8px;
  font-size: 19px;
  font-weight: 600;
  line-height: 30px;
  color: var(--Slate);
}
.vitals-data button.slick-arrow.slick-next {
  display: none !important;
}

.fourth-box-txt-inner-grid {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.custom-txt h4 {
  color: #fff;
  padding-top: 14px;
  font-size: 19px;
}

.right-inner-grid {
}

.right-inner-grid button {
  border-radius: 50px;
  padding: 8px 9px;
  font-weight: 600;
  color: var(--white);
  font-size: 15px;
  border: none;
  background-color: var(--Slate);
  border: 2px solid var(--Slate);
  transition: all 0.5s ease-in-out;
}

.right-inner-grid button:hover {
  background: none;
  color: var(--Slate);
}

.left-inner-grid h6 {
  color: var(--Slate);
  text-align: start;
  font-weight: 600;
  text-align: center;
}

.left-inner-grid p {
  font-size: 15px;
  font-weight: 600;
}

.set-txt-grid-eight {
  padding-top: 52px;
}

.set-txt-grid-seven {
  padding-top: 25px;
}

.set-txt-grid-six {
  padding-top: 50px;
}

.set-txt-grid-five {
  padding-top: 50px;
}

.set-txt-grid-four {
  padding-top: 50px;
}

.set-txt-grid-three {
  padding-top: 53px;
}

.set-txt-grid-two {
  padding-top: 52px;
}

.set-txt-grid-one {
  padding-top: 80px;
}

.five-sect {
  background-image: url("../images/populartest.png");
  background-size: cover;
  background-position: 100% 100%;
  background-repeat: no-repeat;
  padding: 30px 0px 20px 0px;
  position: relative;
  overflow: hidden;
}

section.five-sect span.left-ban-img {
  left: -17%;
  top: 80%;
}

.five-grid {
  display: grid;
  gap: 5%;
  grid-template-columns: 65.5% 29.5%;
}

.five-grid-txt {
  padding-top: 38px;
}

.five-grid-img img {
  width: 325px;
}

.five-grid-txt h1 {
  font-size: 48px;
  padding-bottom: 40px;
  color: var(--Slate);
  line-height: 60px;
  font-weight: 500;
  text-transform: uppercase;
}

/* .five-grid-txt a {
  border-radius: 10px;
  padding: 12px 90px;
  font-size: 22px;
  font-weight: 500;
  background-color: var(--Slate);
  color: #fff;
} */

section.fourth-sect h1 {
  font-weight: 600;
  color: var(--Slate);
  font-size: 50px;
  text-align: center;
  margin-bottom: 40px;
  text-transform: uppercase;
}

.healthsearch input {
  background: #087bca;
  border-radius: 10px;
  border: none;
  height: 50px;

  width: 100%;

  padding: 10px 20px;
}

.healthsearch input::placeholder {
  color: white;
}

.healthsearch {
  position: relative;
  width: 300px;
  margin-left: auto;
  margin-bottom: 40px;
}

.healthsearch i {
  position: absolute;
  right: 18px;
  top: 50%;
  transform: translateY(-50%);
  color: #ffffff6b;
}

.blogdetailinner h2 {
  font-weight: 600;
  color: var(--Slate);
  font-size: 50px;
  text-align: left;
  margin-top: 1.5rem;
}

.blogdetailinner span {
  font-size: 20px;
  color: #7a7a7a;
  margin-bottom: 10px;
  display: block;
}

.blogdetailinner p {
  font-size: 20px;
  color: #7a7a7a;
  margin-bottom: 10px;
  display: block;
  margin-top: 20px;
}

.featurebox {
  background: #f5f5f5;
  border-radius: 30px;
  padding: 30px 20px;
  margin-bottom: 40px;
}

.featurebox h4 {
  font-size: 20px;
  color: var(--Slate);
  font-weight: 600;
}

.featurebox p {
  font-size: 20px;
  color: #7a7a7a;
  margin-bottom: 28px;
  font-weight: 400;
}

.featurebox.categories-fea {
  background: white;
  border: 1px solid #70707045;
}

/* thanks modal purchase */
.fade.thank-purchase.modal .modal-body {
  background: var(--Pale-Aqua);
  border-radius: 10px;
  padding: 60px 0px;
}

.fade.thank-purchase.modal h1 {
  color: var(--Slate);
  font-size: 60px;
  font-weight: 600;
  margin-bottom: 60px;
  margin-top: 20px;
}

.assign-btn button {
  background: #0c7ecc;
  padding: 10px 30px;
  border-radius: 50px;
  color: white;
  font-weight: 600;
  margin-left: 10px;
}

.fade.thank-purchase.modal a {
  background: #087bca;
  padding: 10px 50px;
  border-radius: 12px;
  font-size: 24px;
  font-weight: 500;
  color: #ffff;
}

.fade.thank-purchase.modal button {
  background: var(--Slate);
  padding: 10px 50px;
  border-radius: 12px;
  font-size: 24px;
  font-weight: 500;
  color: #ffff;
}

.autho-info {
  display: flex;
  background: var(--dark-Pale-Aqua);
  border-radius: 20px;
  padding: 20px 40px;
  width: 100%;
  box-shadow: -1px 4px 4px 3px #00000024;
  margin-top: 20px;
  align-items: center;
}

.autho-info h6 {
  color: white;
  font-size: 24px;
  font-weight: 600;
}

.autho-info p {
  color: white;
  font-size: 20px;
  font-weight: 400;
}

section.prodetialsec {
  padding-bottom: 50px;
}

.autho-info img {
  margin-right: 29px;
  width: 300px;
  height: fit-content;
}

/* demo account page */
.demoaccount img {
  margin: 0 auto;
  padding-bottom: 110px;
  width: 63%;
}

.demoaccount {
  text-align: center;
  position: relative;
}

.demoaccount:before {
  position: absolute;
  left: 50%;
  bottom: 30px;
  width: 87%;
  height: 53%;
  background: #b8d9d9;
  content: "";
  border-radius: 280px;
  z-index: -1;
  transform: translateX(-50%);
}

.backhome {
  background: var(--Slate);
  width: 150px;
  display: flex;
  justify-content: center;
  border-radius: 4px;
  padding: 12px 0;
  position: relative;
  left: 130px;
  top: 40px;
}

.backhome i {
  color: white;
  font-size: 18px;
}

section.express-checkout-sec {
  padding: 70px 0;
}

.backhome a i:last-child {
  margin-left: 21px;
}

.title-accounts {
  text-align: center;
}

.title-accounts h1 {
  color: var(--Slate);
  font-size: 50px;
  font-weight: 500;
  text-transform: uppercase;
}

.demoaccount h4 {
  text-transform: uppercase;
}

.title-accounts p {
  font-size: 13px;
  color: var(--Slate);
  width: 50%;
  margin: 0 auto;
}

.title-accounts {
  margin-bottom: 50px;
  margin-top: 50px;
}

section.accountdemosec {
  background-image: url("../images/populartest.png");
  background-repeat: no-repeat;
  background-size: cover;
}

section.accountdemosec .slick-prev:before {
  color: var(--Slate);
  font-size: 45px;
}

section.accountdemosec .slick-next:before {
  color: var(--Slate);
  font-size: 45px;
}

section.form-sect-log .password-input {
  position: relative;
  width: 84%;
}

.password-input input {
  width: 100%;
}

.password-input input {
  margin-bottom: 0 !important;
}

.password-input {
  position: relative;
}

.password-input span.red-alert {
  bottom: -26px;
}

a.back-trans.border-btn span {
  position: absolute;
  right: -12px;
  top: -12px;
  background: var(--dark-Pale-Aqua);
  color: white;
  border-radius: 30px;
  /* padding: 2px 8px; */
  font-size: 13px;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.top-navbar-btns.desktop button#dropdown-basic {
  background: #087bca;
}

.top-navbar-btns.desktop .dropdown {
  display: flex;
  align-items: center;
}

.top-navbar-btns.desktop a.dropdown-item button {
  background: #087bca;
  padding: 9px 26px;
  border-radius: 12px;
  font-size: 18px;
  font-weight: 500;
  color: #ffff;
  font-family: "Poppins";
  width: 100%;
  margin-top: 13px;
}

header.Mean-header .dropdown-menu.show {
  display: block;
  padding: 20px 10px;
}

.about-innerpages.Healthhb .innerbannercontent h1 {
  font-size: 70px;
}

.about-innerpages.Healthhb .innerbannercontent {
  padding-bottom: 70px;
}

.featurecol {
  margin-top: 170px;
}

/*  */
.test-harmones span[title]:hover:after {
  content: attr(title);
  position: absolute;
  width: 280px;
  font-size: 13px;
  background-color: white;
  border: 1px solid black;
  left: 0;
  color: #3a3a3a;
  top: 48px;
  z-index: 99;
  padding: 7px 10px;
  border-radius: 10px;
  font-weight: 600;
  text-align: center;
}

.test-harmones span {
  position: relative;
}

/* payment page */
.innerpaymentdv {
  border: 1px solid #d2d0d0;
  border-radius: 16px;
  padding: 40px 20px;
  background: #fcfcfc;
}

.pay-type {
  text-align: end;
}

.pay-type img {
  margin-left: 15px;
}

.oncredit p {
  color: #959595;
  margin-left: 29px;
  font-size: 13px;
}

.oncredit hr {
  border-bottom: 1px dashed;
  width: 70%;
}

.payform .formgroup input {
  width: 100%;
  background-color: #f5fafd;
  border: 1px solid #cbcbcb;
  border-radius: 10px;
  height: 48px;
  font-size: 13px;
  padding: 0px 20px;
}

.payform .formgroup label {
  color: #959595;
  margin-bottom: 7px;
  font-size: 13px;
}

form.payform {
  border: 1px solid #d2d0d0;
  border-radius: 16px;
  padding: 20px 50px;
}

.method-paypal {
  background: #f4f8fb;
  border-radius: 20px;
  border: 1px solid #d2d0d0;
  padding: 9px 60px;
  margin-top: 2rem;
  align-items: baseline;
}

.method-paypal img {
  width: 80px;
}

.pay-method .view-more-btn {
  left: 0 !important;
}

.formgrouppayment {
  height: 40.8px !important;
  border-radius: 10px;
  background-color: #f5fafd !important;
  border: 1px solid #cbcbcb !important;
  padding: 10px 15px;
}

.pay-method {
  padding-bottom: 50px;
}

.addproduct-tabs .filepond--list-scroller {
  margin: 0 !important;
}

.addproduct-tabs .filepond--list.filepond--list {
  width: 100%;
  max-width: 100%;
}

.addproduct-tabs .filepond--item {
  left: -16px;
}

.img-product-box img {
  width: 105px;
  height: 105px;
}

.innerbanner-img button.filepond--file-action-button {
  display: none;
}

.baseline-img button.filepond--file-action-button {
  display: none;
}

/*  */
.quizsec .next-back {
  background: var(--dark-Pale-Aqua);
  color: white;
  padding: 5px 42px;
  font-size: 18px;
  font-weight: 600;
  border-radius: 30px;
  text-transform: capitalize;
}

.quizsec .next-back:hover {
  background: var(--Slate);
}

section.quizsec {
  padding-top: 13rem;
  padding-bottom: 5rem;
  /* background-image: url(../images//bgquiz.png); */
  background-color: #e7e8db;
  background-repeat: no-repeat;
  background-size: cover;
}

.quizcontent {
  background: #7a9ea0;
  border-radius: 30px;
  height: 100%;
  padding: 30px 0;
}

.quizcontent .MuiPaper-root {
  background: initial;
}

.box-bg-white.MuiBox-root.css-i9gxme {
  background: white;
  width: 80%;
  margin: 0px auto;
  padding: 30px 20px;
  position: relative;
  border-radius: 20px;
}

.quizspan {
  position: absolute;
  width: 100%;
  height: 50px;
  background: white;
  bottom: 33px;
  right: 0;
  z-index: 0;
}

.formbtn-quiz {
  flex-flow: wrap;
  gap: 30px;
  margin-bottom: 2rem;
}

.formbtn-quiz label {
  padding: 10px 0;
  width: 300px;
  border-radius: 30px;
  border-color: var(--Slate);
  color: var(--Slate);
}

.formbtn-quiz .btn-check:checked + .btn,
:not(.btn-check) + .btn:active,
.btn:first-child:active,
.btn.active,
.btn.show {
  background-color: var(--Slate);
  color: white;
}

.titleparaquiz h1 {
  color: var(--Slate);
  font-size: 45px;
  font-weight: 600;
}

.titleparaquiz p {
  color: #797f82;
}

.progressbar {
  width: 100%;
  background: var(--Pale-Aqua);
  display: block;
  height: 15px;
  border-radius: 30px;
}

.progressbar span {
  background: var(--dark-Pale-Aqua);
  display: block;
  height: 15px;
  border-radius: 10px;
  position: relative;
}

.quizprogress h2 {
  color: var(--Slate);
  font-size: 24px;
  font-weight: 600;
}

.progressbar span i {
  position: absolute;
  right: -13px;
  background: var(--Slate);
  border-radius: 30px;
  color: white;
  top: -6px;
  width: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  height: 27px;
}

/* recomm-test */
.recom-test-sec ul#myTab {
  display: flex;
  flex-direction: column;
  border: none;
}

.recom-test-sec li.nav-item {
  display: flex;
  align-items: center;
  /* border: 1px solid #087bca; */
  /* border-radius: 6px; */
  padding: 0 0;
  margin-bottom: 20px;
}

.recom-test-sec li.nav-item button {
  display: flex;
  gap: 10px;
  font-size: 20px;
  font-weight: 400;
  width: 100%;
  padding: 7px 30px;
  /* border-radius: 10px; */
  padding: 18px 16px;
  text-align: left;
  color: var(--Slate);
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-right: 1px solid;
  border-left: 1px solid;
  border-radius: 10px;
}

.recom-test-sec li.nav-item p {
  display: flex;
  width: 100px;
  min-height: 68px;
  border: 1px solid var(--dark-Pale-Aqua);
  text-align: center;
  margin: 0;
  align-items: center;
  justify-content: center;
  color: var(--Slate);
  font-size: 24px;
  border-radius: 10px 0px 0px 10px;
}

.recom-test-sec ul li button.nav-link.active {
  background: var(--dark-Pale-Aqua);
  color: white;
  border-radius: 10px;
  display: flex;
  gap: 2rem;
}

section.recom-test-sec {
  padding-top: 130px;
  background-image: url(../images/testbg1.png);
  background-repeat: no-repeat;
}

.rectitle {
  background: white;
  border-radius: 10px;
  border: 1px solid var(--Pale-Aqua);
  padding: 30px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--Slate);
  font-size: 30px;
  text-align: center;
  font-weight: 500;
}

.recometesttitlebg {
  padding: 90px 30px;
  border-radius: 20px;
  background-image: linear-gradient(
    321deg,
    #4f6367 0%,
    #b8d9d9 0%,
    #7a9ea0 27%,
    #4f6367
  );
  background-size: 100% 100%;
}

.recometesttitlebg h1 {
  font-size: 50px;
  color: white;
  font-weight: 500;
}

.clienttestname h3 {
  color: var(--Slate);
  font-size: 24px;
  font-weight: 500;
}

.clienttestname p {
  color: #6b6b6b;
}

.clienttestname h4 {
  color: var(--Slate);
  font-size: 20px;
  font-weight: 500;
  width: 210px;
}

.clienttestname img {
  margin-right: 10px;
}

.innertabtestcontent {
  background: var(--Pale-Aqua);
  border-radius: 20px;
}

.innertabtestcontent hr {
  border-color: #087bca;
}

.rectest-instruction {
  background: white;
  border-radius: 20px;
  padding: 10px 10px;
  text-align: center;
}

.test-inst-row {
  width: 90%;
  margin: 0 auto;
}

.test-inst-row h1 {
  text-align: center;
  color: var(--Slate);
  font-size: 32px;
  font-weight: 500;
}

.btnadtocart {
  display: flex;
  background: var(--Pale-Aqua);
  padding: 9px 10px;
  font-size: 18px;
  font-weight: 500;
  color: var(--Slate);
  border: 1px solid var(--Slate);
  font-family: "Poppins";
  width: 210px;
  justify-content: center;
  align-items: center;
  gap: 9px;
  border-radius: 30px;
}

.rectestredirection {
  padding: 10px 0;
  width: 300px;
  border-radius: 30px;
  border: 1px solid var(--Slate);
  color: var(--Slate);
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
}

.backtoquiz a {
  color: var(--Slate);
}

.fade.quizmodaltwo.modal.show .modal-content {
  /** TODO: Replace URL */
  background-image: url(https://solidificatiosolution.s3.ap-southeast-2.amazonaws.com/imgs/datasavebg.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-color: transparent;
  border: none;
  box-shadow: initial;
}

.quizmodal h1 {
  color: #4f6367;
  font-size: 100px;
  font-family: "Bebas Neue", sans-serif;
}

.quizmodal .modal-dialog.modal-dialog-centered {
  max-width: 90vw;
}

/*  */
span.sidebannericon img {
  position: absolute;
  left: -16%;
  top: 2%;
  width: 30%;
  opacity: 0.5;
  filter: blur(2px);
  z-index: 0;
  animation: animName 50s linear infinite;
}

.quizLetsStart {
  width: 36%;
  margin: 0 auto;
  padding: 3rem 3rem 0;
  /* background-image: url(../images/innerbgquiz.png); */
  background-color: #82a5a8;
  border-radius: 10px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 520px;
  position: relative;
  text-align: center;
}

section.quizstartsec {
  padding-top: 11rem;
  background-image: url(../images/Quizstartbg.png);
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 6rem;
}

.quizLetsStart h2 {
  font-size: 70px;
  font-family: "Bebas Neue";
  color: white;
  line-height: 0.9;
}

.quizLetsStart a {
  background: var(--Slate);
  padding: 6px 20px;
  border-radius: 10px;
  font-size: 20px;
  font-family: "Poppins";
  color: white;
  font-weight: 600;
}
.quizstartsec h2 {
  text-transform: lowercase !important;
}

.imgquiz img {
  position: absolute;
  bottom: 0;
  right: 0;
}

/* hover animation button */

/* 2nd button animation */

/* about page */
.banneraboutcontent h1 {
  font-size: 60px;
  padding: 5px 0px 0px 0px;
  font-weight: 600;
  color: var(--Slate);
  width: 73%;
  line-height: 63px;
}

section.about-sec-banner {
  position: relative;
  margin-top: 81px;
  background-color: var(--Antique-White);
}

.banneraboutvideo {
  position: relative;
  height: 100%;
}

.banneraboutcontent {
  width: 70%;
  padding: 120px 0;
  margin-left: auto;
}

.banneraboutvideo video.fullscreen {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(0px, 0px);
}

.banneraboutcontent h1 span {
  color: var(--dark-Pale-Aqua);
}

.banneraboutcontent p {
  color: #959595;
  font-size: 20px;
  width: 70%;
}

.banner-faq-form input:focus::placeholder {
  color: #003953;
}

/*  */
section.ourpartner-sect
  .slick-slide.slick-active.slick-center.slick-current
  .partnerlogo {
  height: 250px;
  width: 100%;
  transform: translateY(-40px);
}

section.ourpartner-sect
  .slick-slider.owl-carousel.owl-theme.partner-slide.slick-initialized {
  transform: translateY(60px);
}

section.ourpartner-sect .slick-track {
  padding-top: 40px;
}

.partnerlogo img {
  width: 110px;
  height: 85px;
  object-fit: contain;
}

section.ourpartner-sect
  .slick-slide.slick-active.slick-center.slick-current
  .partnerlogo
  img {
  width: 130px;
  height: 100px;
}
.our-ref img {
  width: 100% !important;
  height: 100% !important;
  border-radius: 10px !important;
}
/* .ref-logo img{
  height: 100%;
  width: 100%;
} */
/* .ref-logo {
  background: white;
  border-radius: 10px;
  height: 150px;
  width: 150px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  
} */

/* canvas */

canvas {
  border: 2px solid #bbb5b5;
  margin: 20px;
  border-radius: 20px;
  border: none;
}

section.form-sect-log.verify .form-login-txt p a {
  color: #abb5bc;
}

section.form-sect-log.verify.verificode input.vi {
  opacity: 0;
}

.hambar ul:first-child li:first-child:before {
  position: absolute;
  right: -20px;
  background: var(--Slate);
  content: "";
  height: 100%;
  width: 3px;
}

.hambar ul li {
  position: relative;
}

/* button hover  */

.viewdemo {
  border: 2px solid var(--Pale-Aqua);
  border-radius: 50px;
  font-size: 17px;
  font-weight: 600;
  color: var(--Slate);
  font-family: "Poppins";
  box-shadow: 5px 4px 8px #0006;
  width: 300px;
  display: flex;
  height: 45px;
  transition: all 0.5s ease-in-out;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: transparent !important;
}

.viewdemo::before {
  content: "";
  position: absolute;
  width: 300px;
  right: -2px;
  top: 0;
  bottom: 0;
  background-color: var(--Pale-Aqua);
  border-radius: 25px;
  z-index: 1;
  transition: all 0.5s ease-in-out;
}

.viewdemo:after {
  content: "\f178";
  position: absolute;
  /* width: 33px; */
  /* height: 18px; */
  top: 50%;
  margin-top: 0px;
  right: 20px;
  opacity: 0;
  transition: all 0.5s ease-in-out;
  z-index: 3;
  font-family: "Font Awesome 5 Free";
  color: var(--Slate);
  font-size: 30px;
  /* top: 3px; */
  transform: translateY(-50%);
  font-weight: 800;
}

.viewdemo:hover:before {
  width: 70px;
}

.viewdemo:hover:after {
  opacity: 1;
}

.viewdemo span {
  color: var(--Slate);
  z-index: 1;
  width: 82%;
  display: block;
  text-align: center;
  transition: all 0.5s ease-in-out;
}

.viewdemo:hover span {
  text-align: left;
  color: var(--Pale-Aqua);
}

/* 2nd button */
.viewdemo.viewtest:before {
  background: var(--Slate);
}

.viewdemo.viewtest {
  border-color: var(--Slate) !important;
}

.viewdemo.viewtest span {
  color: var(--Antique-White);
  z-index: 9;
}

.viewdemo.viewtest:hover span {
  color: var(--Slate);
}

.viewdemo.viewtest:after {
  color: var(--Antique-White);
}

.login-btn:hover {
  background: transparent;
  color: var(--Pale-Aqua);
}

.main-menu ul .active a:before {
  display: none;
}

.main-menu ul .active a:after {
  display: none;
}

.btn-check + .btn:hover {
  color: initial;
  border-color: var(--Slate);
}

.btn-check:checked + .btn,
:not(.btn-check) + .btn:active,
.btn:first-child:active,
.btn.active,
.btn.show {
  border-color: var(--Slate);
}

.test-kits span {
  font-size: 14px;
  border: 1px solid #4f6367;
  border-radius: 5px;
  padding: 0px 15px;
  background: white;
  font-weight: 600;
  color: #4f6367;
}

.test-kits {
  margin-bottom: 20px;
  text-align: end;
  height: 28px;
}

.cart-own-top.more-biomarkers .btn-cart-own {
  display: flex;
  flex-flow: wrap;
}

.cart-own-top.more-biomarkers .btn-cart-own button {
  width: max-content;
}

.innerbanner-img img {
  width: 100%;
}

.primary-banner-img {
  width: 100%;
}

.about-innerpages .btns-optima .viewdemo:before {
  width: 220px;
}

.about-innerpages .btns-optima .viewdemo:hover:before {
  width: 70px;
}

.monial-box-txt-chnge h3 {
  font-size: 1.5rem;
  color: var(--Slate);
  text-transform: uppercase;
  font-weight: 600;
}

section.Representssec .row {
  align-items: center;
}

.cart-own-top.more-biomarkers h1 {
  padding-top: 0px !important;
  font-weight: 600 !important;
}

.verify-loader {
  width: 20px !important;
  height: 20px !important;
}

.partner-hr .slick-prev {
  left: 10px;
  z-index: 9;
}

.partner-hr .slick-next {
  right: 10px;
  z-index: 9;
}

.blogdetailinner img {
  width: 100%;
}

span.arrow-back.custarrow {
  left: -5px;
}

.row.blogdetailsrow {
  padding-top: 150px;
  width: 60%;
  margin: 0 auto;
}

.hover-detail-show {
  position: absolute;
  border: 1px solid black;
  border-radius: 5px;
  width: 300px;
  font-size: 13px !important;
  top: 2.5rem;
  padding: 2px 6px !important;
  background: white;
  opacity: 0;
  color: #3a3a3a;
  transition: all 0.5s ease-in-out;
  z-index: -1;
  font-weight: 600;
}

.hover-detail-bio:hover .hover-detail-show {
  opacity: 1;
  z-index: 9;
  transition: all 0.5s ease-in-out;
}

/* related articles */
.fourth-grid.Relatedarticles {
  display: block;
}

.fourth-grid.Relatedarticles .fourth-box {
  width: 92% !important;
  margin: 0 auto;
  display: flex !important;
}

.fourth-grid.Relatedarticles .fourth-img img {
  display: inline !important;
}

.fourth-grid.Relatedarticles .slick-track {
  padding: 30px 0px;
}

.fourth-grid.Relatedarticles .slick-dots li {
  width: 15px;
  height: 15px;
  background: #b8d9d9;
  border-radius: 50px;
  font-size: 0;
}

.fourth-grid.Relatedarticles .slick-dots li button:before {
  display: none;
}

.fourth-grid.Relatedarticles li.slick-active {
  background: #4f6367;
}

/*  */
.particles-loader .spinGolden {
  mix-blend-mode: screen;
  animation: spinGolden 5s infinite;
}

.visibility-hidden {
  visibility: hidden;
}

@keyframes spinGolden {
  0% {
    transform: rotate(-360deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

.theme-body-bg {
  background: var(--Slate);
}

.-z-index-1 {
  z-index: -999;
}

.loader {
  transition: all 0.5s ease-in-out;
}

/*  */
h4.summry-title {
  color: var(--Slate);
  font-weight: 600;
  font-size: 20px;

  font-weight: 600;
}

.summry-price-edit p {
  margin: 0;
  font-size: 13px;
  font-family: "Poppins";
  font-weight: 400;
  color: var(--Slate);
}

.summry-price-edit span {
  margin: 0;
  font-size: 13px;
  font-family: "Poppins";
  font-weight: 400;
  color: var(--Slate);
}

.summry-price-edit {
  border-bottom: 1px solid #4f636733;
}

.order-sumry {
  background: #f5f5f5;
  padding: 20px 10px;
  border-radius: 20px;
  width: 100%;

  left: 0%;
}

.check-no-btn {
  background-color: transparent !important;
  color: #000 !important;
}

.cartbox .huz-cncl-mark {
  right: 1rem;
  top: 0.5rem;
}

.cust-btn-huz,
.cust-btn-huz::before {
  width: 250px;
}

.cntnu-shop-btn-two {
  padding: 8px 82px;
}

.biomarker-slider .css-14pt78w-MuiSlider-rail {
  opacity: 1 !important;
}

.biomarker-slider .css-11jw1pn-MuiSlider-root .MuiSlider-rail {
  height: 9px;
  border-radius: 10;
}

.biomarker-slider .css-1gv0vcd-MuiSlider-track {
  border: none;
}

.biomarker-slider .css-iogdz8-MuiSlider-root {
  height: 9px;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

.slick-track {
  margin-left: inherit !important;
}

.opacity---low {
  opacity: 0.6;
}

.custom-check-box {
  accent-color: #4f6367;
}

.circularLoaderFullPage {
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes slideLeftToRightText {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}

.see-all {
  display: flex;
  justify-content: center;
  margin: 10px;
}

.see-all-btn {
  font-size: 20px;
  font-family: poppins;
  font-weight: 600;
  padding: 5px 20px;
  color: white;
  background-color: #4f6367;
  border-radius: 50px;
}

.biomarker-list-container {
  width: 57.938rem;
  height: 52.25rem;
  display: flex;
  justify-content: center;
}

.deletedmodalpractit .modalBody {
  color: white;
}

ul.biomarker-list {
  grid-template-columns: 33% 33% 33% !important;
  color: white;
  font-size: 22px !important;
  font-weight: 500;
  font-family: poppins;
}

.biomarkers-heading {
  color: #4f6367;
  font-size: 40px;
  font-weight: 600;
  font-family: "Poppins";
}

.Biomarkers-thead {
  border-radius: 50px;
  padding: 8px 0;
  margin: 0px 0px 8px;
  background: var(--Slate);
}

.tested {
  color: #46b710;
}

.biomarker-lists {
  grid-template-columns: 33% 33% 33% !important;
  color: white;
  font-size: 22px !important;
  font-weight: 500;
  font-family: poppins;
}

.Biomarkers-td {
  border-radius: 50px;
  padding: 8px 0;
  margin: 0px 0px 8px;
  background: #b8d9d9;
  font-size: 16px;
  font-weight: medium;
  font-family: poppins;
}

ul.sec-order-list.biomarker-lists li {
  font-size: 13px;
}

.home-faq-accord.accordion-header .accordion-button::after {
  filter: grayscale(1) invert(3) brightness(0.5);
}

input[type="search"]::-webkit-search-cancel-button {
  /* Remove default */
  -webkit-appearance: none;

  /* Now your own custom styles */
  height: 14px;
  width: 14px;
  display: block;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAAn0lEQVR42u3UMQrDMBBEUZ9WfQqDmm22EaTyjRMHAlM5K+Y7lb0wnUZPIKHlnutOa+25Z4D++MRBX98MD1V/trSppLKHqj9TTBWKcoUqffbUcbBBEhTjBOV4ja4l4OIAZThEOV6jHO8ARXD+gPPvKMABinGOrnu6gTNUawrcQKNCAQ7QeTxORzle3+sDfjJpPCqhJh7GixZq4rHcc9l5A9qZ+WeBhgEuAAAAAElFTkSuQmCC);
  /* setup all the background tweaks for our custom icon */
  background-repeat: no-repeat;

  /* icon size */
  background-size: 14px;
}

.cursor-pointer {
  cursor: pointer;
}

.datasavage {
  text-align: left;
  padding: 10px 30px;
}

.datasavage h1 {
  font-family: "Bebas Neue";
  color: white;
  font-size: 55px;
}

.datasavage {
}

.datasavage p {
  font-family: "Poppins";
  color: white;
  font-size: 25px;
}

.accordion-header:focus {
  outline: none;
}

.accordion-header:focus-visible {
  outline: none;
}

.accordion-item:focus {
  outline: none;
}

.accordion-item:focus-visible {
  outline: none;
}

.collapsed {
  outline: none !important;
  border: none !important;
}

.collapsed:focus {
  outline: none !important;
  border: none !important;
}

.collapsed:focus-visible {
  outline: none !important;
  border: none !important;
}

.accordion-header button:focus {
  outline: none !important;
  border: none !important;
}

.accordion-header button:visited {
  outline: none !important;
  border: none !important;
}

.accordion-button:focus {
  box-shadow: none !important;
}

.fertin .canvasjs-chart-toolbar button:hover {
  background: transparent !important;
  background-color: transparent !important;
  color: black !important;
}

.fertin .canvasjs-chart-toolbar button:hover img {
  filter: initial !important;
}

.mt-5.nowtalkend h4 {
  font-family: "Bebas Neue";
  color: #4f6367;
  font-size: 35px;
}

.mt-5.nowtalkend p {
  color: #4f6367;
  font-size: 21px;
  width: 70%;
  margin: 0 auto;
}

.quizmodal.modal .modal-content {
  background-image: url("https://solidificatiosolution.s3.ap-southeast-2.amazonaws.com/imgs/bg-quiz.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.nowtalkendmod.modal-body img {
  width: 20%;
  margin-top: 20px;
}

/* g#SvgjsG1010 path {
  fill: #20c200;
}

g#SvgjsG1012 path {
  fill: red;
} */
.notfound {
  height: 100vh;
  background: var(--Antique-White);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.notfound h1 {
  font-size: 50px;
  font-weight: 600;
  color: #495057;
  font-family: fangsong;
}

.rectest-instruction p {
  margin: 0;
}

@keyframes widthAnimation {
  0% {
    width: 0%;
  }

  100% {
    width: 100%;
  }
}

.customKeyFrame {
  width: 0%;
  animation: widthAnimation 9s linear;
}

/* css-1n6sfyn-MenuList  */
.accordion-button::after {
  max-height: 200px !important;
  /* Set a max height to enable scrolling */
  overflow-y: auto !important;
  /* Enable vertical scrollbar */
}

/* Custom scrollbar styles */
.css-55gf71-Menuportal::-webkit-scrollbar {
  width: 8px !important;
  /* Width of the scrollbar */
}

.css-55gf71-Menuportal::-webkit-scrollbar-thumb {
  background-color: #ad0a0a !important;
  /* Color of the scrollbar thumb */
  border-radius: 4px !important;
  /* Border radius of the thumb */
}

/* Optionally, you can style other scrollbar components like track, corner, etc. */
.css-55gf71-Menuportal::-webkit-scrollbar-track {
  background-color: #8f1414 !important;
  /* Color of the scrollbar track */
}

.css-55gf71-Menuportal::-webkit-scrollbar-corner {
  background-color: #7c1a1a !important;
  /* Color of the scrollbar corner */
}

.btn__loader {
  width: 20px !important;
  height: 20px !important;
  margin-left: 10px;
}

.btn__loader svg {
  width: 20px !important;
  height: 20px !important;
}

.cust-text-truncate {
  padding: 10px 5px !important;
}

/* Seatch Input Open Box Styles */
.search-input-open-box-parent {
  width: 92vw;
  height: 92vw;
  margin: 0 auto;
  background-color: #b8d9d9;
  position: absolute;
  top: 5.15rem;
  left: 0;
  right: 0;
}
.search-input-open-box-inner-parent img.img-fluid {
  border-radius: 29px;
}
.search-input-open-box-inner-parent {
  display: grid;
  gap: 1.75rem;
  grid-template-columns: 63% 35%;
  padding: 0 3rem;
  height: 100%;
  background-image: url(../images/Quizstartbg.png);
  background-repeat: no-repeat;
  background-size: cover;
  overflow-y: scroll;
}

.search-input-open-box-heading {
  font-size: 33px;
  line-height: 1;
  font-family: "Poppins";
  font-weight: 500;
  color: #4f6367;
}

.search-result-box-heading {
  font-size: 23px;
  line-height: 1;
  font-family: "Poppins";
  font-weight: 600;
  color: #4f6367;
}

.search-input-open-box-inner-grid {
  display: grid;
  gap: 15px;
  grid-template-columns: 32% 32% 32%;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 676px;
}

.search-input-open-box-inner-grid-box-parent {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 40px;
  gap: 1rem;
  padding: 1.25rem 1rem;
  border: 1px solid black;
}

.search-input-open-box-left,
.search-input-open-box-right {
  gap: 1.25rem;
  padding-top: 1rem;
}

.search-result-box-price {
  font-size: 16px;
  line-height: 1;
  font-family: "Poppins";
  font-weight: 600;
  border-radius: 6px;
  background-color: #b8d9d9;
  color: #4f6367;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 119px;
  height: 36px;
}

.search-input-open-box-right {
  border-left: 2px solid #087cca45;
  padding-left: 22px;
}

.cut-search {
  position: absolute;
  right: 1.5rem;
  font-size: 34px;
  color: var(--Slate);
}

.mobile-only-Slider {
  display: none;
}

.mobile-only-Slider .slick-prev {
  left: 5px;
  top: 200px;
  z-index: 999;
}

.mobile-only-Slider .slick-next {
  right: 5px;
  top: 200px;
  z-index: 999;
}

.main-banner-sec {
  position: relative;
  background-image: url(../images/sec1bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  padding-bottom: 60px;
  background-color: #4f6367;
}

.parentBox {
  padding: 30px 0px;
  transition: all 0.5s ease-in-out;
  border-radius: 5px;
  margin-bottom: 1rem;
}

.btns-optima-mb {
  display: none;
}

.hamburger-super-parent {
  display: none;
  position: relative;
}

.productinner-mb {
  display: none !important;
}

.dropdown-search-img {
  max-width: 40px;
  max-height: 40px;
}

.drop-down-inner-items {
  display: flex;
  gap: 5px;
  align-items: center;
}

.dropdown-list-search {
  position: absolute;
  top: 5.6rem;
  left: 0px;
  border-radius: 10px;
  padding: 0.5rem 0.5rem;
  background-color: #ffff;
  min-height: 70px;
  max-height: 180px;
  overflow-y: scroll;
  width: 600px;
  display: block;
  border: 1px solid var(--Slate);
}

.drop-down-inner-items span {
  color: var(--Slate) !important;
}

.inputBox input:focus-visible ~ div {
  display: block;
}

button.btn-for-search {
  display: flex;
  gap: 10px;
  align-items: center;
}

.drop-down-inner-items {
  padding: 4px;
}

.drop-down-inner-items:hover {
  background-color: rgba(128, 128, 128, 0.308);
  color: white;
}

.dropdown-list-search::-webkit-scrollbar {
  width: 0.5em;
  height: 0.5em;
}

.dropdown-list-search::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.dropdown-list-search::-webkit-scrollbar-thumb {
  background-color: var(--dark-Pale-Aqua);
  outline: 1px solid slategrey;
}

.fourth-img img {
  width: 95%;
  object-fit: initial;
  border-radius: 10px;
  margin: 0 auto;
  height: 320px;
}

.drop-down-inner-items span:hover {
  color: white !important;
}

.btn-for-search {
  display: flex;
  align-items: center;
}

img.blogs-overview-img {
  width: 70px;
  height: 55px;
}
.fertin .canvasjs-chart-toolbar button {
  background-color: transparent !important;
  background: transparent !important;
}

.banner-faq-form-2 h2 {
  font-size: 23px !important;
  margin: 0 !important;
}

.banner-faq-form-2 input {
  width: 100%;
  font-size: 14px;
}
.banner-faq-form-2 input {
  height: 45px;
}
.banner-faq-form-2 textarea {
  font-size: 15px;
  width: 100%;
  border-radius: 11px;
  width: 100%;
  height: 110px;
}
.banner-faq-form-2 a {
  font-size: 15px;
}
.ask-question-btn {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 180px;
}
.quest-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}
.quest-main i {
  color: #4f6367;
}
.banner-faq-form-2 input {
  margin-bottom: 20px !important;
}
.banner-faq-form-2 {
  left: -35px;
  top: 7px;
}
.pass-requir ul li i {
  color: red;
  font-size: 16px;
  margin-right: 5px;
  width: 12px;
}
.fourth-img h5 br {
  display: none;
}
/* .main-home-search{
  position: relative;
} */
/* Seatch Input Open Box Styles */

/* graph custome start */
.graph-left p {
  margin: 0;
  position: relative;
}
.graph-left p span:first-child {
  position: absolute;
  left: -50px;
  font-size: 12px;
}
.graph-left .graph-span {
  width: 10px;
  background: red;
  height: 80px;
  display: block;
}
.graph-left {
  margin-left: 5rem;
}
.cust-graph {
  display: flex;
  position: relative;
}
.graph-right {
  display: flex;
  flex-direction: column;
}

.graph-right span {
  width: 230px;
  text-align: center;
  border-bottom: 1px solid #0000003d;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.graph-points span {
  display: block;
  background: black;
  width: 150px;
  height: 2px;
  transform: rotate(-45deg);
  position: absolute;
  transform-origin: left;
}

.graph-points {
  position: absolute;
  top: 45%;
  left: 8rem;
}
span.spn-inner-1 {
  background: red;
  position: absolute;
  transform: rotate(90deg);
  width: 80px;
  right: -80px;
}
span.spn-inner-2 {
  transform-origin: left;
  left: 80px;
  width: 90px;
  transform: rotate(272deg);
}
span.spn-inner-3 {
  transform-origin: right;
  transform: rotate(240deg);
  width: 40px;
  right: 0;
}
/* graph custome end */

/* Payment Step Form Styles */
.payment-step-form-sec {
  margin-top: 8rem;
}
.payment-step-form-content-parent {
  width: 77vw;
  margin: 0 auto;
}

.payment-step-progress-bar-parent {
  width: 100%;
  height: 7px;
}

.payment-step-circle:nth-child(1) {
  left: 0;
}
.payment-step-circle:nth-child(2) {
  left: 50%;
}
.payment-step-circle:nth-child(3) {
  right: 0;
}

.payment-step-circle {
  width: 40px;
  height: 40px;
  border: 1px solid var(--Slate);
  background-color: #fff;
  color: var(--Slate);
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
}

.payment-step-circle-active {
  background-color: var(--Slate);
  color: #fff;
}

.payment-step-circle-and-pg-bar-parent {
  width: 75%;
  margin: 0 auto;
}

.payment-step-form-right-side {
  background-color: var(--Pale-Aqua);
}

.payment-step-form-left-side {
  border-top-left-radius: 2rem;
  border-bottom-left-radius: 2rem;
  padding: 1.7rem 1.5rem;
}
.payment-step-form-right-side {
  height: 100%;
  border-top-right-radius: 2rem;
  border-bottom-right-radius: 2rem;
  padding: 1.7rem 1.5rem 2.35rem 1.5rem;
}

.payment-step-form-row-parent {
  margin: 3rem 0 2rem 0;
  border-radius: 2rem;
  box-shadow: 0 0 14px #00000030;
}

.payment-step-form-label-inp-grid {
  display: grid;
  gap: 2%;
  grid-template-columns: 49% 49%;
}

.payment-step-form-label {
  font-size: 13px;
  font-weight: 400;
  color: #252334;
}
.payment-step-form-input {
  font-size: 13px;
  font-weight: 400;
  background-color: #f5f5f5;
  border-radius: 6px;
  height: 36px;
  outline: none;
  padding: 0 0.35rem;
  border: 1px solid #cbcbcb;
}

.payment-step-form-top-heading {
  font-size: 23px;
  color: #252334;
  font-weight: 500;
  margin-bottom: 0;
}

.have-an-acc {
  font-size: 13px;
  font-weight: 400;
  color: #959595;
}
.have-an-acc-action {
  font-size: 13px;
  font-weight: 500;
  color: #4f6367;
  margin-left: 0.25rem;
}

/* Chrome, Safari, Edge, Opera */
.payment-step-form-input-number::-webkit-outer-spin-button,
.payment-step-form-input-number::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.payment-step-form-input-number[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}
.checkbox-container {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.checkbox-input {
  display: none;
}

.checkbox-custom {
  width: 16px;
  height: 16px;
  border: 2px solid #0d2e59;
  border-radius: 3px;
  position: relative;
}

.checkbox-custom::before {
  content: "";
  position: absolute;
  display: none;
  top: 2px;
  left: 4px;
  width: 4px;
  height: 8px;
  border: solid #0d2e59;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.checkbox-input:checked + .checkbox-custom::before {
  display: block;
}

.checkbox-label {
  font-size: 16px;
}

.payment-step-form-btn-btm {
  min-width: 138px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--Slate);
  color: #fff;
  font-weight: 700;
  font-size: 13px;
  border-radius: 30px;
}

.payment-step-form-right-img {
  width: 250px;
  height: 175px;
  object-fit: cover;
  border-radius: 10px;
  filter: drop-shadow(0 0 10px #0000008a);
}

.access-plan {
  font-size: 16px;
  font-weight: 500;
  color: #252334;
}
.access-plan-para {
  font-size: 13px;
  font-weight: 400;
  color: #252334;
}

.annual-subs-parent-border {
  padding: 0 0.5rem 1rem 0.5rem;
  border-bottom: 1px solid #25233459;
}

.first-last-name-box {
  background-color: #eeeeee;
  border-radius: 6px;
  padding: 0.5rem 1rem;
  gap: 0.55rem;
  margin: 1rem 0 1.5rem 0;
}

.first-name-last-name-text {
  font-size: 13px;
  font-weight: 400;
  color: #252334;
}

.VisaIcon {
  width: 32px;
  height: 27px;
  object-fit: contain;
}

.VisaIconfield {
  top: 50%;
  transform: translateY(-50%);
  right: 2%;
}

.confirm-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 99;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.confirm-modal-content {
  background-color: var(--Pale-Aqua);
  border: 1px solid #888;
  width: 40vw;
  margin: 0 auto;
  gap: 1.75rem;
  padding: 2rem 0;
  border-radius: 1rem;
}

.close-confirm {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close-confirm:hover,
.close-confirm:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.modal-payment-heading {
  font-size: 37px;
  color: #4f6367;
  font-weight: 600;
}
.payment-step-form-btn-btm:hover {
  color: #fff;
}
.confirm-modal-content img {
  width: 23%;
}
.own-build-btn {
  font-weight: 700 !important;
  color: var(--Slate) !important;
}
/* Payment Step Form Styles */

.fourth-sect .slick-prev:before {
  color: #4f6367 !important;
}
.fourth-sect .slick-prev {
  left: -55px;
}
.fourth-sect .slick-next:before {
  content: "→";
  color: #4f6367 !important;

  font-size: 40px;
}

.fourth-sect .slick-prev:before {
  content: "←";
  font-size: 40px;
}

/* REG CART  */
.scrollable-container {
  /* Make it scrollable */
  max-height: 82vh; /* Set maximum height to make it scrollable */
}
.reg-cart {
  position: sticky;
  background-color: #b8d9d9;
  border-radius: 25px;
  box-shadow: -1px -1px 50px -19px #0000006e;
  height: 750px;
  top: 5rem;
  border-radius: 0px 20px 20px 0px;
}
.reg-cart-head {
  font-size: 30px;
  color: var(--Slate);
  font-weight: 600;
  padding: 40px;
}
.reg-cart-head-2 {
  font-weight: 500;
  font-size: 25px;
  color: #252334 !important;
}
.reg-cart-product-head {
  font-size: 18px;
  color: #252334;

  font-weight: 500;
}
.reg-cart-product-detail {
  font-size: 16px;
  color: #252334;
  font-weight: 500;
}
.register-cart {
  display: grid;
  grid-template-columns: 50% 50%;
}
.register-form-box-parent {
  display: grid;
  grid-template-columns: 50% 50%;
  /* box-shadow: -1px -1px 50px -19px #0000006e; */
  border-radius: 25px;
  margin-bottom: 1rem;
}

.form-box-grid {
  box-shadow: -1px -1px 50px -19px #0000006e;
  border-radius: 20px 0px 0 20px;
}
.reg-cart-prices {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  gap: 5px;
}
.reg-cart-inner-detail {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.reg-cart-specific-product {
  padding: 10px 10px 15px 10px;
  border-bottom: 1px solid #252334;
}
.reg-cart-multiple {
  padding: 0 31px 0 31px;
  max-height: 250px;
  overflow-y: auto;
}

.reg-cart-multiple::-webkit-scrollbar {
  width: 0.5em;
}

.reg-cart-multiple::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.reg-cart-multiple::-webkit-scrollbar-thumb {
  background-color: #4f6367;
  outline: 1px solid #ffffff;
  border-radius: 10px;
}

.multiple-cart-handl {
  padding: 0 3rem 0 0;
}
.reg-cart-product-detail-2 {
  font-weight: 400 !important;
}
.reg-cart-product-detail-2 {
  font-weight: 600 !important;
}
.order-sum-inner {
  background-color: #f8f8f879;
  border-radius: 8px;
  padding: 20px;
}
.order-sum-specific {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding-bottom: 5px;
  border-bottom: 1px solid #bbbbbb;
}
.reg-ordersum-main {
  padding: 30px 80px 40px 40px;
}
.new-reg-cust {
  background-color: #ffffff !important;
  position: initial !important;
  padding: 130px 0px 20px 0 !important;
}
.reg-cart-product-detail-3 {
  font-size: 16px;
}
/* REG CART  */

.qml-pathalogy {
  color: var(--Slate);
  font-weight: 600;
  text-decoration: underline !important;
}
.qml-pathalogy:hover {
  color: var(--Slate) !important;
}
section.register-sec-sect {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.second-quiz-head {
  font-size: 90px !important;
  margin-bottom: 5rem;
}

/* Define the styles for the hovered path */
/* .path-one,
.path-two,
.path-three,
.path-four,
.path-five,
.path-six,
.path-seven {
  fill: var(--Pale-Aqua);
  stroke: #000;
  stroke-width: 4px !important;
  transition: fill 0.3s;
}

.mapdiv :hover {
  fill: var(--Pale-Aqua);
}

.path-one:hover, .path-two:hover, .path-three:hover, .path-four:hover, .path-five:hover, .path-six:hover, .path-seven:hover {
  stroke: rgb(255, 136, 0);
  transition: fill 0.5s;
}

.path-one:not(:hover) ,.path-two:not(:hover) , .path-three:not(:hover) , .path-four:not(:hover) ,.path-five:not(:hover), .path-six:not(:hover), .path-seven:not(:hover){
  stroke: black;
  stroke-width: 1px;
} */

.path-one,
.path-two,
.path-three,
.path-four,
.path-five,
.path-six,
.path-seven {
  fill: var(--Pale-Aqua);
  stroke: black;
  stroke-width: 4px; /* Initial stroke width */
  transition: stroke-width 0.3s; /* Smooth transition for stroke width */
}

/* Define the styles for the mapdiv when hovered */
.mapdiv:hover .path-one,
.mapdiv:hover .path-two,
.mapdiv:hover .path-three,
.mapdiv:hover .path-four,
.mapdiv:hover .path-five,
.mapdiv:hover .path-six,
.mapdiv:hover .path-seven {
  stroke-width: 4px;
}

/* Define the styles for individual paths when hovered */
.path-one:hover,
.path-two:hover,
.path-three:hover,
.path-four:hover,
.path-five:hover,
.path-six:hover,
.path-seven:hover {
  /* stroke: rgb(255, 136, 0); */
  stroke-width: 4px !important; /* Increase stroke width on hover */
  z-index: 999999999999999999 !important;
  fill: #ff5e54;
}

/* .path-one {
  transform: translateX(-3.5px);
}
.path-two {
  transform: translate(0px, -3px);
}
.path-three {
  transform: translate(4px, -3px);
}
.path-four {
  transform: translate(0px, 0px);
}
.path-five {
  transform: translate(3.5px, 0px);
}
.path-six {
  transform: translate(3px, 2px);
} */
/* .path-six {
  transform: translate(1px, 2px);
}  */

/* .path-one:not(:hover) ,.path-two:not(:hover) , .path-three:not(:hover) , .path-four:not(:hover) ,.path-five:not(:hover), .path-six:not(:hover), .path-seven:not(:hover){
  stroke: black;
  stroke-width: 0;
}  */
/* Add any other styles you want to apply when the path is hovered */

/* NEW QUIZ COMPONENETS */
.quiz-title-head {
  position: relative;
  z-index: 1;
}
.quiz-title-head h1 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
.quiz-title-head p {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 1rem;
}
.quiz-bk-btn {
  background: var(--dark-Pale-Aqua);
  color: white;
  padding: 5px 42px;
  font-size: 18px;
  font-weight: 600;
  border-radius: 30px;
  text-transform: capitalize;
  text-align: left;
}
.quiz-bk-handler {
  text-align: left;
}
/* NEW QUIZ COMPONENETS */
/* Terms-And-Condition*/
.terms-condition-banner h1 {
  font-size: 70px;
  color: var(--Slate);
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  text-align: center;
}
.terms-codition-content {
  width: 80%;
  margin: 3rem auto;
}
.terms-codition-content p {
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  color: #4d4d4d;
  font-weight: 400;
  margin-bottom: 1rem;
}
.terms-codition-content h2 {
  font-size: 28px;
  font-family: "Poppins", sans-serif;
  color: var(--Slate);
  font-weight: 600;
  width: 50%;
  margin: 1.5rem 0;
  text-transform: capitalize;
}
.terms-codition-sp-para {
  font-weight: 500 !important;
}
.privacy-policy-content ul li {
  list-style: disc;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  color: #4d4d4d;
  font-weight: 400;
  margin-bottom: 1rem;
  margin-left: 20px;
}
/* Terms-And-Condition */
.quiz-new-bk {
  position: absolute;
  bottom: 3%;
}
.techciaga-gothrough {
  text-decoration: none !important;
  color: inherit;
}
.techciaga-gothrough:hover {
  color: inherit;
}
.formbtn-quiz label:active {
  background-color: #4f6367 !important;
  border: none !important;
}

.testinal-slider .slick-dots li {
  display: none;
}
.testinal-slider .slick-dots li.slick-active,
.testinal-slider .slick-dots li.slick-active + li,
.testinal-slider .slick-dots li.slick-active + li + li {
  display: flex;
}
.testinal-slider .slick-dots li:nth-last-child(1),
.testinal-slider .slick-dots li:nth-last-child(2),
.testinal-slider .slick-dots li:nth-last-child(3) {
  display: flex;
}
.testinal-slider .slick-dots li.slick-active ~ li:nth-last-child(1),
.testinal-slider .slick-dots li.slick-active ~ li:nth-last-child(2),
.testinal-slider .slick-dots li.slick-active ~ li:nth-last-child(3) {
  display: none;
}
.testinal-slider .slick-dots li.slick-active + li + li:nth-last-child(3),
.testinal-slider .slick-dots li.slick-active + li + li:nth-last-child(2),
.testinal-slider .slick-dots li.slick-active + li + li:nth-last-child(1),
.testinal-slider .slick-dots li.slick-active + li:nth-last-child(3),
.testinal-slider .slick-dots li.slick-active + li:nth-last-child(2),
.testinal-slider .slick-dots li.slick-active + li:nth-last-child(1) {
  display: flex;
  justify-content: center;
}
.testinal-slider ul.slick-dots {
  display: flex !important;
  justify-content: center !important;
}
/* //////Email Verification Code ///////////////////// */
.email-verfication-payment-step-form-top-heading {
  font-size: 18px;
  color: #252334;
  font-weight: 600;
  margin-bottom: 0.5rem;
}
.please-enter-code {
  font-size: 13px;
  font-weight: 400;
  color: #959595;
  margin-bottom: 2rem !important;
}
.VerifactionCode {
  margin-bottom: 3rem;
}
/* .payment-step-circle:nth-child(4){
  right: 0 !important;
}
.payment-step-circle-email:nth-child(1){
  left: 0;
}
.payment-step-circle-email:nth-child(2){
  left: 32%;
}
.payment-step-circle-email:nth-child(3){
  left: 63%;
} */
.verification-input-wrapper div#field-0 {
  background: transparent;
  border-radius: 6px;
  border-color: rgb(79 99 103);
}
.verification-input-wrapper div#field-1 {
  background: transparent;
  border-radius: 6px;
  border-color: rgb(79 99 103);
}
.verification-input-wrapper div#field-2 {
  background: transparent;
  border-radius: 6px;
  border-color: rgb(79 99 103);
}
.verification-input-wrapper div#field-3 {
  background: transparent;
  border-radius: 6px;
  border-color: rgb(79 99 103);
}
.password-changeSetp {
  position: absolute;
  top: 11px;
  right: 10px;
  font-size: 12px;
  color: var(--Slate);
}
.SubmitBtn-faqs-Submit {
  background: var(--Slate);
  padding: 10px 30px;
  border-radius: 50px;
  font-size: 20px;
  font-weight: 500;
  color: #ffff;
  font-family: "Poppins";
  width: 100%;
  display: flex;
  justify-content: center;
}
.ASK-YOUR-QUESTION {
  margin-bottom: 30px;
}
.ASK-YOUR-QUESTION textarea {
  margin-bottom: 0;
}
.quizLetsStart22 {
  height: 400px;
}
.second-quiz-head22 {
  margin-bottom: 2rem;
}
.quizLetsStart22 h2 {
  font-size: 90px !important;
}
/* //////Email Verification Code ///////////////////// */
.accept-tc {
  display: grid;
  grid-template-columns: 2% 97%;
  gap: 10px;
  align-items: flex-start;
}
.accept-tc input {
  margin-top: 10px;
}
.fill_star.fa-regular.fa-star,
.fill_star.fa-solid.fa-star {
  color: orange;
}

.fill_star.fa-regular.fa-star:hover,
.fill_star.fa-solid.fa-star:hover {
  fill: orange;
  font-weight: 900;
}

.primary_btn {
  border: 1px solid var(--Slate);
  color: var(--Slate);
  font-size: 0.875rem;
  transition: .2s all ease-in-out;
}

.primary_btn.active,
.primary_btn:hover {
  background-color: var(--Slate);
  color: #fff;
}

.primary_btn:active{
  box-shadow: inset 0 0 0 100px #0000005c;
}

.text_red {
  color: red;
}

.text_green {
  color: #20c200;
}

.text_yellow {
  color: #feb117;
}

.bg_red {
  background-color: red;
}

.bg_green {
  background-color: #20c200;
}

.bg_yellow {
  background-color: #feb117;
}